<template>
  <v-container fill-height fluid grid-list-xl>
    <v-row>
      <!-- SOURCE -->
      <v-card>
        <v-col xs="12" md="12">
          <div id="printStatistics">
            <div class="fullReport">
              <div class="page-header">
                <div class="header" align="center">
                  <!-- <table class="tableheader" align="center">
                    <tr>
                      <td class="bold">{{data.user_settings.site_info.site}}</td>
                    </tr>
                    <tr>
                      <td>
                        {{data.user_settings.site_info.address}}
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ data.user_settings.site_info.contact }}
                        &nbsp;
                      </td>
                    </tr>
                  </table>-->
                  <table class="tableheader" align="center">
                    <tr>
                      <td class="bold">STATISTICS</td>
                    </tr>
                    <tr>
                      <td>{{ data.filters.branch }}</td>
                    </tr>
                    <tr>
                      <td>{{ data.filters.start }} to {{ data.filters.end }}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="result-body" align="center">
                <table>
                  <thead>
                    <tr>
                      <td>
                        <!--place holder for the fixed-position header-->
                        <div class="page-header-space"></div>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <!--*** CONTENT GOES HERE ***-->
                        <div class="page" align="center">
                          <!-- <table class="tableheader" align="center">
                            <tr>
                              <td class="bold">STATISTICS</td>
                            </tr>
                            <tr>
                              <td>{{ data.filters.branch }}</td>
                            </tr>
                            <tr>
                              <td>{{ data.filters.start }} to {{ data.filters.end }}</td>
                            </tr>
                          </table>-->

                          <!-- <div class="section" align="center">STATISTICS</div> -->

                          <!-- <tr>
                            <td colspan="5">PAGE</td>
                          </tr>-->
                          <table class="items-table" id="items">
                            <thead>
                              <!-- <tr>
                                <td colspan="5">PAGE</td>
                              </tr>-->
                              <tr
                                class="items-table-header"
                                style="border-bottom: 1px solid black;"
                              >
                                <td style="width: 100mm" class="left">
                                  ANALYTES
                                  <!-- <h4></h4> -->
                                </td>
                                <td style="width: 30mm" class="center">RESULTS</td>
                                <td style="width: 20mm" class="center">QC</td>
                                <td style="width: 20mm" class="center">CALIBRATION</td>
                                <td style="width: 20mm" class="center">TOTAL</td>
                              </tr>
                            </thead>
                            <tbody class="items-body">
                              <!-- <tr>
                                <td colspan="5" class="left bold">
                                  {{
                                  group.analyte_group
                                  }}
                                </td>
                              </tr>-->

                              <tr
                                v-for="(item, itemObjKey) in data.statistics.data"
                                :key="item.analyte_id"
                                class="col-result"
                              >
                                <td style="width: 100mm">
                                  <!-- <span>
                                    <h5 v-if="!( (itemObjKey + 1) % 20)"></h5>-
                                  </span>-->
                                  {{ item.analyte}}
                                </td>

                                <td style="width: 30mm" class="center bold">{{ item.result_count }}</td>
                                <td style="width: 30mm" class="center bold">{{ item.qc_count }}</td>
                                <td
                                  style="width: 30mm"
                                  class="center bold"
                                >{{ item.calibration_count }}</td>
                                <td style="width: 30mm" class="center bold">{{ item.total }}</td>
                                <!-- <td style="width: 50mm" class="center">
                                  {{
                                  item
                                  .result
                                  .reference_range
                                  }}
                                </td>-->
                              </tr>
                            </tbody>
                          </table>

                          <table class="total">
                            <tr>
                              <td style="width: 100mm" class="bold">GRAND TOTAL</td>

                              <td
                                style="width: 30mm"
                                class="center bold"
                              >{{ data.statistics.total.totalResults }}</td>
                              <td
                                style="width: 30mm"
                                class="center bold"
                              >{{ data.statistics.total.totalQC }}</td>
                              <td
                                style="width: 30mm"
                                class="center bold"
                              >{{ data.statistics.total.totalCal }}</td>
                              <td
                                style="width: 30mm"
                                class="center bold"
                              >{{ data.statistics.total.grandTotal }}</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>
                        <!--place holder for the fixed-position footer-->
                        <div class="page-footer-space"></div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="page-footer" align="center">
                <!-- <table class="table-footer">
                  <tr>
                    <td class="signatory">
                      {{
                      data.user_settings
                      .user
                      .fullname_with_suffix
                      }}
                    </td>
                    <td class="spacer"></td>
                    <td class="signatory">
                      {{
                      data.user_settings
                      .site_info
                      .pathologist
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td class="position">Medical Technologist</td>
                    <td class="spacer"></td>
                    <td class="position">Pathologist</td>
                  </tr>
                </table>-->
              </div>
            </div>
          </div>
          <!-- OUTPUT -->
          <div align="right">
            <v-card>
              <v-card-actions>
                <v-btn color="indigo" @click="print()">
                  PRINT
                  <v-icon right>mdi-printer</v-icon>
                </v-btn>

                <v-btn color="cyan lighten-2" @click="close()">
                  Close
                  <v-icon right>mdi-close-circle</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/plugins/eventbus';

export default {
  props: {
    statisticsdata: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      output: null,
      data: {
        user_settings: {
          site_info: {
            site: null,
            address: null,
            contact: null,
          },
          user: {
            fullname_with_suffix: null,
          },
        },
        statistics: {
          total: {
            totalResults: null,
            totalQC: null,
            totalCal: null,
            grandTotal: null,
          },
          found: false,
        },
        filters: {
          branch: null,
          start: null,
          end: null,
        },
      },
      patient: {
        full_name: null,
        birthdata: null,
        age_simple: null,
        gender: null,
      },
      updated_data: {},
      interval: null,
      loading: null,
    };
  },
  methods: {
    getItemName(item) {
      // console.log(Object.keys(this.data).length)
      // if(!Object.keys(this.data).length)
      if (item.analyte_order_name) return item.analyte_order_name;
      else return item.analyte_group;
    },
    print() {
      this.interval = setTimeout(() => {
        let title = '';
        this.$htmlToPaperFull('printStatistics', title, [
          '/styles/statistics.css',
        ]);
        clearInterval(this.interval);
      }, 200);
    },
    close() {
      EventBus.$emit('CLOSE_STATISTICS_VIEW');
    },
    async getData() {
      this.interval = setInterval(() => {
        // const user_settings = this.$store.getters['settings/getUserSettings'];
        // this.data.user_settings = user_settings;
        this.data = this.$store.getters['statistics/getStatisticsData'];
        console.log(
          'this.data',
          this.$store.getters['statistics/getStatisticsData']
        );
        if (this.data.statistics.found) {
          clearInterval(this.interval);
          this.print();
          EventBus.$emit('CLOSE_STATISTICS_VIEW');
        }
      }, 100);
    },
  },
  computed: {
    // Site() {
    //   return 'Sitesss';
    //   return this.data.user_settings.site_info.site;
    // },
    // PatientName() {
    //   return this.patient.full_name;
    // },
    // Birthdate() {
    //   return this.patient.birthdate;
    // },
    // Age() {
    //   return this.patient.age_simple;
    // },
    // Gender() {
    //   return this.patient.gender;
    // },
    // DateOrdered() {
    //   return this.data.order_details.ordered_at;
    // },
    // DatePrinted() {
    //   return this.data.printed_at;
    // },
    // ChargeSlip() {
    //   return this.data.order_details.chargeslip;
    // },
    // Total() {
    //   return this.data.charge_slip.total_amount.toLocaleString();
    // },
  },
  watch: {
    statisticsdata(val) {
      this.data = val;
      this.patient = this.data.patient;
      // console.log('result_data_component', this.data)
    },
    updated_data(val) {
      //   console.log('UPDATED');
      this.data = val;
      this.patient = val.data.patient;
    },
  },
  mounted() {
    EventBus.$on('UPDATE_STATISTICS_VIEW', (payload) => {
      console.log('UPDATE_STATISTICS_VIEW', payload);
      // this.data = payload;
      this.getData();
    });
    this.getData();
  },
  created() {},
  beforeDestroy() {
    EventBus.$off('UPDATE_STATISTICS_VIEW');
  },
  destroyed() {
    // console.log('data', this.data)
    // this.data = {}
  },
};
</script>

<style>
/*
@import '../../../../public/styles/result.css';
*/
</style>
