
export default {
	api: '/api/wards',
	items: [],
	buttontip: 'New Ward',
	title: 'Wards',
	text: 'Hospital Wards',
	options: [
		{
			action: 'new',
			label: 'New Ward',
			actiontext: ['Enter New Ward', 'Enter New Ward Code'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Ward',
			actiontext: ['Edit Ward', 'Edit Ward Code'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Ward',
			actiontext: 'Delete Ward',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: true,
          text: 'Ward Code',
          value: 'code'
        },
        {
          sortable: true,
          text: 'Ward',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
}