<template>
  <v-container fill-height fluid grid-list-xl>
    <!-- <v-layout
    justify-center
    >-->

    <v-row>
      <v-col cols="12" md="12">
        <base-lisa-material-card
          color="orange"
          icon="mdi-file-document-multiple"
          inline
          class="px-5 py-3"
          :title="getAnalyteTitle"
        >
          <v-card class="my-0">
            <v-card-text>
              <!-- <base-subheading
              subheading="Navigation Pills"
              text="Horizontal Tabs"
              />-->

              <base-material-tabs color="warning">
                <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab }}</v-tab>

                <v-tab-item>
                  <v-card flat class="my-0">
                    <v-card-text>
                      <admin-analyte
                        :action="this.options.action"
                        :analyteData="analytePayload"
                        :key="analyteKey"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat class="my-0">
                    <v-card-text>
                      <admin-referencerangecontainer
                        :action="this.options.action"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat class="my-0">
                    <v-card-text>
                      <p>-</p>

                      <!-- <div>
                      Dynamically innovate resource-leveling customer service for state of the art customer service.
                      </div>-->
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </base-material-tabs>
            </v-card-text>
          </v-card>
        </base-lisa-material-card>
      </v-col>
    </v-row>

    <!-- </v-card>  -->

    <!-- </v-layout> -->
  </v-container>
</template>

<script>
// import {
//   ValidationObserver,
//   ValidationProvider,
//   withValidation
// } from "vee-validate";

// import {
//   fetchItems,
//   updateItem,
//   addItem,
//   deleteItem
// } from '../../store/modules/proc/axios'

import EventBus from '@/plugins/eventbus';
export default {
  props: {
    analyteData: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      analyteKey: 0,
      analytePayload: undefined,
      tab: 0,
      tabs: ['Analyte Details', 'Reference Range'],
      expand: false,
      refrangeheaders: [],
      refrangeitems: [],
      decimals: [
        {
          id: 0,
          name: '0',
        },
        {
          id: 1,
          name: '1',
        },
        {
          id: 2,
          name: '2',
        },
        {
          id: 3,
          name: '3',
        },
      ],
      operators: [
        {
          id: '*',
          name: '( * ) Multiply',
        },
        {
          id: '/',
          name: '( / ) Divide',
        },
      ],
      sex: [
        {
          id: 'A',
          name: 'ALL',
        },
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Male',
        },
      ],
      sections: [],
      specimens: [],
      units: [],
      analytegroups: [],
      reporttemplates: [],
      tab: null,
      text: null,
      loading: false,
      gradients: 'to top right, #c5deea, #8abbd7',
      dialog: false,
      options: {
        actiontext: [''],
        action: 'new',
      },
      id: '',
      item: '',
      item_: {
        id: '',
        hl7_code: '',
        code: '',
        analyte: '',
        analyte_order_name: '',
        specimen: { id: 3 },
        analyte_group_id: null,
        specimen_id: null,
        unit_id: null,
        section_id: null,
        decimal: null,
        operator: '',
        factor: 1,
        unit_id2: null,
        decimal2: null,
        amount: null,
        sort: null,
        calculated: false,
        report_template_id: null,
        active: null,
        default: false,
      },
      itemdata: {},
      message: '',
      module: '',
      snackbar: false,
      closedialog: false,
      switchlabel: 'Inactive',
      analyte_id: null,
    };
  },
  // components: {
  //   ValidationProvider,
  //   ValidationObserver
  // },
  watch: {
    analyteData: 'updateAnalyteData',
  },
  methods: {
    updateAnalyteData() {
      //   this.analytePayload = this.analyteData;
      //   console.log('this.analytePayload', this.analytePayload);
    },
    test() {
      alert(1);
      return true;
    },
    setAnalyteOrderName(val) {
      this.item_.order_name = val;
    },
    setAnalyteGroup(id) {
      this.item_.analyte_group_id = id;
    },
    setDefaultSpecimen(id) {
      this.item_.specimenid = id;
    },
    setSpecimen(id) {
      this.item_.specimen_id = id;
    },
    setUnit(id) {
      this.item_.unit_id = id;
    },
    setSection(id) {
      this.item_.section_id = id;
    },
    setDecimal(id) {
      this.item_.decimal = id;
    },
    setOperator(id) {
      this.item_.operator = id;
    },
    setUnit2(id) {
      this.item_.unit_id2 = id;
    },
    setDecimal2(id) {
      this.item_.decimal2 = id;
    },
    setReportTemplate(id) {
      this.item_.report_template_id = id;
    },
    setSex(id) {
      this.refrangeitems.sex = id;
    },
    closeDialog() {
      // this.dialog = true
      this.tab = 0;
      this.item_ = {};
      // this.item_.analyte = '';
      EventBus.$emit('CLEAR_REFRANGE');
      EventBus.$emit('CLOSE_ANALYTE_DIALOG');
    },
    async setOption(opt) {
      this.options = opt;
      // console.log(this.item_.default)
      return true;
      // if(this.options.action === 'edit')
      //   this.item = this.item_.name

      // if(this.options.action != 'delete')
      //   this.setFocus()
    },
    setSwitchLabel() {
      if (
        this.item_.active === undefined ||
        this.item_.active === 'NO' ||
        this.item_.active == false
      ) {
        this.item_.active = false;
        this.switchlabel = 'Inactive';
        //return 'Inactive'
      } else {
        this.item_.active = true;
        this.switchlabel = 'ACTIVE';
        // return 'Active'
      }
    },
    setActionText(index) {
      return this.options.actiontext[index];
    },
    async processItem() {
      this.loading = true;
      const isValid = await this.$refs.observer.validate();
      //   console.log(isValid);
      if (isValid) {
        // this.item_.name = this.item
        // this.item_.name = ''
        // console.log(this.item_)
        this.loading = true;
        var payload = {
          action: this.options.action,
          item: this.item_,
        };
        EventBus.$emit('PROCESS_ITEM', payload);
      } else {
        // Throw Validation Error
      }
    },
    async clear(toggle) {
      this.item = '';
      this.item_ = {};
      this.itemdata = {};
      requestAnimationFrame(() => {
        this.$refs.form.reset();
      });
      this.loading = false;

      if (this.options.action != 'new' || toggle || this.closedialog) {
        this.closeDialog();
      }
    },
    async setFocus() {
      this.$nextTick(() => this.$refs.hl7code.focus());
    },

    // async setLabel() {
    //   console.log(this.item_.default)
    //   if (this.item_.default === undefined || this.item_.default === 'NO' || this.item_.default == false) {
    //     this.item_.default = false
    //     return 'Set Default'
    //   }
    //   else {
    //     return 'Default Selected'
    //   }
    // }
    SetNewDefaults() {
      this.item_.operator = '*';
      this.item_.factor = 1;
      this.item_.amount = 0;
      this.item_.sort = 1;
      this.item_.analyte_group_id = 0;
      this.item_.calculated = false;
      this.switchlabel = 'ACTIVE';
      this.item_.active = true;
    },
    DisplayConsole() {
      console.log(this.sections);
    },
    setReferenceRangeParams(payload, instruments) {
      EventBus.$emit('SET_REFRANGE_OPTION', payload, instruments);
    },
    async initialize() {
      ++this.analyteKey;
      let analyteSettings = this.$store.getters['cache/getAnalyteSettings'];
      //   this.item_ = analyteSettings.itemdata;
      this.analytePayload = this.analyteData;
      this.item_ = this.analyteData;
      //   console.log('analyteSettings', analyteSettings);

      this.options = analyteSettings.option;
      switch (analyteSettings.option.action) {
        case 'new':
          EventBus.$emit('CLEAR_REFRANGE');
          // this.item_.analyte = null;
          break;
        case 'edit':
          // console.log(this.item_);
          EventBus.$emit('LOAD_REFERENCE_RANGE');
          break;
      }
    },
  },
  computed: {
    showTextfield() {
      if (this.options.action != 'delete') return true;
      else return false;
    },
    getIcon() {
      return this.options.icon;
    },
    getAction() {
      return this.options.action;
    },
    getItem() {
      return this.item_.name;
    },
    getAnalyteTitle() {
      return 'Configure Analyte';
    },
  },
  mounted() {
    console.log('AnalyteDialog Mounted', this.analyteData);
    // EventBus.$on(
    //   'SET_ANALYTE_DIALOG_OPTION',
    //   (
    //     payload,
    //     itemdata,
    //     module,
    //     sections,
    //     specimens,
    //     units,
    //     analytegroups,
    //     reporttemplates,
    //     instruments
    //   ) => {
    //     // console.log('SET_ANALYTE_DIALOG_OPTION')
    //     this.setOption(payload);
    //     if (this.options.action == 'edit') {
    //       this.refrangeheaders = this.$store.getters[
    //         'referenceranges/getHeaders'
    //       ];
    //       // this.setReferenceRangeParams(payload, instruments)
    //       // EventBus.$emit('SET_REFRANGE_OPTION', payload, instruments);
    //       // this.setAnalyteID(itemdata.id)
    //     }
    //     this.initialize();
    //   }
    // );
    this.initialize();
    // EventBus.$on('SET_ANALYTE_DIALOG_OPTION', (payload, itemdata, module, sections, specimens, units, analytegroups, reporttemplates) => {
    //   this.item_ = itemdata
    //   this.setOption(payload)
    //   this.module = module
    //   this.sections = sections
    //   this.specimens = specimens
    //   this.units = units
    //   this.analytegroups = analytegroups
    //   this.reporttemplates = reporttemplates
    //   this.setSwitchLabel()

    //   if(this.options.action == 'new')
    //     this.SetNewDefaults()
    //   // console.log(this.sections)

    //   if(this.options.action == 'edit')
    //     this.refrangeheaders = this.$store.getters['referenceranges/getHeaders']
    // }),
    // EventBus.$on('CLEAR_DIALOG', (payload) => {
    //   this.closedialog = payload
    //   this.clear();
    // }),
    // this.setDefaultSpecimen(3)
    EventBus.$on('ANALYTE_INITIALIZE_TAB', (payload) => {
      this.tab = 0;
    });
  },
  beforeDestroy() {
    EventBus.$off('SET_ANALYTE_DIALOG_OPTION');
    EventBus.$off('ANALYTE_INITIALIZE_TAB');
  },
  created() {
    // this.setDefaultSpecimen(3)
    // this.DisplayConsole();
  },
};
</script>

<style>
.v-toolbar__content {
  margin-left: 0px !important;
}

.v-datatable__actions {
  color: black;
}

.v-datatable__actions .v-btn {
  color: orange !important;
}
.v-messages__message {
  color: white;
}
</style>
