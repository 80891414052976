/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';

// Store functionality
import actions from './actions';
// import getters from './getters'
import modules from './modules';
import mutations from './mutations';
import state from './state';

import { initialize } from './modules/proc/general';
// import createPersistedState from 'vuex-persistedstate'

// import router from '../router';
// import axios from './modules/proc/axios';

Vue.use(Vuex);

modules.exports = {
    productionSourceMap: false
};
// Create a new store
const store = new Vuex.Store({
    // strict: true,
    actions,
    // getters,
    modules,
    mutations,
    state
});
// initialize(store, router);
export default store;
