<template>
  <validation-observer ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validateForm)">
      <v-card dark>
        <v-toolbar color="orange" dark>
          <v-btn icon dark>
            <v-icon>{{ getIcon }}</v-icon>
          </v-btn>
          <div class="toolbarlabel">
            <v-toolbar-title class="white--text">{{ options.label }}</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click.native="clear(true)" dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-title primary-title>
          <v-row>
            <v-col md="12">
              <v-card-title v-if="getAction==='delete'">
                Do you want to delete {{ getItem }}?
                <br />
                <br />This process can't be undone.
              </v-card-title>
            </v-col>
            <!-- SECTION MODULE -->
            <v-col md="12">
              <ValidationProvider
                name="code"
                rules="required|max:255"
                v-if="showTextByModule(0)"
                vid="code"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="item_.code"
                  :label="setActionText(1)"
                  class="orange-input"
                  :error-messages="errors"
                  required
                  ref="code"
                />
              </ValidationProvider>
            </v-col>

            <v-col md="12">
              <ValidationProvider
                name="item"
                rules="required|max:255"
                v-if="showTextfield"
                vid="item"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="item_.name"
                  :label="setActionText(0)"
                  class="orange-input"
                  :error-messages="errors"
                  required
                  ref="item"
                />
              </ValidationProvider>
            </v-col>

            <v-col md="12">
              <ValidationProvider
                name="sort"
                rules="numeric|required|max:255"
                v-if="showTextByModule(1)"
                vid="sort"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="item_.sort"
                  :label="setActionText(1)"
                  class="orange-input"
                  :error-messages="errors"
                  required
                  ref="sort"
                />
              </ValidationProvider>
            </v-col>

            <v-col md="12">
              <ValidationProvider
                name="view"
                rules="required|max:255"
                v-if="showTextByModule(2)"
                vid="view"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="item_.view"
                  :label="setActionText(1)"
                  class="orange-input"
                  :error-messages="errors"
                  required
                  ref="view"
                />
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider
                name="default"
                v-if="showTextByModule(3)"
                vid="default"
                v-slot="{ errors }"
              >
                <v-switch
                  v-model="item_.default"
                  :label="switchlabel"
                  @change="setSwitchLabel()"
                  color="orange"
                ></v-switch>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions right>
          <v-spacer></v-spacer>
          <div>
            <v-progress-circular indeterminate color="orange" :size="25" v-if="loading"></v-progress-circular>
            <v-btn
              text
              class="orange--text"
              v-on:click="processItem"
              :disabled="loading"
              v-if="getAction!='delete'"
            >Save</v-btn>
            <v-btn
              text
              class="orange--text"
              v-on:click="clear"
              :disabled="loading"
              v-if="getAction==='delete'"
            >Cancel</v-btn>
            <v-btn
              text
              class="orange--text"
              v-on:click="processItem"
              :disabled="loading"
              v-if="getAction==='delete'"
            >Yes</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </form>
  </validation-observer>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
} from 'vee-validate';

// import {
//   fetchItems,
//   updateItem,
//   addItem,
//   deleteItem
// } from '../../store/modules/proc/axios'

import EventBus from '@/plugins/eventbus';
export default {
  $_veeValidate: {
    validator: 'new',
  },

  data() {
    return {
      loading: false,
      gradients: 'to top right, #c5deea, #8abbd7',
      dialog: false,
      options: {
        actiontext: [''],
        action: 'new',
      },
      id: '',
      item: '',
      item_: {
        id: '',
        name: '',
        default: false,
      },
      itemdata: {},
      message: '',
      module: '',
      snackbar: false,
      closedialog: false,
      switchlabel: 'Set Default',
      othercomponent: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    closeDialog() {
      EventBus.$emit('CLOSE_DIALOG', this.module);
    },
    async setOption(opt) {
      this.options = opt;
      // console.log(this.item_.default)
      return true;
      // if(this.options.action === 'edit')
      //   this.item = this.item_.name

      // if(this.options.action != 'delete')
      //   this.setFocus()
    },
    setActionText(index) {
      return this.options.actiontext[index];
    },
    async processItem() {
      // const isValid = await this.$refs.observer.validate()
      const isValid = true;
      if (isValid) {
        this.loading = true;
        var payload = {
          action: this.options.action,
          item: this.item_,
          module: this.module,
          othercomponent: this.othercomponent,
        };
        EventBus.$emit('PROCESS_ITEM_', payload);
      }
    },
    async clear(toggle) {
      this.item = '';
      this.item_ = {};
      this.itemdata = {};
      requestAnimationFrame(() => {
        this.$refs.form.reset();
      });
      this.loading = false;

      // console.log(this.options.action)
      // console.log(toggle)
      // console.log(this.closedialog)

      if (this.options.action != 'new' || toggle || this.closedialog) {
        this.closeDialog();
      }
    },
    async setFocus() {
      if (this.module === 'sections')
        this.$nextTick(() => this.$refs.code.focus());
    },
    showTextByModule(index) {
      if (index === 0) {
        if (
          this.module === 'sections' ||
          this.module === 'specimens' ||
          this.module === 'wards' ||
          this.module === 'physicians'
        ) {
          if (this.options.action != 'delete') return true;
        }
      } else if (index === 1) {
        if (this.module === 'analytegroups') {
          if (this.options.action != 'delete') return true;
        }
      } else if (index === 2) {
        if (this.module === 'reporttemplates') {
          if (this.options.action != 'delete') return true;
        }
      } else if (index === 3) {
        if (this.module === 'pathologists') {
          if (this.options.action != 'delete') return true;
        }
      }

      return false;
    },
    setSwitchLabel() {
      if (
        this.item_.default == undefined ||
        this.item_.default === 'NO' ||
        this.item_.default == false
      ) {
        this.item_.default = false;
        this.switchlabel = 'Set Default';
      } else {
        this.item_.default = true;
        this.switchlabel = 'Default Selected';
      }
    },
    // async setLabel() {
    //   console.log(this.item_.default)
    //   if (this.item_.default === undefined || this.item_.default === 'NO' || this.item_.default == false) {
    //     this.item_.default = false
    //     return 'Set Default'
    //   }
    //   else {
    //     return 'Default Selected'
    //   }
    // }
  },
  computed: {
    showTextfield() {
      if (this.options.action != 'delete') return true;
      else return false;
    },
    // getActionSection(index) {
    //   var actiontext = this.options.actiontext[index]
    //   return actiontext
    // },
    getActionText(index) {
      return 'TEST';
      // return this.setActionText(0)
    },
    getIcon() {
      return this.options.icon;
    },
    getAction() {
      return this.options.action;
    },
    getItem() {
      return this.item_.name;
    },
  },
  mounted() {
    EventBus.$on(
      'SET_DIALOG_OPTION',
      (payload, itemdata, module, othercomponent) => {
        this.clear();
        this.item_ = itemdata;
        this.setOption(payload);
        this.module = module;
        this.othercomponent = othercomponent;
        // console.log('MODULE FOR DELETION: ' + this.module)
        this.setSwitchLabel();
      }
    );
    EventBus.$on('CLEAR_DIALOG', (payload) => {
      this.closedialog = payload;
      this.clear();
    });
    EventBus.$on('SET_ERRORS_IN_DIALOG', (payload) => {
      if (payload) {
        this.$refs.form.setErrors(payload);
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('SET_DIALOG_OPTION');
    EventBus.$off('CLEAR_DIALOG');
    EventBus.$off('SET_ERRORS_IN_DIALOG');
  },
  created() {},
};
</script>

<style>
.v-toolbar__content {
  margin-left: 0px !important;
}

.v-datatable__actions {
  color: black;
}

.v-datatable__actions .v-btn {
  color: orange !important;
}
.v-messages__message {
  color: white;
}
</style>