
export default {
	api: '/api/physicians',
	items: [],
	buttontip: 'New Physician',
	title: 'Physicians',
	text: 'Hospital Physicians',
	options: [
		{
			action: 'new',
			label: 'New Physician',
			actiontext: ['Enter New Physician', 'Enter New Physician HL7 Code'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Physician',
			actiontext: ['Edit Physician', 'Edit Physician HL7 Code'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Physician',
			actiontext: 'Delete Physician',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: true,
          text: 'HL7 Code',
          value: 'code'
        },
        {
          sortable: true,
          text: 'Physicians',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
}