import EventBus from '@/plugins/eventbus';

export function showNotification(payload) {
    var notif = payload;
    switch (notif.option) {
        case 'error':
            if (payload.response_status != 422) {
                notif.msg =
                    'Server can not process the data. Response Status: (' +
                    notif.response_status +
                    ')';
            }
            break;
        default:
    }
    EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
}

export function closePatientDialog(payload) {
    EventBus.$emit('CLOSE_PATIENT_DIALOG', payload);
}
