import {
    fetchItems,
    fetchItemsWithPayload,
    updateItem,
    updateItemPost,
    addItem,
    deleteItem
} from '../proc/axios';

export default {
    async fetchItems(context) {
        const response = await fetchItems(context.state.api);
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async searchItem(context, id) {
        const response = await fetchItems(context.state.api + '/search/' + id);
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async fetchValidatedResults(context, payload) {
        const response = await fetchItemsWithPayload(
            context.state.api + '/print',
            payload
        );
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async validate(context, payload) {
        const response = await addItem(context.state.api, payload);
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async addItem(context, item) {
        const response = await addItem(context.state.api, item);
        // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async updateItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await updateItem(url, item);
        return response;
    },
    async deleteItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await deleteItem(url, item);
        return response;
    },
    async updateAllResults(context, payload) {
        let response = await updateItemPost(
            context.state.api + '/update-all',
            payload
        );

        return response;
    },
    async processCalculatedResult(context, payload) {
        var action = payload.action;
        // const url = context.state.api + '/' + payload.item.id
        let response = 'null';

        if (payload.action === 'edit') {
            response = await updateItemPost(
                context.state.api + '/update',
                payload
            );
        }

        return response;
    },
    async processItem(context, payload) {
        var action = payload.action;
        // const url = context.state.api + '/' + payload.item.id
        let response = 'null';

        if (payload.action === 'edit') {
            response = await updateItemPost(
                context.state.api + '/update',
                payload
            );
        }

        // // return payload
        // if(action === 'new')
        // 	response = await addItem(context.state.api, payload.item)
        // else if(payload.action==='edit') {
        // 	// console.log('edit')
        // 	console.log('action', payload.action)
        // 	response = 'edit'
        // 	// response = await updateItemPost(context.state.api + '/update', payload)
        // 	// response = await updateItem(url , payload.item)
        // } else if(payload.action==='delete')
        // 	response = await deleteItem(url , payload.item)

        // // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async setRefresh(context, payload) {
        context.commit('UPDATE_REFRESH', payload);
    }
};
