export default {
    login(state) {
        state.loading = true;
        state.auth_error = null;
        // console.log('LOGGED IN');
    },
    loginSuccess(state, payload) {
        // localStorage.removeItem('user');
        // localStorage.removeItem('lisa');
        // localStorage.removeItem('analytes');
        // localStorage.removeItem('app_settings');
        // localStorage.removeItem('default_branch');
        localStorage.clear();

        state.auth_error = null;
        state.isLoggedIn = true;
        state.loading = false;
        state.currentUser = Object.assign({}, payload.user, {
            token: payload.access_token
        });
        // console.log(`payload.user ${payload.user}`);
        // console.log(payload.access_token);
        // console.log(state.currentUser);
        localStorage.setItem('user', JSON.stringify(state.currentUser));
        //localStorage.setItem("user", Object.assign({}, payload.user, {token: payload.access_token}));
    },
    loginFailed(state, payload) {
        // console.log('FAILED');
        state.loading = false;
        state.auth_error = payload.error;
    },
    logout(state) {
        // localStorage.removeItem("vuex");
        // console.log('LOGGING OUT')
        // localStorage.removeItem('user');
        // localStorage.removeItem('lisa');
        // localStorage.removeItem('analytes');
        // localStorage.removeItem('app_settings');
        // localStorage.removeItem('default_branch');
        localStorage.clear()
        // AppStorage.clear();
        state.isLoggedIn = false;
        state.currentUser = null;
    }
};
