import {
    fetchItems,
    updateItem,
    addItem,
    deleteItem,
    processItemWithPayload
} from '../proc/axios';

export default {
    async fetchItems(context) {
        const response = await fetchItems(context.state.api);
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async getPatientsWithStatus(context, payload) {
        let url = context.state.api + '/status/' + payload.status + '/paginate/' + payload.paginate
        if (payload.page) {
            url = url + '?page=' + payload.page
        }
        const response = await fetchItems(url);
        // console.log(response)
        context.commit('SAVE_ITEMS', response);
        return response;
    },
    async searchPatientsWithStatus(context, payload) {
        let url = context.state.api + '/status/' + payload.status + '/paginate/' + payload.paginate + '/search/' + payload.query

        if (payload.page) {
            url = url + '?page=' + payload.page
        }
        const response = await fetchItems(url);
        context.commit('SAVE_ITEMS', response);
        return response;
    },
    async searchItem(context, id) {
        const response = await fetchItems(context.state.api + '/search/' + id);
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async addItem(context, item) {
        const response = await addItem(context.state.api, item);
        // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async updateItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await updateItem(url, item);
        return response;
    },
    async deleteItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await deleteItem(url, item);
        return response;
    },
    async processItem(context, payload) {
        // console.log("processItempayload", payload);
        var action = payload.action;
        const url = context.state.api + '/' + payload.item.id;
        var response;

        if (action === 'new')
            response = await addItem(context.state.api, payload.item);
        else if (payload.action === 'edit')
            response = await updateItem(url, payload.item);
        else if (payload.action === 'delete')
            response = await deleteItem(url, payload.item);

        // context.commit('SAVE_ITEMS', response.data)
        // console.log("response", response);
        return response;
    },
    async checkPatientID(context, payload) {
        var response = await processItemWithPayload(
            context.state.api + '/check',
            payload
        );
        return response;
    },
    async setRefresh(context, payload) {
        context.commit('UPDATE_REFRESH', payload);
    }
};
