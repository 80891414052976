import {
	fetchItems,
	updateItem,
	addItem,
	deleteItem
} from '../proc/axios'

// import vuex from 'vuex'
export default {
	async fetchItems(context) {
		const response = await fetchItems(context.state.api)
		context.commit('SAVE_ITEMS', response.data)
		return response
	},
	async addItem(context, item) {
		// console.log(context.state.api)
		const response = await addItem(context.state.api, item)
		// context.commit('SAVE_ITEMS', response.data)
		return response
	},
	async updateItem(context, item) {
		// console.log(context.state.api)
		const url = context.state.api + '/' + item.id
		
		const response = await updateItem(url , item)
		// context.commit('SAVE_ITEMS', response.data)
		return response
	},
	async deleteItem(context, item) {
		// console.log(context.state.api)
		const url = context.state.api + '/' + item.id
		
		const response = await deleteItem(url , item)
		// context.commit('SAVE_ITEMS', response.data)
		return response
	},
	async processItem(context, payload) {
		
		var action = payload.action
		// console.log(action)
		const url = context.state.api + '/' + payload.item.id
		var response

		if(action === 'new') 
			response = await addItem(context.state.api, payload.item)
		else if(payload.action==='edit')
			response = await updateItem(url , payload.item)
		else if(payload.action==='delete')
			response = await deleteItem(url , payload.item)

		// console.log(response.data)
		context.commit('SAVE_ITEMS', response.data)
		return response
	},
	async setRefresh(context, payload) {
		context.commit('UPDATE_REFRESH', payload)
	}
	// fetchItems(context) {
	//     return new Promise((res, rej) => {
	//         axios.get('/api/sections')
	//             .then((response) => {
	//             	context.commit('saveItems', response.data.data)
	//             	console.log(response.data.data)
	//                 res(response.data);
	//             })
	//             .catch((err) => {
	//                 rej(err);
	//             })
	//     })
	// }

}