<template>
    <base-lisa-material-card
        color="info"
        icon="mdi-cog"
        inline
        class="px-5 py-3"
        :title="getTitle"
    >
        <!-- :text="getText" -->

        <!-- <v-card outlined>
    <v-card-actions>-->
        <v-tooltip top content-class="top">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    slot="activator"
                    color="success"
                    small
                    v-on:click="showDialog('new')"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-auto"
                >
                    <v-icon>mdi-plus</v-icon>NEW
                </v-btn>
            </template>
            <span>{{ ButtonTip }}</span>
        </v-tooltip>

        <v-tooltip top content-class="top">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    slot="activator"
                    color="orange"
                    small
                    v-on:click="fetchItems()"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-auto"
                >
                    <v-icon>mdi-refresh</v-icon>REFRESH
                </v-btn>
            </template>
            <span>Refresh Table</span>
        </v-tooltip>
        <!-- </v-card-actions>
    </v-card>-->

        <v-divider class="mt-3" />

        <v-data-table :headers="itemheaders" :items="items" :loading="loading">
            <template slot="headerCell" slot-scope="{ header }">
                <span
                    class="font-weight-light text-warning text--darken-3"
                    v-text="header.text"
                />
            </template>

            <template v-slot:progress>
                <v-progress-linear
                    color="info"
                    :height="3"
                    indeterminate
                    absolute
                ></v-progress-linear>
            </template>

            <template slot="items" slot-scope="{ item }">
                <!-- <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
        -->
                <td v-for="value in item" v-if="checkModule()">{{ value }}</td>

                <!-- PATHOLOGISTS -->
                <td v-if="module == 'pathologists'">{{ item.id }}</td>
                <td v-if="module == 'pathologists'">{{ item.name }}</td>
                <td v-if="module == 'pathologists'">
                    <v-chip
                        :color="setColor(item.default)"
                        dark
                        :small="true"
                        >{{ item.default }}</v-chip
                    >
                </td>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip top content-class="top">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            slot="activator"
                            class="v-btn--simple"
                            icon
                            @click="editItem(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Edit {{ item.analyte }}</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" width="500px" persistent>
            <admin-dialogv2
                :propItems="itemdata"
                :propOptions="propOptions"
                :propModule="module"
            />
        </v-dialog>

        <!-- <v-snackbar :color="optSnackbar.color" top right v-model="snackbar" dark>
      <v-icon color="white" class="mr-3">
        {{ optSnackbar.icon }}
      </v-icon>
      <div>
        <strong>{{ optSnackbar.title }}</strong>
        - {{ optSnackbar.msg }}
      </div>
      <v-icon size="14" @click="snackbar = false">mdi-close-circle</v-icon>
    </v-snackbar>-->
    </base-lisa-material-card>
</template>

<script>
import EventBus from '@/plugins/eventbus';
export default {
    props: {
        module: {
            type: String, // [1*] This will validate the 'max' prop to be a Number.
            default() {
                return '';
            }
        }
    },

    data: () => ({
        dialogKey: 0,
        title: '',
        text: '',
        ButtonTip: '',
        items: [],
        dialog: false,
        analytedialog: false,
        optData: {},
        itemdata: {},
        color: null,
        colors: ['purple', 'info', 'success', 'warning', 'error'],
        snackbar: false,
        itemheaders: [
            {
                sortable: true,
                text: 'ID',
                value: 'id'
            },
            {
                sortable: true,
                text: 'Name',
                value: 'name'
            },
            {
                sortable: false,
                text: 'Actions',
                value: 'actions',
                align: 'left'
            }
        ],
        optSnackbar: {},
        options: [],
        propOptions: [],
        snackbarsettings: [],
        action: '',
        refresh: true,
        refreshparameters: true,
        onprocess: false,
        sections: [],
        specimens: [],
        units: [],
        analytegroups: [],
        reporttemplates: [],
        instruments: [],
        loading: undefined,
        analyte_id: null,
        interval: undefined,
        dataLoaded: false,
        loadCounter: 0
    }),
    methods: {
        async fetchItems() {
            console.log('fetchItems: ' + this.module);
            this.loading = true;

            this.itemdata = {};
            await this.$store
                .dispatch(this.module + '/fetchItems')
                .then(res => {
                    this.items = res.data;
                    this.dataLoaded = true;

                    clearInterval(this.interval);
                })
                .catch(error => {
                    var notif = {
                        option: 'error',
                        msg: 'Error in getting ' + this.title + ' data.',
                        error: error
                    };
                    if (error.response.data.status == 401) {
                        clearInterval(this.interval);
                    }
                    // EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
                })
                .finally(() => {
                    this.loading = false;
                    // this.refresh = false;
                });
        },
        async processItem(payload) {
            console.log(payload);
            let notif = {};
            console.log('payload.action: ' + payload.action);
            var module = payload.module;

            if (module === '' || module === undefined) {
                module = this.module;
            }
            console.log('process');
            this.$store
                .dispatch(module + '/processItem', payload)
                .then(res => {
                    this.items = res.data;
                    notif = { option: 'success', msg: '', show: true };
                    EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

                    if (this.action === 'new' && !payload.othercomponent) {
                        this.onprocess = true;
                        this.refresh = true;

                        EventBus.$emit('CLEAR_DIALOG_' + this.module, false);
                        // } else if (this.action !='new' && module != 'referenceranges') {
                    } else if (
                        payload.action === 'delete' &&
                        payload.othercomponent
                    ) {
                        if (payload.module === 'referenceranges')
                            EventBus.$emit('LOAD_REFERENCE_RANGE');
                        notif = {
                            option: 'success',
                            msg: 'Delete Successful!'
                        };
                        EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
                        this.dialog = false;
                    } else if (this.action != 'new') {
                        EventBus.$emit('CLEAR_DIALOG_' + this.module, true);
                    }

                    // this.dataLoaded = true;
                })
                .catch(error => {
                    notif = {
                        option: 'error',
                        msg:
                            error.response.data.message +
                            ' (' +
                            error.response.status +
                            ')',
                        error: error
                    };
                    EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

                    EventBus.$emit(
                        'SET_ERRORS_IN_DIALOG_' + this.module,
                        error
                    );
                    // this.clear()
                });
            // }
        },
        async processItemFromEvent(payload) {
            this.processItem(payload);
        },
        init() {
            // this.ButtonTip = this.$store.getters[this.module + '/getButtonTip']
            this.options = this.$store.getters[this.module + '/getOptions'];
            // Object.keys(this.options).forEach(key => console.log(key, this.options[key]));
            this.ButtonTip = this.options[0].label;
            this.snackbarsettings = this.$store.getters[
                'settings/getSnackbarSettings'
            ];
            this.title = this.$store.getters[this.module + '/getTitle'];
            this.text = this.$store.getters[this.module + '/getText'];
            this.itemheaders = this.$store.getters[this.module + '/getHeaders'];

            clearInterval(this.interval);
            this.interval = setInterval(() => {
                // console.log(this.dataLoaded);
                if (this.loadCounter == 10) clearInterval(this.interval);
                if (this.dataLoaded == false) {
                    if (!this.loading) this.fetchItems();
                } else {
                    clearInterval(this.interval);
                    this.dataLoaded = false;
                }
                this.loadCounter++;
            }, 500);
        },
        showDialog(opt) {
            this.onprocess = true;
            this.refresh = true;
            this.action = opt;
            var [option] = this.options.filter(el => el.action == opt);
            this.propOptions = option;

            // alert(this.module);
            EventBus.$emit(
                'SET_DIALOG_OPTION_' + this.module,
                option,
                this.itemdata,
                this.module
            );
            this.dialog = true;
        },
        showDeleteDialog(opt, itemdata, module) {
            this.onprocess = true;
            this.refresh = true;

            var options = this.$store.getters[module + '/getOptions'];
            var [option] = options.filter(el => el.action == opt);

            EventBus.$emit(
                'SET_DIALOG_OPTION_' + this.module,
                option,
                itemdata,
                module,
                true
            );
            this.dialog = true;
        },
        async setAnalyteID(id) {
            this.$store
                .dispatch('cache/setAnalyteID', id)
                .then(res => {
                    this.analyte_id = id;
                })
                .catch(error => {});
        },
        async showNotification(notification) {
            // console.log('showNotification')

            if (notification.show == false) {
                this.dialog = false;
                return;
            }

            var opt = notification.option;
            var [option] = this.snackbarsettings.filter(el => el.option == opt);

            this.optSnackbar = Object.assign({}, option);

            if (this.action === 'delete')
                this.optSnackbar.msg = 'Delete Success!';

            if (notification.msg != (null || ''))
                this.optSnackbar.msg = notification.msg;

            // if(opt === 'success') {
            //   this.optSnackbar = () => { return this.snackbarsettings[0] }
            //   if(this.action === 'delete') {
            //     this.optSnackbar.msg = 'Success!'
            //   }
            //   console.log(this.snackbarsettings[0])
            // } else if(opt === 'fail') {
            //   this.optSnackbar = this.snackbarsettings[1]
            //   if(msg != '') {
            //     this.optSnackbar.msg = msg
            //   }
            // }
            this.snackbar = true;
        },
        editItem(item) {
            this.itemdata = item;
            this.showDialog('edit');
        },
        deleteItem(item) {
            // this.itemdata.id = id
            // this.itemdata.name = name
            this.itemdata = item;
            this.showDialog('delete');
        },
        setValue(value) {
            if (value === 'NO') {
                return '<v-chip>Example Chip</v-chip>';
            } else {
                return '<v-chip>Example Chip</v-chip>';
            }
        },
        setColor(value) {
            if (value === 'YES' || value === 1) {
                if (this.module == 'analytes') {
                    this.items.active = 'YES';
                }

                return 'orange';
            } else {
                return '';
            }
        },
        checkModule() {
            if (this.module != 'pathologists' && this.module != 'analytes') {
                return true;
            } else {
                return false;
            }
        },
        analyteModule() {
            if (this.module == 'analytes') return true;
            else return false;
        }
    },
    computed: {
        getTitle() {
            return this.title;
        },
        getText() {
            return this.text;
        },
        getOption() {
            return this.module;
        }
    },
    mounted() {
        // EventBus.$on('EVENT_NAME', (payload) => {
        //   this.testAlert()
        // }),
        EventBus.$on('CLOSE_DIALOG', payload => {
            if (this.module == payload) this.fetchItems();
            this.dialog = false;
        });
        // EventBus.$on('CLOSE_ANALYTE_DIALOG', (payload) => {
        //   this.fetchItems();
        //   this.analytedialog = false;
        // });
        EventBus.$on('SAVE_ITEM', payload => {
            this.dialog = false;
        });
        EventBus.$on('SHOW_NOTIFICATION', payload => {
            this.showNotification(payload);
        });
        EventBus.$on('SHOW_DELETE_DIALOG', (payload, module) => {
            this.showDeleteDialog('delete', payload, module);
        });

        EventBus.$on('PROCESS_ITEM_' + this.module, payload => {
            this.processItemFromEvent(payload);
        });
    },
    beforeDestroy() {
        EventBus.$off('CLOSE_DIALOG');
        // EventBus.$off('CLOSE_ANALYTE_DIALOG');
        EventBus.$off('SAVE_ITEM');
        EventBus.$off('SHOW_NOTIFICATION');
        EventBus.$off('SHOW_NOTIFSHOW_DELETE_DIALOGICATION');
        EventBus.$off('PROCESS_ITEM_' + this.module);
    },
    created() {
        this.init();
    },
    ready() {
        // events.$on('eventGreet', () => {
        //   alert('from Parent');
        // });
    }
};
</script>

<style>
/*
.v-datatable__actions {
  display: none;
} */
#core-view {
    padding-bottom: 100px;
}
.v-btn {
    color: #orange;
}
.v-btn--icon {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px !important;
}

.v-btn--round {
}
</style>
