
export default {
	api: '/api/sections',
	items: [],
	buttontip: 'New Section',
	title: 'Sections',
	text: 'Laboratory Sections',
	options: [
		{
			action: 'new',
			label: 'New Section',
			actiontext: ['Enter New Section', 'Enter New Code'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Section',
			actiontext: ['Edit Section', 'Edit Code'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Section',
			delactiontext: 'Delete Section',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: true,
          text: 'Code',
          value: 'code'
        },
        {
          sortable: true,
          text: 'Name',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
	
}