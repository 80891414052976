import { setAuthorization } from './general';
import axios from 'axios';

export function login(credentials) {
    return new Promise((res, rej) => {
        axios
            .post('/api/auth/login', credentials)
            .then(response => {
                res(response.data);
            })
            .catch(err => {
                rej('Invalid Username or Password');
                // console.log('ERROR: ' + err);
            });
    });
}

export function getLocalUser() {
    const userStr = localStorage.getItem('user');
    // console.log(localStorage.getItem("user"));
    if (!userStr) {
        return null;
    }

    return JSON.parse(userStr);
}

export function logout() {
    return new Promise((res, rej) => {
        axios
            .post('/api/auth/logout')
            .then(response => {
                // console.log(response);
                res(response.data);
            })
            .catch(err => {
                // rej('Invalid Username or Password');
            });
    });
}
