<template>
  <!-- <v-container fill-height fluid grid-list-xl> -->
  <v-layout justify-center wrap>
    <v-row>
      <!-- <v-card color="white" dark> -->
      <v-card color="white">
        <!-- <user-searchcomponent :module="'orders'" class="analytes" /> -->
        <user-searchcomponent :module="'orders'" class="analytes" />
      </v-card>

      <!-- <v-card class="pa-2">
        <v-row>
          <v-col cols="12" md="12">
            <v-layout row wrap class="pl-4 pr-4">
              <div v-for="item in analyte_groups" :key="item.id" class="items">
                <v-btn
                  class="order-button"
                  :disabled="item.disabled"
                  color="blue-grey darken-1"
                  @click="addAnalyteGroup(item);item.disabled = !item.disabled;"
                  v-show="item.show_in_order"
                >
                  {{ item.code }}
                  <v-icon right>mdi-alphabet-g-box-outline</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-col>
        </v-row>
      </v-card>-->

      <v-container>
        <v-slide-y-transition class="py-0" group tag="v-card">
          <v-card
            outlined
            v-for="(item, index) in items"
            :key="item.id"
            class="my-2 orders"
            ripple
            hover
          >
            <!--     <div v-for="item in items"
            :key="item.id" class="items">-->
            <!-- <div class="items" > -->

            <v-layout row wrap class="pl-4 pr-4">
              <v-row>
                <v-col md="1" class="margin-fix">
                  <!-- <div class="caption grey--text text-xs-left"> Action </div> -->
                  <div class="text-xs-center">
                    <!-- <v-btn
                  color="grey darken-2"
                  @click="clear()"
                  icon
                >
                  
                  <v-icon right>mdi-close-circle</v-icon>
                    </v-btn>-->
                    <v-tooltip top content-class="top">
                      <v-btn
                        slot="activator"
                        class="v-btn--simple"
                        color="danger"
                        icon
                        @click="removeItem(index)"
                      >
                        <v-icon color="error">mdi-close</v-icon>
                      </v-btn>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col md="1" class="margin-fix hidden-sm-and-down">
                  <div class="caption grey--text">ID</div>
                  <div>{{ getItemID(item) }}</div>
                </v-col>

                <v-col md="4" class="margin-fix">
                  <div class="caption grey--text">Analyte</div>
                  <div style="font-weight: bold">{{ getAnalyteName(item) }}</div>
                </v-col>

                <v-flex md="2" class="margin-fix hidden-sm-and-down">
                  <div class="caption grey--text">Specimen</div>
                  <div>{{ getSpecimen(item) }}</div>
                </v-flex>

                <v-col md="2" class="margin-fix hidden-sm-and-down">
                  <div class="caption grey--text">Section</div>
                  <div>{{ item.section }}</div>
                </v-col>

                <v-col md="2" class="margin-fix text-xs-right">
                  <div class="caption grey--text">Amount</div>
                  <div>{{ item.amount.toLocaleString() }}</div>
                </v-col>
              </v-row>
            </v-layout>

            <v-divider></v-divider>

            <!-- </div> -->

            <!-- </div> -->
          </v-card>
        </v-slide-y-transition>

        <v-slide-y-transition class="py-0">
          <v-card outlined class="mt-4 orders" ripple hover v-show="this.items.length">
            <v-layout wrap class="pl-4 pr-4">
              <v-row>
                <v-col md="9">
                  <div class="caption grey--text text-xs-left">Selection</div>
                  <div class="text-xs-left">
                    <span class="selected">
                      {{
                      selected
                      }}
                    </span>
                    Item/s Selected
                  </div>
                </v-col>

                <v-col md="3">
                  <div class="caption grey--text text-xs-right">TOTAL AMOUNT</div>
                  <div class="text-xs-right total">{{ total.toLocaleString() }}</div>
                </v-col>
              </v-row>
            </v-layout>
          </v-card>
        </v-slide-y-transition>

        <v-slide-y-transition class="py-0">
          <v-card flat class="mt-4 orders" v-show="this.items.length">
            <v-layout wrap class="right">
              <v-flex xs12 md12>
                <v-btn color="amber darken-4" @click="addSample()">
                  SAVE
                  <v-icon right>mdi-check</v-icon>
                </v-btn>

                <v-btn color="cyan lighten-2" @click="clear(true)">
                  Clear
                  <v-icon right>mdi-close-circle</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-slide-y-transition>
      </v-container>
    </v-row>
    <!-- <v-btn
          color="amber darken-4"
          @click="testpdf();"
        >
          TEST
          <v-icon right> mdi-check </v-icon>
        </v-btn>
        <button @click="$refs.myPdfComponent.print()">print</button>
  <pdf ref="myPdfComponent" src="/pdfjs/tracemonkey.pdf"></pdf>

    <pdf src="./static/test.pdf"></pdf>-->
    <v-dialog v-model="dialog" width="100%">
      <user-chargeslip :patientdata="chargesdata" />
    </v-dialog>
    <!-- </v-container> -->
  </v-layout>
</template>
<script>
// import ItemTemplate from '../search/Item.vue'
import pdf from "vue-pdf";

import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  Validator,
} from "vee-validate";

import EventBus from "@/plugins/eventbus";
const messages = {
  en: {
    item: "Item | Items",
  },
};
export default {
  props: {
    patientdata: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },
  components: {
    pdf,
  },
  //
  data() {
    return {
      patient: {},
      // items: [
      //   // { id: 1, analyte_order_name: 'ANALYTE 1', specimen: 'Serum', section: 'CHEMISTRY', amount: '10000000.01' },
      //   // { id: 2, analyte_order_name: 'ANALYTE 2', specimen: 'Serum', section: 'CHEMISTRY', amount: '1000.02' },
      //   // { id: 3, analyte_order_name: 'ANALYTE 3', specimen: 'Serum', section: 'CHEMISTRY', amount: '1000.10' },
      //   // { id: 4, analyte_order_name: 'ANALYTE 4', specimen: 'Serum', section: 'CHEMISTRY', amount: '1000' },
      //   // { id: 5, analyte_order_name: 'ANALYTE 5', specimen: 'Serum', section: 'CHEMISTRY', amount: '1000' }
      // ],

      item: [
        {
          id: 9,
          name: "Lion",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        },
        {
          id: 10,
          name: "Cat",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        },
      ],
      items: [],
      options: [],
      dialog: false,
      chargesdata: {},
      // template: ItemTemplate,

      selected_ids: [],
      analyte_groups: [
        {
          id: 1,
          code: "LIPID",
          analyte_group: "LIPID PROFILE",
          section_id: 1,
          section: "CHEM",
          show_in_order: 1,
          amount: 500,
          is_group: true,
          disabled: false,
          index: null,
        },
        {
          id: 3,
          code: "LIVER PROFILE",
          analyte_group: "LIVER PROFILE",
          section_id: 1,
          section: "CHEM",
          show_in_order: 1,
          amount: 500,
          is_group: true,
          disabled: false,
          index: null,
        },
        {
          id: 2,
          code: "CBC",
          analyte_group: "CBC",
          section_id: 2,
          section: "HEMA",
          show_in_order: 1,
          amount: 100,
          is_group: true,
          disabled: false,
          index: null,
        },
      ],

      // items: [
      //   { active: true, title: 'Jason Oner', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg' },
      //   { active: true, title: 'Ranee Carlson', avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg' },
      //   { title: 'Cindy Baker', avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg' },
      //   { title: 'Ali Connors', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg' },
      // ],
      items2: [
        {
          title: "Travis Howard",
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        },
      ],
    };
  },

  methods: {
    testpdf() {
      alert(1);
    },
    getLabel(item) {
      return item.name;
    },
    getItemID(item) {
      let val = null;
      if (!item.is_group) val = item.id;
      else val = item.code;
      return val;
    },
    getAnalyteName(item) {
      let val = null;
      if (!item.is_group) val = item.analyte_order_name;
      else val = item.analyte_group;
      return val;
    },
    getSpecimen(item) {
      let val = null;
      if (!item.is_group) val = item.specimen;

      return val;
    },
    // getAmount(item) {
    //     let val = null
    //     if (!item.is_group)
    //       val = item.specimen
    //     else
    //       val = item.analyte_group
    //     return val
    // },
    updateItems(text) {
      this.items = this.item;
      // yourGetItemsMethod(text).then( (response) => {
      //   this.items = response
      // })
    },
    removeItem(index) {
      this.removeIndex(index);
      EventBus.$emit("CLEAR_SEARCH");
    },
    removeIndex(index) {
      this.analyte_groups.forEach((val) => {
        if (val.id == this.items[index].id) {
          val.disabled = false;
        }
      });
      this.items.splice(index, 1);

      // this.selected_ids = this.$store.getters['cache/getSelectedAnalyteIDs']
      // this.selected_ids.splice(index, 1)
      this.$store.dispatch("cache/setSelectedAnalytes", this.items);
    },
    addAnalyteGroup(item) {
      let selected = this.$store.getters["cache/getSelectedAnalytes"];
      let exists = false;

      selected.forEach(function (val, index) {
        if (item.code === val.code) {
          exists = true;
        }
      });

      if (!exists) {
        this.items.push(item);
        this.$store.dispatch("cache/setSelectedAnalytes", this.items);
      } else {
        let notif = {
          option: "fail",
          msg: item.analyte_group + " is already Selected",
          show: true,
        };
        EventBus.$emit("SHOW_NOTIFICATION", notif);
      }
    },
    addItem(payload) {
      this.items.push(payload);
      this.$store.dispatch("cache/setSelectedAnalytes", this.items);
    },
    clearOrders() {
      this.items = [];
      this.$store.dispatch("cache/setSelectedAnalytes", this.items);
      this.analyte_groups.forEach((val) => {
        val.disabled = false;
      });
    },
    clear() {
      this.clearOrders();
      EventBus.$emit("CLEAR_SEARCH");
    },
    async addSample() {
      this.options.action = "new";
      await this.processItem();
    },
    async processItem() {
      // this.dialog = true
      // return false
      let isValid = true;
      let notif = { option: "success", msg: "", show: true };

      if (!Object.keys(this.patient).length || !this.items.length) {
        // console.log(this.items.length)
        // console.log(Object.keys(this.patient).length)
        isValid = false;
      }

      if (isValid) {
        this.loading = true;

        let data = { patient: this.patient, items: this.items };
        console.log(data);
        let payload = {
          action: this.options.action,
          item: data,
          module: null,
        };

        this.$store
          .dispatch("samples/processItem", payload)
          .then((res) => {
            if (this.options.action == "delete")
              notif = {
                option: "success",
                msg: "Delete Successful!",
                show: true,
              };

            this.chargesdata = res.data;
            // console.log(this.chargesdata)
            this.dialog = true;
            EventBus.$emit("SHOW_NOTIFICATION", notif);

            this.clear();
            if (this.module == "orders") {
              var payload = { show: true, data: res.data };
              EventBus.$emit("CLOSE_DIALOG", payload);
            }
          })
          .catch((error) => {
            notif = { option: "fail", msg: "" };

            if (error.response.status === 422) {
              var err = this.$store.getters["settings/getErrors"];
              var errors = "";
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(
                (key) => (errors = err.data.errors[key][0])
              );
              notif = { option: "fail", msg: errors };
              EventBus.$emit("SHOW_NOTIFICATION", notif);
            } else {
              notif = {
                option: "fail",
                msg:
                  "Server can not process the Item. (" +
                  error.response.status +
                  ")",
              };
              EventBus.$emit("SHOW_NOTIFICATION", notif);
            }
          })
          .finally((this.loading = false));
      } else {
        // console.log(!this.patient)

        if (!Object.keys(this.patient).length)
          notif = {
            option: "fail",
            msg: "Patient is Required",
            show: true,
          };
        else
          notif = {
            option: "fail",
            msg: "Please select Analyte/s",
            show: true,
          };

        EventBus.$emit("SHOW_NOTIFICATION", notif);
      }
    },
  },
  computed: {
    selected() {
      return this.items.length;
    },
    total() {
      if (!this.items.length) return 0;
      return this.items.reduce(function (prev, cur) {
        return prev + Number(cur.amount);
      }, 0);
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
  },

  watch: {},

  created() {},
  mounted() {
    EventBus.$on("LOAD_PATIENT", (payload) => {
      console.log("LOAD_PATIENT2");
      this.patient = payload;
      // console.log(this.patient)
    });
    EventBus.$on("CLEAR_ORDERS", (payload) => {
      // console.log('CLEAR_ORDERS')
      this.clearOrders();
    });
    EventBus.$on("ADD_ITEM", (payload) => {
      this.addItem(payload);
      // console.log(this.ids)
    });
  },
  destroyed() {
    this.clearOrders();
  },
};
//v-btn--simple v-btn v-btn--icon theme--light
</script>

<style>
.card-buttons {
  background: #f8bbd0 !important;
}
.order-button {
  font-size: 10pt !important;
}
.margin-fix {
  margin-top: -0.5%;
  margin-bottom: -1.1%;
}
.total {
  font-weight: bold;
  font-size: 12pt;
  color: red;
}
.selected {
  font-weight: bold;
}
.v-btn--icon {
  width: 20px;
  height: 20px;
  line-height: 0px;
  padding: 0px;
}
.ordercard {
  background: white;
}
.orders {
  background: white;
  font-size: 10pt;
}
.patient {
  font-weight: bold;
}
.patientcard {
  margin-top: 9px !important;
}
.analytes {
  margin-top: 10px !important;
}
</style>
