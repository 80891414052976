<template>
  <!-- <v-container fluid> -->
  <base-lisa-material-card
    color="info"
    :title="title"
    icon="mdi-face"
    class="user_class"
  >
    <!-- <template v-slot:after-heading>
        <div class="title font-weight-light">New User</div>
    </template>-->
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(validateForm)">
        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="email"
              v-slot="{ errors }"
              name="Username"
              :rules="rules.username"
            >
              <v-text-field
                v-model.trim="user.email"
                label="Username"
                class="color"
                :error-messages="errors"
                required
                ref="email"
                color="info"
                v-if="show"
                :loading="usernameLoading"
                @blur="checkUsername()"
                v-on:keyup="clearMsg()"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <v-scroll-x-transition>
              <div v-if="showUsernameMsg">
                <div class="mt-5 ml-n5" v-if="usernameFound">
                  <v-icon color="error" class="mb-1">mdi-close-thick</v-icon>
                  <span class="mb-n6 red--text text-subtitle-2">{{
                    usernameAvailability
                  }}</span>
                </div>

                <div class="mt-5 ml-n5" v-else>
                  <v-icon color="success" class="mb-1">mdi-check-bold</v-icon>
                  <span
                    class="mb-n6 text-success text-subtitle-2"
                    style="color: #388e3c"
                    >{{ usernameAvailability }}</span
                  >
                </div>
              </div>
            </v-scroll-x-transition>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="last_name"
              v-slot="{ errors }"
              name="Last Name"
              rules="required|max:255"
            >
              <v-text-field
                v-model.trim="user.last_name"
                label="Last Name"
                class="color"
                :error-messages="errors"
                ref="last_name"
                color="info"
                required
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider
              vid="first_name"
              v-slot="{ errors }"
              name="First Name"
              rules="required|max:255"
            >
              <v-text-field
                v-model.trim="user.first_name"
                label="First Name"
                class="color"
                :error-messages="errors"
                required
                ref="first_name"
                color="info"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider
              vid="middle_name"
              v-slot="{ errors }"
              name="Middle Name"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="user.middle_name"
                label="Middle Name"
                class="color"
                :error-messages="errors"
                ref="middle_name"
                color="info"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider
              vid="sex"
              v-slot="{ errors }"
              name="Gender"
              rules="required"
            >
              <v-select
                v-model.trim="user.sex"
                :items="sex"
                label="Gender"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                color="info"
                ref="sex"
                @change="setSex"
                required
              ></v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="30"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  vid="dob"
                  v-slot="{ errors }"
                  name="Date of Birth"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.dob"
                    label="Date of Birth (YYYY-MM-DD)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="purple"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                ref="picker"
                v-model.trim="date"
                @input="menu = false"
                @change="save"
                color="purple"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="suffix"
              v-slot="{ errors }"
              name="Suffix"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="user.suffix"
                label="Suffix"
                class="color"
                :error-messages="errors"
                ref="suffix"
                :color="color"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="license_no"
              v-slot="{ errors }"
              name="License Number"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="user.license_no"
                label="License Number"
                class="color"
                :error-messages="errors"
                ref="license_no"
                :color="color"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="contact_no"
              v-slot="{ errors }"
              name="Contact Number"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="user.contact_no"
                label="Contact Number"
                class="color"
                :error-messages="errors"
                ref="contact_no"
                :color="color"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="email_address"
              v-slot="{ errors }"
              name="Email Address"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="user.email_address"
                label="Email Address"
                class="color"
                :error-messages="errors"
                ref="email_address"
                :color="color"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="site_id"
              v-slot="{ errors }"
              name="Branch"
              rules="required"
            >
              <v-select
                :items="branches"
                :label="selectBranch.label"
                :error-messages="errors"
                color="deep-purple accent-4"
                item-text="branch_name"
                item-value="id"
                v-model="selectBranch.model"
                @change="setBranch"
                item-color="deep-purple accent-4"
                :loading="selectBranch.loading"
                required
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="bc_printer"
              v-slot="{ errors }"
              name="Barcode Printer"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="user.bc_printer"
                label="Barcode Printer Name"
                class="color"
                :error-messages="errors"
                ref="bc_printer"
                :color="color"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="password"
              v-slot="{ errors }"
              name="Password"
              rules="max:50"
            >
              <v-text-field
                v-model.trim="rawPassword"
                label="Password"
                class="color"
                :error-messages="errors"
                ref="password"
                :color="color"
                type="password"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="password_confirmation"
              v-slot="{ errors }"
              name="Repeat Password"
              rules="max:50"
            >
              <v-text-field
                v-model.trim="rawPassConfirmation"
                label="Repeat Password"
                class="color"
                :error-messages="errors"
                ref="password_confirmation"
                :color="color"
                type="password"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" text-center>
            <v-card outlined>
              <v-card-actions>
                <v-btn
                  class="font-weight-light"
                  color="indigo"
                  type="submit"
                  :disabled="loading"
                  :loading="loading"
                >
                  {{ buttonLabel }}
                  <v-icon right>mdi-account-check</v-icon>
                </v-btn>

                <v-btn
                  color="grey darken-2"
                  @click="clear()"
                  :disabled="btnClear.disabled"
                >
                  Clear
                  <!-- <v-icon right>mdi-close-circle</v-icon> -->
                </v-btn>

                <v-btn color="grey darken-2" outlined text @click="close()">
                  Close
                  <v-icon right>mdi-close-circle</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </form>
    </validation-observer>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-2"
            >Please enter your current password:</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="currentPassword"
                  label="Current Password"
                  class="color"
                  ref="current_password"
                  :color="color"
                  type="password"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="dark--hrey"
            text
            @click="
              currentPassword = undefined;
              dialog = false;
            "
            >Close</v-btn
          >
          <v-btn
            color="indigo"
            @click="
              dialog = false;
              processItem();
            "
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-lisa-material-card>

  <!-- </v-container> -->
</template>

<script>
import EventBus from '@/plugins/eventbus';
// import Base64 from '@/plugins/base64';

export default {
  name: 'User',

  props: {
    userData: {
      type: Object,
      default: {},
    },
    action: {
      type: String,
      default: 'new',
    },
    module: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      dialog: undefined,
      title: undefined,
      show: undefined,
      usernameAvailability: undefined,
      showUsernameMsg: undefined,
      usernameFound: undefined,
      rawPassword: undefined,
      rawPassConfirmation: undefined,
      currentPassword: undefined,
      btnClear: {
        disabled: false,
      },
      rules: {
        username: '',
      },
      usernameLoading: undefined,
      selectBranch: {
        model: undefined,
        label: 'Branch',
        loading: false,
      },
      app_settings: undefined,
      branches: undefined,
      color: 'info',
      user: {
        dob: undefined,
        sex: 'U',
        bc_printer: 'Zebra1',
      },
      options: {},
      menu: false,
      date: undefined,
      loading: undefined,
      sex: [
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Female',
        },
      ],
    };
  },

  methods: {
    setSex(id) {
      this.user.sex = id;
    },
    setBranch(value) {
      this.user.site_id = value;
    },
    save(date) {
      this.$refs.menu.save(date);
      this.user.dob = date;
    },
    validateForm(scope) {
      this.validate();
    },
    async validate() {
      if (this.usernameFound) {
        notif = {
          option: 'error',
          msg: 'Please choose other username.',
        };
        EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        return false;
      }

      this.user.action = this.action;

      if (this.rawPassword) {
        this.user.password = btoa(this.rawPassword);
      }

      if (this.rawPassConfirmation) {
        this.user.password_confirmation = btoa(this.rawPassConfirmation);
      }

      if (this.action == 'edit') {
        this.dialog = true;
      } else if (this.action == 'new') {
        this.processItem();
      }
    },
    async processItemLocal() {
      let payload = {
        action: this.action,
        item: this.user,
      };
      this.$store
        .dispatch('users/processItemLocal', payload)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log('Saved to Local.');
        });
    },
    async processItem() {
      let notif = {
        option: 'success',
        msg: undefined,
        error: undefined,
      };

      let payload = {
        action: this.action,
        item: this.user,
      };

      if (this.action == 'edit') {
        if (this.currentPassword) {
          this.user.current_password = btoa(this.currentPassword);
        } else {
          notif = {
            option: 'error',
            msg: 'Please provide your current password.',
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          return false;
        }
      }

      EventBus.$emit('SHOW_LOADER', true);
      this.loading = true;

      this.$store
        .dispatch('users/processItem', payload)
        .then((res) => {
          console.log('res', res);
          notif.msg = res.data.message;

          switch (this.action) {
            case 'new':
              EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
              this.clear();
              break;
            case 'edit':
              EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);
              this.close();
              //   if (this.module === 'userProfile') {
              //     EventBus.$emit('CLOSE_USER_SETTINGS', notif);
              //   }
              break;
          }
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.form.setErrors(err.response.data.errors);
          }
          notif = {
            option: 'error',
            msg: err.response.data.message,
            error: err,
          };
          this.currentPassword = undefined;
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally(() => {
          this.loading = false;
          EventBus.$emit('SHOW_LOADER', false);
        });
    },

    clearMsg() {
      this.showUsernameMsg = false;
    },

    async checkUsername() {
      if (!this.user.email) return;

      this.loading = true;
      this.usernameLoading = true;

      let payload = {
        username: this.user.email,
      };

      this.$store
        .dispatch('users/checkUsername', payload)
        .then((res) => {
          this.showUsernameMsg = true;
          this.usernameFound = res.data.found;
          this.usernameAvailability = res.data.message;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
          this.usernameLoading = false;
        });
    },

    async clear(toggle) {
      this.user = {};
      this.selectBranch.model = undefined;
      this.showUsernameMsg = undefined;
      this.usernameFound = undefined;
      this.usernameAvailability = undefined;
      this.rawPassword = undefined;
      this.rawPassConfirmation = undefined;
      this.currentPassword = undefined;

      requestAnimationFrame(() => {
        this.$refs.form.reset();
      });
    },

    async close() {
      this.clear();
      EventBus.$emit('CLOSE_USER_DIALOG');
    },

    async init() {
      this.$nextTick(function () {
        switch (this.action) {
          case 'new':
            this.title = 'New User';
            this.show = true;
            this.rules.username = 'required|max:10';
            this.btnClear.disabled = false;

            break;
          case 'edit':
            this.title =
              this.module === 'userProfile'
                ? 'Update User Settings'
                : 'Update User';
            // this.title = 'Update User';
            this.show = false;
            this.rules.username = '';
            this.user = this.userData;
            this.selectBranch.model = this.user.site_id;
            this.btnClear.disabled = true;
            break;
        }

        this.app_settings = JSON.parse(localStorage.getItem('app_settings'));
        if (this.app_settings) {
          this.branches = [];
          this.branches = this.app_settings.branches;

          // this.selectBranch.model = this.app_settings.default_branch.id;
        }
      });
      // clearTimeout(this._timerId);
      // this._timerId = setInterval(() => {
      //   const app_settings = this.$store.getters['cache/getAppSettings'];

      //   if (app_settings) {
      //     this.branches = [];
      //     this.branches = app_settings.branches;

      //     this.branches.push({ id: 0, branch: 'ALL' });
      //     // this.branches.push(app_settings.branches);
      //     this.selectBranch.model = app_settings.default_branch.id;

      //     this.sampleStatus = app_settings.sample_status;
      //     this.selectStatus.model = 0;
      //     clearTimeout(this._timerId);
      //   }
      // }, 500);
    },
  },

  mounted() {
    this.init();
    EventBus.$on('UPDATE_USER_DATA', (payload) => {
      this.init();
    });
  },

  beforeDestroy() {
    EventBus.$off('UPDATE_USER_DATA');
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    // userData(val) {
    //   this.$nextTick(function () {
    //     this.user = val;
    //     this.selectBranch.model = this.user.site_id;
    //   });
    // },
    // action(val) {
    //   this.$nextTick(function () {
    //     this.action = val;
    //   });
    // },
  },

  computed: {
    buttonLabel() {
      if (this.action == 'new') {
        return 'Save New User';
      } else {
        return 'Update';
      }
    },
  },
};
</script>

<style scoped>
.user_class {
  z-index: 1 !important;
}
</style>
