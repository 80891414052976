import {
    fetchItems,
    updateItem,
    addItem,
    deleteItem,
    processItemWithPayload
} from '../proc/axios';

export default {
    saveErrors(context, error) {
        context.commit('SAVE_ERRORS', error);
    },
    async fetchItems(context) {
        const response = await fetchItems(context.state.api);
        // context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async fetchItem(context, id) {
        const response = await fetchItems(context.state.api + '/' + id);
        // context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async processItem(context, payload) {
        var action = payload.action;
        const url = context.state.api + '/' + payload.item.id;
        var response = undefined;

        if (action === 'new')
            response = await addItem(context.state.api, payload.item);
        else if (payload.action === 'edit')
            response = await updateItem(url, payload.item);
        else if (payload.action === 'delete')
            response = await deleteItem(url, payload.item);

        // context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async processItemLocal(context, payload) {
        var action = payload.action;
        const url = context.state.localApi + '/' + payload.item.id;
        var response = undefined;

        if (action === 'new_dont_process')
            response = await addItem(context.state.api, payload.item);
        else if (payload.action === 'edit')
            response = await updateItem(url, payload.item);
        else if (payload.action === 'delete')
            response = await deleteItem(url, payload.item);

        // context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async checkUsername(context, payload) {
        var response = await processItemWithPayload(
            context.state.api + '/check',
            payload
        );
        return response;
    },
    async addItem(context, item) {
        const response = await addItem(context.state.api, item);
        // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async updateItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await updateItem(url, item);
        return response;
    },
    async fetchUserSettings(context) {
        const response = await fetchItems(context.state.api);
        context.commit('SAVE_SETTINGS', response.data);
        return response;
    }
};
