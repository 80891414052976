
export default {
	api: '/api/results',
	items: [],
	buttontip: 'New Sample',
	title: 'Patients',
	text: 'Laboratory Samples',
	options: [
		{
			action: 'new',
			label: 'New Sample',
			actiontext: ['Enter New Sample'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Sample',
			actiontext: ['Edit Sample'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Sample',
			actiontext: 'Delete Sample',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: true,
          text: 'Sample Code',
          value: 'code'
        },
        {
          sortable: true,
          text: 'Sample',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
}