<template>
  <v-container id="validation-forms" fluid tag="section" fill-height>
    <v-row wrap>
      <v-col cols="12" lg="9">
        <base-lisa-material-card
          color="info"
          icon="fa-user-circle-o"
          title="Patient Information"
          class="py-3 px-5"
        >
          <v-expand-transition>
            <v-row v-if="newOrder">
              <v-col cols="12" md="9">
                <!-- <v-autocomplete
                  v-model="model"
                  :items="entries"
                  :loading="isLoading"
                  :search-input.sync="search"
                  color="grey darken-1"
                  hide-no-data
                  hide-selected
                  item-text="description"
                  item-value="id"
                  label="Search Patient..."
                  placeholder="Start typing to Search"
                  prepend-icon="fa4 fa-check"
                  return-object
                  @change="setValue"
                  v-on:keyup.delete="clear()"
                  v-on:keyup.backspace="clear()"
                  v-on:keyup="clear()"
                  class="patient"
                  no-filter
                >-->
                <!-- color="grey darken-1" -->
                <v-autocomplete
                  v-model="model"
                  :items="entries"
                  :loading="isLoading"
                  :search-input.sync="search"
                  color="deep-purple accent-4"
                  hide-selected
                  item-text="patient_description"
                  item-value="id"
                  label="Search Patient..."
                  prepend-icon="mdi-account-multiple-check"
                  return-object
                  @change="setValue"
                  class="patient"
                  chips
                  hide-details
                  solo
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Start typing to Search
                        <strong>Patient</strong>...
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <!-- color="#318fb5" -->
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      :color="item.color"
                      class="white--text"
                      v-on="on"
                    >
                      <v-icon left>fa-user-circle-o</v-icon>
                      <span v-text="item.full_name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar :color="item.color" class="white--text"
                      >{{ item.first_name.charAt(0)
                      }}{{ item.last_name.charAt(0) }}</v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.patient_description"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="'PATIENT ID: ' + item.patient_id"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>fa-user-circle-o</v-icon>
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="3" class="mt-3">
                <v-btn
                  color="info"
                  @click="
                    clear();
                    patients = true;
                  "
                >
                  New Patient
                  <v-icon right>mdi-account-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expand-transition>

          <!-- CHANGE PATIENT -->
          <v-expand-transition>
            <v-row v-if="changePatient">
              <v-col cols="12" md="7">
                <div>
                  <v-autocomplete
                    v-model="model"
                    :items="entries"
                    :loading="isLoading"
                    :search-input.sync="search"
                    color="grey darken-1"
                    hide-selected
                    item-text="patient_description"
                    item-value="id"
                    label="Search Patient..."
                    prepend-icon="mdi-account-edit"
                    return-object
                    @change="setValue"
                    class="patient mt-2"
                    chips
                    hide-details
                    solo
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Start typing to Search
                          <strong>Patient</strong>...
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                      >
                        <v-icon left>fa-user-circle-o</v-icon>
                        <span v-text="item.full_name"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        :color="item.color"
                        class="white--text"
                        >{{ item.first_name.charAt(0)
                        }}{{ item.last_name.charAt(0) }}</v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.patient_description"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="'PATIENT ID: ' + item.patient_id"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon>fa-user-circle-o</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>

              <v-expand-transition>
                <v-col cols="12" md="5" class="mt-5">
                  <!-- <v-btn color="light-green" @click="clear();patients = true;">
                  New Patient
                  <v-icon right>mdi-account-plus</v-icon>
                  </v-btn>-->

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="showNewPatientForUpdate()"
                        class="ml-n2 mb-7"
                        x-small
                      >
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>New Patient</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="info"
                        @click="updatePatientInOrder()"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="btnUpdatePatient.disabled"
                        :loading="btnUpdatePatient.loading"
                        class="mb-7"
                      >
                        Update
                        <v-icon right>mdi-account-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Update Patient</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey darken-2"
                        @click="cancelUpdate()"
                        v-bind="attrs"
                        v-on="on"
                        class="mb-7"
                      >
                        Cancel
                        <v-icon right>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancel Update</span>
                  </v-tooltip>
                </v-col>
              </v-expand-transition>
            </v-row>
          </v-expand-transition>

          <v-row>
            <v-expand-transition>
              <v-card-text v-if="model" class="mt-n5 mb-n5">
                <v-row>
                  <v-col cols="12" md="3">
                    <div class="caption grey--text">PATIENT ID</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="patient">
                          <span>{{ patient.patient_id }}</span>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="3">
                    <div class="caption grey--text">LAST NAME</div>
                    <v-expand-transition>
                      <!-- <div v-if="!showPatientDetails"> -->
                      <div class="bold" v-if="showPatientDetails">
                        <!-- <v-expand-transition> -->
                        <v-tooltip top v-if="!changePatient && !newOrder">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="#E91E63"
                              v-bind="attrs"
                              v-on="on"
                              @click="showUpdatePatientSelection()"
                              class="ml-n2 mt-n1"
                            >
                              <v-icon>mdi-account-edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Change Patient</span>
                        </v-tooltip>
                        <!-- </v-expand-transition> -->
                        <!-- <v-expand-transition> -->
                        <span v-if="showPatientDetails" class="patient">{{
                          patient.last_name
                        }}</span>
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="3">
                    <div class="caption grey--text">
                      <span>FIRST NAME</span>
                    </div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="bold">
                          <span class="patient">{{ patient.first_name }}</span>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="3">
                    <div class="caption grey--text">MIDDLE NAME</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="patient">{{ patient.middle_name }}</div>
                      </div>
                    </v-expand-transition>
                  </v-col>
                </v-row>

                <v-row class="mt-n3">
                  <v-col cols="12" md="3">
                    <div class="caption grey--text">AGE</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="bold">{{ patient.age }}</div>
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="1">
                    <div class="caption grey--text">GENDER</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="bold">{{ patient.gender }}</div>
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="2">
                    <div class="caption grey--text">DATE OF BIRTH</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="bold">{{ patient.birthdate }}</div>
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="3">
                    <div class="caption grey--text">LAST VISIT DATE</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="bold" v-if="last_visit.loading">
                          <v-progress-circular
                            indeterminate
                            color="info"
                            :width="3"
                            :size="20"
                          ></v-progress-circular>
                        </div>
                        <!-- <v-expand-transition> -->
                        <div class="bold" v-if="!last_visit.loading">
                          <span>
                            {{ last_visit.data.order_date }}
                            <br />
                            {{ last_visit.data.order_human_diff }}
                          </span>
                        </div>
                        <!-- </v-expand-transition> -->
                      </div>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="12" md="3">
                    <div class="caption grey--text">LAST BRANCH VISIT</div>
                    <v-expand-transition>
                      <div v-if="showPatientDetails">
                        <div class="bold" v-if="last_visit.loading">
                          <v-progress-circular
                            indeterminate
                            color="info"
                            :width="3"
                            :size="20"
                          ></v-progress-circular>
                        </div>
                        <v-expand-transition>
                          <div class="bold" v-if="!last_visit.loading">
                            <span>{{ last_visit.data.branch }}</span>
                          </div>
                        </v-expand-transition>
                      </div>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expand-transition>
          </v-row>
        </base-lisa-material-card>
      </v-col>
    </v-row>

    <v-row class="mt-n12 mb-2">
      <v-expand-transition>
        <v-col cols="12" md="9">
          <v-card class="mb-n3" color="deep-purple accent-4" dark>
            <v-row class="mt-n2 ml-3" v-if="showChargeslip">
              <v-col cols="12" md="3">
                <div class="caption">CHARGE SLIP</div>
                <div class="bold">
                  {{ order_details.chargeslip_details.chargeslip }}
                </div>
              </v-col>

              <v-col cols="12" md="3">
                <div class="caption">ORDER DATE/TIME</div>
                <div class="bold">
                  {{ order_details.chargeslip_details.ordered_at }}
                </div>
              </v-col>

              <v-col cols="12" md="3">
                <div class="caption">ORDERED BY</div>
                <div class="bold">
                  {{ order_details.chargeslip_details.ordered_by }}
                </div>
              </v-col>

              <v-col cols="12" md="3">
                <div class="caption">BRANCH</div>
                <div class="bold">
                  {{ order_details.chargeslip_details.branch }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-expand-transition>
    </v-row>

    <!-- <v-divider /> -->
    <!-- <v-divider class="mx-4" :inset="false"></v-divider> -->

    <v-row>
      <v-col cols="12" md="2" align="center" justify="center">
        <v-select
          :items="patientTypes"
          label="Patient Type"
          outlined
          color="deep-purple accent-4"
          item-text="type"
          item-value="id"
          v-model="defaultPatientType"
          @change="setPatientType"
          item-color="deep-purple accent-4"
          :loading="loadingPatientType"
        ></v-select>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          v-model="ORNumber"
          label="OR Number"
          placeholder="Type OR Number..."
          outlined
          height="20px"
          color="deep-purple accent-4"
        />
      </v-col>

      <v-col cols="12" md="2" align="center" justify="center">
        <v-select
          :items="branches"
          label="Branch"
          outlined
          color="deep-purple accent-4"
          item-text="branch_name"
          item-value="id"
          v-model="defaultBranch"
          @change="setBranch"
          item-color="deep-purple accent-4"
          :loading="loadingBranch"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="sampleID"
          label="Barcode / Chargeslip"
          placeholder="Type Barcode / Chargeslip..."
          outlined
          height="20px"
          color="deep-purple accent-4"
          v-on:keyup.enter="searchOrder()"
        />
      </v-col>

      <v-col cols="12" md="5" class="mt-2" justify="space-around">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="blue-grey"
              v-bind="attrs"
              v-on="on"
              @click="searchOrder()"
              class="ml-n2"
              x-small
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search Barcode / Chargeslip</span>
        </v-tooltip>

        <v-btn v-if="newOrder" color="indigo" @click="processOrder()">
          Save Order
          <v-icon right>mdi-playlist-check</v-icon>
        </v-btn>

        <v-btn v-else color="indigo" @click="printChargeslip()">
          Print Chargeslip
          <v-icon right>mdi-printer</v-icon>
        </v-btn>

        <v-btn color="grey darken-2" outlined @click="clear()">
          Clear
          <v-icon right>mdi-close-circle</v-icon>
        </v-btn>
      </v-col>

      <!-- <v-col cols="12" md="2" align="center" justify="center">
        <v-select
          :items="branches"
          label="Branch"
          outlined
          color="deep-purple accent-4"
          item-text="branch"
          item-value="id"
          v-model="defaultBranch"
          @change="setBranch"
          item-color="deep-purple accent-4"
          :loading="loadingBranch"
        ></v-select>
      </v-col> -->
      <!-- </v-col> -->
    </v-row>
    <v-divider class="mx-4" :inset="false"></v-divider>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="mx-auto testselection info" dark shaped>
          <v-card-title>
            <span class="mt-3 align_center">
              {{ chemistry_card.section }}
            </span>
          </v-card-title>

          <!-- <v-card
                            class="mx-5 mt-n5 mb-5 elevation-5"
                            v-show="chemistry_card.showProfile"
          >-->
          <v-card-title>
            <span class="mt-n5">&nbsp;</span>
          </v-card-title>
          <span class="title_center">
            <v-checkbox
              v-for="item in chemistry_card.profiles"
              :key="item.id"
              @change="
                selectTestsByProfile(item.id, item.section_id, item.selected)
              "
              :label="item.name"
              v-model="item.selected"
              class="mt-n5 ml-5 chk-color-dark"
            ></v-checkbox>
          </span>
          <!-- </v-card> -->
        </v-card>
        <v-card class="mx-auto testselection mt-n2" shaped>
          <!-- <v-card-text> -->
          <v-card-title>
            <span class="text-center">
              &nbsp;
              <!-- {{ chemistry_card.section }} -->
            </span>
          </v-card-title>
          <!-- </v-card-text> -->

          <!-- <v-card
                            class="mx-5 mt-n5 mb-5 elevation-5"
                            v-show="chemistry_card.showProfile"
                        >
                            <v-card-title> &nbsp; </v-card-title>
                            <v-checkbox
                                v-for="item in chemistry_card.profiles"
                                :key="item.id"
                                @change="test(item.id)"
                                :label="item.profile_name"
                                v-model="item.selected"
                                class="mt-n5 ml-3"
                                color="info"
                            ></v-checkbox>
          </v-card>-->

          <v-card-text>
            <v-checkbox
              v-for="item in chemistry_card.analytes"
              :key="item.id"
              @change="selectTest(item.id, item.section_id, item.selected)"
              :label="item.analyte_order_name"
              v-model="item.selected"
              class="mt-n5 chk-color"
              color="info"
            ></v-checkbox>
          </v-card-text>
          <!-- <v-card-actions>
                            <v-btn text color="deep-purple accent-4"
                                >Learn More</v-btn
                            >
          </v-card-actions>-->
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="mx-auto testselection" color="#E91E63" shaped dark>
          <v-card-title>
            <span class="mt-3 align_center">
              {{ immunoserology_card.section }}
            </span>
          </v-card-title>

          <v-card-title>
            <span class="mt-n5">&nbsp;</span>
          </v-card-title>
          <span class="title_center">
            <v-checkbox
              v-for="item in immunoserology_card.profiles"
              :key="item.id"
              @change="
                selectTestsByProfile(item.id, item.section_id, item.selected)
              "
              :label="item.name"
              v-model="item.selected"
              class="mt-n5 ml-5 chk-color-dark"
              dark
            ></v-checkbox>
          </span>
        </v-card>

        <v-card class="mx-auto testselection mt-n2" shaped>
          <v-card-title>
            <span class="text-center">&nbsp;</span>
          </v-card-title>

          <v-card-text>
            <v-checkbox
              v-for="item in immunoserology_card.analytes"
              :key="item.id"
              @change="selectTest(item.id, item.section_id, item.selected)"
              :label="item.analyte_order_name"
              v-model="item.selected"
              class="mt-n5 chk-color"
              color="#E91E63"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="mx-auto testselection deep-purple accent-4" shaped dark>
          <v-card-title>
            <span class="mt-3 align_center">
              {{ hematology_card.section }}
            </span>
          </v-card-title>

          <v-card-title>
            <span class="mt-n5">&nbsp;</span>
          </v-card-title>
          <span class="title_center">
            <v-checkbox
              v-for="item in hematology_card.profiles"
              :key="item.id"
              @change="
                selectTestsByProfile(item.id, item.section_id, item.selected)
              "
              :label="item.name"
              v-model="item.selected"
              class="mt-n5 ml-5 chk-color-dark"
              dark
            ></v-checkbox>
          </span>
        </v-card>

        <v-card class="mx-auto testselection mt-n2" shaped>
          <v-card-title>
            <span class="text-center">&nbsp;</span>
          </v-card-title>

          <v-card-text>
            <v-checkbox
              v-for="item in hematology_card.analytes"
              :key="item.id"
              @change="selectTest(item.id, item.section_id, item.selected)"
              :label="item.analyte_order_name"
              v-model="item.selected"
              class="mt-n5 chk-color"
              color="deep-purple accent-4"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="mx-auto testselection orange accent-4" shaped dark>
          <v-card-title>
            <span class="mt-3 align_center">
              {{ microscopy_card.section }} & <br />
              BACTERIOLOGY
            </span>
          </v-card-title>

          <v-card-title>
            <span class="mt-n5">&nbsp;</span>
          </v-card-title>
          <span class="title_center">
            <v-checkbox
              v-for="item in microscopy_card.profiles"
              :key="item.id"
              @change="
                selectTestsByProfile(item.id, item.section_id, item.selected)
              "
              :label="item.name"
              v-model="item.selected"
              class="mt-n5 ml-5 chk-color-dark"
              dark
            ></v-checkbox>
          </span>
        </v-card>

        <v-card class="mx-auto testselection mt-n2" shaped>
          <v-card-title>
            <span class="text-center">&nbsp;</span>
          </v-card-title>

          <v-card-text>
            <v-checkbox
              v-for="item in microscopy_card.analytes"
              :key="item.id"
              @change="selectTest(item.id, item.section_id, item.selected)"
              :label="item.analyte_order_name"
              v-model="item.selected"
              class="mt-n5 chk-color"
              color="orange accent-4"
            ></v-checkbox>
          </v-card-text>
        </v-card>

        <v-card class="mx-auto testselection mt-n2" shaped>
          <v-card-title>
            <span class="text-center">&nbsp;</span>
          </v-card-title>

          <v-card-text>
            <v-checkbox
              v-for="item in bacteriology_card.analytes"
              :key="item.id"
              @change="selectTest(item.id, item.section_id, item.selected)"
              :label="item.analyte_order_name"
              v-model="item.selected"
              class="mt-n5 chk-color"
              color="orange accent-4"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- </v-layout> -->
    <!-- </v-responsive> -->
    <!-- </v-expand-transition> -->

    <!-- </v-row> -->

    <!-- <v-row> -->
    <!-- <v-col cols="12" md="4"> -->
    <!-- <user-orderselection /> -->
    <!-- <v-card color="white"> -->
    <!-- <user-searchcomponent :module="'orders'" class="analytes" /> -->
    <!-- </v-card> -->
    <!-- </v-col> -->
    <!-- </v-row> -->

    <v-dialog v-model="patients" persistent max-width="1000px">
      <user-patients :action="'new'" :module="'orders'" />
    </v-dialog>

    <v-dialog v-model="chargeslip" persistent width="300">
      <user-chargeslip :patientdata="chargesdata" class="hidden" />
      <!-- <v-card color="cyan accent-4" dark>
                    <v-card-text dark>
                        <span style="color: white">
                            Please stand by
                        </span>
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
      </v-card>-->
    </v-dialog>
    <!-- </v-layout> -->
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  // Validator,
} from 'vee-validate';

import EventBus from '@/plugins/eventbus';
import updateDefaultBranch from '@/store/modules/helpers/siteHelper';

export default {
  //
  default_analytes: {},
  data() {
    return {
      default_branch: {},
      app_settings: {},
      chemistry_card_defaults: [],
      chemistry_card: {
        section: undefined,
        showProfile: false,
        profiles: [
          {
            id: 0,
            profile_name: undefined,
          },
        ],
        analytes: [],
      },
      immunoserology_card_defaults: [],
      immunoserology_card: {
        section: undefined,
        showProfile: false,
        profiles: [
          {
            id: 0,
            profile_name: undefined,
          },
        ],
        analytes: [],
      },
      hematology_card_defaults: [],
      hematology_card: {
        section: undefined,
        showProfile: false,
        profiles: [
          {
            id: 0,
            profile_name: undefined,
          },
        ],
        analytes: [],
      },
      microscopy_card_defaults: [],
      microscopy_card: {
        section: undefined,
        showProfile: false,
        profiles: [
          {
            id: 0,
            profile_name: undefined,
          },
        ],
        analytes: [],
      },
      bacteriology_card_defaults: [],
      bacteriology_card: {
        section: undefined,
        showProfile: false,
        profiles: [
          {
            id: 0,
            profile_name: undefined,
          },
        ],
        analytes: [],
      },
      barangays: [],
      branches: [
        {
          id: 0,
          branch: undefined,
        },
      ],
      patientTypes: [
        {
          id: 1,
          type: 'Non-Employee',
        },
        {
          id: 2,
          type: 'Employee',
        },
        {
          id: 3,
          type: 'Pregnant',
        },
      ],
      defaultPatientType: 1,
      defaultBranch: 1,
      loadingPatientType: false,
      loadingBranch: false,
      chargesdata: {},
      test_selection: [],
      order_details: {},
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      selected: false,
      patient: {},
      current_patient: {},
      update_patient: null,
      changePatient: null,
      btnUpdatePatient: {
        disabled: true,
        loading: false,
      },
      disableBtnUpdatePatient: true,
      loadingBtnPatientUpdate: false,
      showPatientDetails: true,
      last_visit: {
        loading: false,
        data: {
          order_human_diff: undefined,
        },
      },
      patients: null,
      chargeslip: null,
      color: 'success',
      item_: {},
      options: {},
      menu: false,
      date: null,
      loading: false,
      showChargeslip: false,
      validator: null,
      // buttonLabel: 'Save Patient',
      sex: [
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Female',
        },
      ],
      newOrder: true,
      _timerID: null,
      sampleID: undefined,
      ORNumber: undefined,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    filterObject(item, queryText, itemText) {
      // let index = queryText.indexOf(',');
      // let qLast = undefined;
      // let qFirst = undefined;
      // let qText = null;
      // if (index > 0) {
      //   qText = queryText.split(',');
      //   qLast = qText[0];
      //   qFirst = qText[1];
      // }
      // // console.log(qLast, qFirst);
      // // console.log(itemText.toLocaleLowerCase());
      // // console.log(item.last_name.toLocaleLowerCase());

      // let last_name = item.last_name.toLocaleLowerCase();
      // let first_name = item.first_name.toLocaleLowerCase();
      // console.log('items', item);
      return item;
      // return (
      //   item.prop1.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
      //     -1 ||
      //   item.prop2.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
      //     -1
      // );
    },
    async processOrder() {
      let isValid = true;
      let notif = { option: 'success', msg: 'SUCCESS', show: true };

      if (!Object.keys(this.patient).length || !this.test_selection.length) {
        // console.log(this.items.length)
        // console.log(Object.keys(this.patient).length)
        isValid = false;
      }

      var action = 'new';
      if (isValid) {
        EventBus.$emit('SHOW_LOADER', true);
        this.loading = true;

        // let data = { patient: this.patient, items: this.items };

        let data = {
          branchID: this.defaultBranch,
          patientTypeID: this.defaultPatientType,
          ORNumber: this.ORNumber,
          newOrder: this.newOrder,
          patient: this.patient,
          items: this.test_selection,
        };
        console.log('data', data);
        let payload = {
          action: action,
          item: data,
          module: null,
        };

        this.$store
          .dispatch('samples/processItem', payload)
          .then((res) => {
            notif.msg = 'Saving Successful!';
            if (this.options.action == 'delete')
              notif = {
                option: 'success',
                msg: 'Delete Successful!',
                show: true,
              };

            this.chargesdata = res.data;
            // this.savePrintBarcode(res.data);
            // this.chargeslip = true;
            // console.log(this.chargesdata)

            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
            // EventBus.$emit('LOAD_CHARGESLIP');

            this.isLoading = false;
            this.loading = false;
            EventBus.$emit('SHOW_LOADER', false);
            //   this.chargeslip = false;
            //   this.showChargeslip = false;

            //   let chargeslip = this.chargesdata.charges_slip.id;
            //   window.open('/order/pdf/' + chargeslip, '_blank');
            this.$nextTick(() => {
              window.open(
                '/order/pdf/' + this.chargesdata.charge_slip.id,
                '_blank'
              );
            });

            // this.$nextTick(() => {
            //   window.open(
            //     '/order/pdfs/' + this.chargesdata.charge_slip.id,
            //     '_blank'
            //   );
            // });

            this.clear();
            // if (this.module == 'orders') {
            //   var payload = { show: true, data: res.data };
            //   EventBus.$emit('CLOSE_DIALOG', payload);
            // }
          })
          .catch((error) => {
            notif = { option: 'error', msg: undefined, error: error };

            if (error.response.status === 422) {
              var err = this.$store.getters['settings/getErrors'];
              var errors = undefined;
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(
                (key) => (errors = err.data.errors[key][0])
              );
              notif = { option: 'error', msg: errors };

              EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
            } else {
              notif = {
                option: 'error',
                msg:
                  'Server can not save Orders. (' + error.response.status + ')',
              };
              EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
            }
          })
          .finally(() => {
            this.loading = false;
            EventBus.$emit('SHOW_LOADER', false);
            this.chargeslip = false;
            this.showChargeslip = false;
          });
      } else {
        console.log(!this.patient);
        notif = {
          option: 'error',
          msg: undefined,
          show: true,
        };
        if (!Object.keys(this.patient).length) {
          notif.msg = 'Patient is Required';
        } else {
          notif.msg = 'Please select Analyte/s';
        }
        EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        // EventBus.$emit('SHOW_LOADER', false);
      }
    },
    async savePrintBarcode(data) {
      console.log('barcode_payload', data.barcodes);
      let payload = {
        barcodes: data.barcodes,
      };
      this.$store
        .dispatch('barcodes/saveBarcode', payload)
        .then((res) => {
          console.log('BARCODE SAVED!', res);
          // alert(1);
        })
        .catch((error) => {
          //   console.log('error', error);
          //   notif = { option: 'error', msg: undefined, error: error };
          //   if (error.response.status === 422) {
          //     var err = this.$store.getters['settings/getErrors'];
          //     var errors = undefined;
          //     // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
          //     Object.keys(err.data.errors).forEach(
          //       (key) => (errors = err.data.errors[key][0])
          //     );
          //     notif = { option: 'error', msg: errors };
          //     EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          //   } else {
          let notif = {
            option: 'error',
            msg: 'Server can not print Barcodes.',
            error: error,
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          // }
        });
      // .finally(() => {});
    },
    async searchOrder() {
      let isValid = true;
      let notif = { option: 'success', msg: 'SUCCESS', show: true };
      this.clear(true);
      this.cancelUpdate();
      // console.log(this.sampleID);
      if (!this.sampleID) {
        // console.log(this.items.length)
        // console.log(Object.keys(this.patient).length)
        isValid = false;
      }

      var action = 'new';
      if (isValid) {
        EventBus.$emit('SHOW_LOADER', true);
        this.loading = true;

        // let data = {
        //   patient: this.patient,
        //   items: this.test_selection,
        // };
        // console.log(data);

        let payload = {
          action: action,
          sample_id: this.sampleID,
        };

        // console.log('payload', payload);

        this.$store
          .dispatch('samples/getSampleData', payload)
          .then((res) => {
            // console.log('res_searchOrder', res.data);
            const data = res.data;
            this.order_details = data;
            notif.msg = data.message;
            if (data.found) {
              this.newOrder = false;
              this.test_selection = [];
              this.defaultBranch = data.chargeslip_details.branch_id;
              data.orders.forEach((element) => {
                // console.log(element);
                this.selectTest(
                  element.id,
                  element.section_id,
                  true,
                  element.profile_id,
                  true
                );
              });
              this.model = true;
              this.showChargeslip = true;
              this.patient = data.patient;
              // this.getLastVisit(this.patient);
              // EventBus.$emit('LOAD_PATIENT', this.patient);
              // console.log(this.patient);
              this.last_visit.loading = true;
              setTimeout(() => {
                this.getLastVisit(this.patient);
              }, 500);
            } else {
              notif.option = 'error';
            }

            // notif = { option: 'success', msg: res.data.message };
            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          })
          .catch((error) => {
            // notif = { option: 'error', msg: error.data.message };
            // EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          })
          .finally(() => {
            this.loading = false;
            EventBus.$emit('SHOW_LOADER', false);
          });
      } else {
        notif = {
          option: 'error',
          msg: 'Please provide Barcode / Chargeslip',
          show: true,
        };
        EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
      }
    },
    selectTestsByProfile(id, section_id, selected) {
      // console.log(selected);
      var valid = true;
      if (this.patient.patient_id == null && this.newOrder) {
        let notif = {
          option: 'error',
          msg: 'Please provide a Patient.',
          show: true,
        };
        EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        valid = false;
      }

      var card = [];
      var tests_for_update = [];

      switch (section_id) {
        case 1:
          card = this.chemistry_card;
          break;
        case 2:
          card = this.immunoserology_card;
          break;
        case 3:
          card = this.hematology_card;
          break;
        case 5:
          card = this.microscopy_card;
          break;
        case 7:
          card = this.bacteriology_card;
          break;
      }

      card.analytes.forEach((element) => {
        if (element.profile_id == id) {
          element.selected = selected;
          if (element.selected) {
            this.test_selection.push(element);
          } else {
            let index = 0;
            this.test_selection.forEach((val) => {
              // console.log(val, element.id);
              if (val.id == element.id) {
                // console.log(true);
                this.test_selection.splice(index, 1);
              }
              index++;
            });
          }
          /**
           * ADD TESTS TO ARRAY FOR UPDATE
           */
          tests_for_update.push(element);
        }
      });

      /**
       * Update in DB
       */
      if (!this.newOrder && valid) {
        this.updateOrder(tests_for_update);
      }

      if (!valid) {
        clearTimeout(this._timerId);

        this._timerId = setTimeout(() => {
          this.clear();
        }, 500);
      }
      // console.log('this.test_selection', this.test_selection);
      //   console.log('this.tests_for_update', this.tests_for_update);
    },
    selectTest(id, section_id, selected, profile_id, orderInit) {
      // console.log(id, selected);
      var valid = true;

      if (this.patient.patient_id == null && this.newOrder) {
        let notif = {
          option: 'error',
          msg: 'Please provide a Patient.',
          show: true,
        };
        EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        valid = false;
      }

      var found = false;
      var index = 0;
      var card = [];
      var filtered_test = null;

      switch (section_id) {
        case 1:
          card = this.chemistry_card;
          break;
        case 2:
          card = this.immunoserology_card;
          break;
        case 3:
          card = this.hematology_card;
          break;
        case 5:
          card = this.microscopy_card;
          break;
        case 7:
          card = this.bacteriology_card;
          break;
      }

      if (selected) {
        const [filtered] = card.analytes.filter((el) => el.id == id);

        /**
         * Select checkbox if Searched an Order
         */
        if (!this.newOrder && orderInit) {
          card.analytes.forEach((element) => {
            if (element.id == id) {
              element.selected = selected;
            }
          });

          card.profiles.forEach((element) => {
            if (element.id == profile_id) {
              element.selected = selected;
            }
          });
        }

        if (valid) {
          this.test_selection.push(filtered);
        }
      } else {
        this.test_selection.forEach((element) => {
          // console.log(element.id, id);
          if (element.id === id) {
            this.test_selection.splice(index, 1);
          }
          index++;
        });
      }

      if (!this.newOrder && !orderInit && valid) {
        /**
         * Update in DB
         */
        const [filtered_test] = card.analytes.filter((el) => el.id == id);
        this.updateOrder([filtered_test]);
      }

      if (!valid) {
        clearTimeout(this._timerId);

        this._timerId = setTimeout(() => {
          this.clear();
        }, 500);
      }
      //   console.log('this.test_selection', this.test_selection);
    },
    setValue(value) {
      if (!this.showPatientDetails) {
        this.showPatientDetails = true;
        this.current_patient = this.patient;
        this.btnUpdatePatient.disabled = false;
      }
      this.selected = true;
      this.patient = value;
      // console.log('setValue', this.patient);
      EventBus.$emit('LOAD_PATIENT', this.patient);
      // console.log('this.patient', this.patient);
      // this.getLastVisit(this.patient);
      if (this.patient != undefined) {
        this.getLastVisit(this.patient);
      }
    },
    setUpdateValue(value) {
      if (this.current_patient.patient_id == null)
        this.current_patient = this.patient;

      // this.selected = true;
      this.patient = value;

      this.getLastVisit(this.patient);
      // this.changePatient = false;
      this.showPatientDetails = true;
      this.disableBtnUpdatePatient = false;

      this.btnUpdatePatient.disabled = false;
    },
    setPatientType(value) {
      // const [branch] = this.branches.filter((el) => el.id == value);
      this.defaultPatientType = value;
      if (!this.newOrder) {
        // this.updateSampleData('branch', value);
      }
    },
    setBranch(value) {
      // const [branch] = this.branches.filter((el) => el.id == value);

      this.defaultBranch = value;
      let payload = {
        site_id: value,
      };
      updateDefaultBranch(payload).then((res) => {
        console.log('res.data_defaultBranch', res.data.default_branch);
        this.default_branch = res.data.default_branch;
        this.defaultBranch = this.default_branch.id;
      });
      if (!this.newOrder) {
        this.updateSampleData('branch', value);
      }
    },
    async cancelUpdate() {
      //   console.log('this.current_patient', this.current_patient);
      if (this.current_patient.patient_id != null)
        this.patient = this.current_patient;

      this.current_patient = {};
      this.showPatientDetails = true;
      this.changePatient = false;
    },
    async getLastVisit(patient) {
      this.last_visit.loading = true;
      let notif = { option: 'success', msg: 'SUCCESS', show: true };
      let payload = patient;

      console.log('payload', payload);
      this.$store
        .dispatch('samples/getLastVisit', payload)
        .then((res) => {
          this.last_visit.loading = false;
          this.last_visit.data = res.data;

          console.log(res);
          // alert(this.last_visit.data);
        })
        .catch((error) => {
          notif = {
            option: 'error',
            msg:
              error.response.data.message + ' (' + error.response.status + ')',
            error: error,
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally(() => {
          this.isLoading = false;
          this.last_visit.loading = false;
        });
    },
    async updateSampleData(key, id) {
      // this.last_visit.loading = true;
      let notif = { option: 'success', msg: 'SUCCESS', show: true };
      let data = {
        key: key,
        id: id,
        chargeslip: this.order_details.chargeslip_details.chargeslip,
      };
      let payload = {
        item: data,
        id: this.order_details.chargeslip_details.sampleID,
      };
      switch (key) {
        case 'branch':
          this.loadingBranch = true;
        case 'patient':
          this.btnUpdatePatient.loading = true;
          break;
      }
      // this.loadingBranch = false;
      // return;
      console.log('payload', payload);
      this.$store
        .dispatch('samples/updateSampleData', payload)
        .then((res) => {
          switch (key) {
            case 'branch':
              this.order_details.chargeslip_details =
                res.data.chargeslipDetails;
              break;
            case 'patient':
              // setTimeout(() => {
              //   this.getLastVisit(this.patient);
              // }, 500);
              // this.current_patient = this.patient;
              this.current_patient = {};
              break;
          }
          notif.msg = res.data.message;
          EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);

          //   console.log(notif.msg);
        })
        .catch((error) => {
          notif = {
            option: 'error',
            msg: error.response.data.message,
            error: error,
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally(() => {
          this.loadingBranch = false;
          this.btnUpdatePatient.loading = false;
        });
    },
    async printChargeslip() {
      EventBus.$emit('SHOW_LOADER', true);
      let notif = { option: 'success', msg: 'SUCCESS', show: true };
      let payload = this.order_details;

      //   console.log('payload', payload);
      this.$store
        .dispatch('samples/printChargeslip', payload)
        .then((res) => {
          this.chargesdata = res.data;
          //   this.savePrintBarcode(res.data);
          //   this.chargeslip = true;
          // console.log('this.chargesdata', this.chargesdata);

          //   EventBus.$emit('LOAD_CHARGESLIP');

          this.isLoading = false;
          this.loading = false;
          EventBus.$emit('SHOW_LOADER', false);
          //   this.chargeslip = false;
          //   this.showChargeslip = false;

          //   let chargeslip = this.chargesdata.charges_slip.id;
          //   window.open('/order/pdf/' + chargeslip, '_blank');

          this.$nextTick(() => {
            window.open(
              '/order/pdf/' + this.chargesdata.charge_slip.id,
              '_blank'
            );

            // window.open(
            //   '/receive/pdf/' + this.chargesdata.charge_slip.id,
            //   '_blank'
            // );
          });

          //   this.$nextTick(() => {});
          //   window.open(
          //     '/order/pdf/' + this.chargesdata.charge_slip.id,
          //     '_blank'
          //   );
          //   this.clear();
        })
        .catch((err) => {
          //   console.log('err', err);
          //   console.log('error', err.response.status);
          notif = {
            option: 'error',
            msg: 'Internal Server Error',
            error: err,
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally(() => {
          this.isLoading = false;
          this.loading = false;
          EventBus.$emit('SHOW_LOADER', false);
          this.chargeslip = false;
          this.showChargeslip = false;
        });
    },
    async updateOrder(analytes) {
      let notif = { option: 'success', msg: 'SUCCESS', show: true };
      let payload = {
        chargeslip: this.order_details.chargeslip_details.chargeslip,
        items: analytes,
        patient: this.patient,
      };
      console.log('payload', payload);
      this.$store
        .dispatch('samples/updateOrder', payload)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log('analytes', analytes);
          /**
           * Toggle selection when error occurred
           */
          analytes.forEach((element) => {
            element.selected = !element.selected;
          });
          notif = {
            option: 'error',
            msg:
              error.response.data.message + ' (' + error.response.status + ')',
            error: error,
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally(() => (this.isLoading = false));
    },
    async showUpdatePatientSelection() {
      this.showPatientDetails = false;
      this.changePatient = !this.changePatient;
      // this.$nextTick(() => this.$refs.txtshowUpdatePatientSelection.$el.focus());
    },
    async updatePatientInOrder() {
      this.changePatient = false;
      this.disableBtnUpdatePatient = true;
      this.btnUpdatePatient.disabled = true;

      this.updateSampleData('patient', this.patient.id);
    },
    async showNewPatientForUpdate() {
      this.current_patient = {};
      this.patients = true;
    },
    async processItem() {
      const isValid = await this.$refs.observer.validate();
      var notif = { option: 'success', msg: undefined, show: true };

      if (isValid) {
        this.loading = true;

        var payload = {
          action: this.options.action,
          item: this.item_,
          module: null,
        };

        this.$store
          .dispatch('patients/processItem', payload)
          .then((res) => {
            if (this.options.action == 'delete')
              notif = {
                option: 'success',
                msg: 'Delete Successful!',
                show: true,
              };

            EventBus.$emit('SHOW_NOTIFICATION', notif);
          })
          .catch((error) => {
            notif = { option: 'fail', msg: undefined };
            this.loading = false;
            if (error.response.status === 422) {
              var err = this.$store.getters['settings/getErrors'];
              var errors = undefined;
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(
                (key) => (errors = err.data.errors[key][0])
              );
              notif = { option: 'fail', msg: errors };
              EventBus.$emit('SHOW_NOTIFICATION', notif);
            } else {
              notif = {
                option: 'fail',
                msg:
                  'Server can not process the Item. (' +
                  error.response.status +
                  ')',
              };
              EventBus.$emit('SHOW_NOTIFICATION', notif);
            }
          });
      } else {
        const ValidDob = await this.validator
          .validateAll({
            dob: this.item_.dob,
          })
          .then((result) => {
            if (result) return true;
            else return false;
          });
        if (!ValidDob)
          notif = {
            option: 'fail',
            msg: 'Date of Birth is Required',
            show: true,
          };
        else
          notif = {
            option: 'fail',
            msg: 'Please fill in all required fields',
            show: true,
          };

        EventBus.$emit('SHOW_NOTIFICATION', notif);
        EventBus.$emit('LOAD_CHARGESLIP');
      }
    },
    async searchItem(val) {
      this.$store
        .dispatch('patients/searchItem', val)
        // .then(res => res.json())
        .then((res) => {
          // console.log(res)
          const { count, entries } = res;
          this.count = count;
          this.entries = entries;
        })
        .catch((error) => {
          var notif = {
            option: 'fail',
            msg:
              'Internal Server Error. Can not complete search (' +
              error.response.status +
              ')',
          };
          EventBus.$emit('SHOW_NOTIFICATION', notif);
        })
        .finally(() => (this.isLoading = false));
    },
    async fetchAnalytes() {
      this.$store
        .dispatch('cache/fetchAnalytes')
        // .then(res => res.json())
        .then((res) => {
          console.log('res_getAnalytes', res);

          this.chemistry_card.profiles = [];
          this.immunoserology_card.profiles = [];
          this.hematology_card.profiles = [];
          this.microscopy_card.profiles = [];

          res.profiles.forEach((element) => {
            switch (element.section_id) {
              case 1:
                this.chemistry_card.profiles.push(element);
                this.chemistry_card.showProfile = true;
                break;
              case 2:
                this.immunoserology_card.profiles.push(element);
                this.immunoserology_card.showProfile = true;
                break;
              case 3:
                this.hematology_card.profiles.push(element);
                this.hematology_card.showProfile = true;
                break;
              case 5:
              case 7:
                this.microscopy_card.profiles.push(element);
                this.microscopy_card.showProfile = true;
                break;
            }
          });

          res.analyte_card.forEach((element) => {
            switch (element.section_id) {
              case 1:
                this.chemistry_card.section = element.section;
                this.chemistry_card.analytes = element.analytes;
                break;
              case 2:
                this.immunoserology_card.section = element.section;
                this.immunoserology_card.analytes = element.analytes;
                break;
              case 3:
                this.hematology_card.section = element.section;
                this.hematology_card.analytes = element.analytes;
                break;
              case 5:
                this.microscopy_card.section = element.section;
                this.microscopy_card.analytes = element.analytes;
                break;
              case 7:
                this.bacteriology_card.section = element.section;
                this.bacteriology_card.analytes = element.analytes;
                break;
            }
          });

          // const { count, entries } = res;
          // this.count = count;
          // this.entries = entries;
        })
        .catch((error) => {
          // var notif = {
          //     option: 'error',
          //     msg:
          //         'Internal Server Error. Can not complete search (' +
          //         error.response.status +
          //         ')'
          // };

          var notif = {
            option: 'error',
            msg: 'Internal Server Error.',
          };
          EventBus.$emit('SHOW_NOTIFICATION', notif);
        })
        .finally(() => (this.isLoading = false));
    },
    async clearUpdate() {
      this.update_patient = null;
    },
    async clear(softClear) {
      // console.log('this.patient.patient_id', this.patient.patient_id);
      if (this.patient.patient_id != null) {
        this.selected = false;
        this.patient = {};

        this.model = null;
        EventBus.$emit('LOAD_PATIENT', this.patient);
      }
      this.current_patient = {};
      this.disableBtnUpdatePatient = true;
      this.btnUpdatePatient.disabled = true;

      this.showChargeslip = false;
      this.order_details = {};
      this.clearTestOrders();

      this.newOrder = true;

      // if (!this.newOrder)
      //   this.defaultBranch = this.app_settings.default_branch.id;

      // this.newOrder = true;
      this.defaultBranch = this.default_branch.id;
      this.defaultPatientType = 1;
      this.ORNumber = undefined;
      this.changePatient = false;
      this.showPatientDetails = true;
      //   this.sampleID = undefined;

      if (softClear) return;
      //UNCOMMENT DURING PRODUCTION
      this.sampleID = undefined;
    },

    async clearTestOrders() {
      this.test_selection = [];

      const analytes = JSON.parse(localStorage.getItem('analytes'));
      // const analytes = this.$options.default_analytes;
      // this.$options.default_analytes = analytes;

      this.chemistry_card.profiles = [];
      this.immunoserology_card.profiles = [];
      this.hematology_card.profiles = [];
      this.microscopy_card.profiles = [];

      analytes.profiles.forEach((element) => {
        switch (element.section_id) {
          case 1:
            this.chemistry_card.profiles.push(element);
            this.chemistry_card.showProfile = true;
            break;
          case 2:
            this.immunoserology_card.profiles.push(element);
            this.immunoserology_card.showProfile = true;
            break;
          case 3:
            this.hematology_card.profiles.push(element);
            this.hematology_card.showProfile = true;
            break;
          case 5:
          case 7:
            this.microscopy_card.profiles.push(element);
            this.microscopy_card.showProfile = true;
            break;
        }
      });
      analytes.analyte_card.forEach((element) => {
        switch (element.section_id) {
          case 1:
            this.chemistry_card.section = element.section;
            this.chemistry_card.analytes = element.analytes;
            break;
          case 2:
            this.immunoserology_card.section = element.section;
            this.immunoserology_card.analytes = element.analytes;
            break;
          case 3:
            this.hematology_card.section = element.section;
            this.hematology_card.analytes = element.analytes;
            break;
          case 5:
            this.microscopy_card.section = element.section;
            this.microscopy_card.analytes = element.analytes;
            break;
          case 7:
            this.bacteriology_card.section = element.section;
            this.bacteriology_card.analytes = element.analytes;
            break;
        }
      });
    },
    async loadPatient(payload) {
      this.current_patient = this.patient;
      this.model = payload.show;
      this.patient = payload.data[0];

      if (!this.newOrder) {
        this.showPatientDetails = true;
        this.disableBtnUpdatePatient = false;
        this.btnUpdatePatient.disabled = false;
      }
      // EventBus.$emit('LOAD_PATIENT', payload.data[0]);
    },
    itemHeader(item) {
      //itemheader
      let patientHeader = 'PATIENT ID: ' + item.patient_id;
      let spacer = 100 - patientHeader.length;
      let dobHeader = 'BIRTHDATE';
      let space = '                           ';
      let patientHeader2 = patientHeader + space + dobHeader + '     ' + 'test';
      return patientHeader2;
    },
    // getItemTitle(item) {
    //   let fullName = item.patient_description;
    // },
    async init() {
      clearTimeout(this._timerId);

      this.$nextTick(function () {
        // const app_settings = this.$store.getters['cache/getAppSettings'];
        this.app_settings = JSON.parse(localStorage.getItem('app_settings'));
        this.default_branch = JSON.parse(
          localStorage.getItem('default_branch')
        );
        console.log('app_settings', this.app_settings);
        console.log('this.defaultBranch', this.default_branch.id);
        if (this.app_settings) {
          this.branches = [];
          this.branches = this.app_settings.branches;
          //   this.barangays = this.app_settings.barangays;
          //   this.defaultBranch = this.app_settings.default_branch.id;
        }
        if (this.default_branch) {
          this.defaultBranch = this.default_branch.id;
          clearTimeout(this._timerId);
        }
      });

      // this._timerId = setInterval(() => {
      //   this.app_settings = JSON.parse(localStorage.getItem('app_settings'));
      //   console.log('app_settings', this.app_settings);
      //   if (this.app_settings) {
      //     this.branches = this.app_settings.branches;
      //     clearTimeout(this._timerId);
      //   }
      // }, 500);
    },
  },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      // console.log(this.entries)

      return this.entries.map((entry) => {
        // const Description = entry.Description.length > this.descriptionLimit
        //   ? entry.Description.slice(0, this.descriptionLimit) + '...'
        //   : entry.Description

        const description = entry.description;
        return Object.assign({}, entry, { description });

        // return Object.assign({}, entry, { entry.description })
      });
    },
  },

  watch: {
    search(val) {
      // // Items have already been loaded
      // if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading || !val || this.selected) return;
      if (val.length < 1) return;

      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        // console.log(val);
        this.isLoading = true;
        this.searchItem(val);
      }, 200);

      // // Lazily load input items
      // fetch('https://api.publicapis.org/entries')
      //   .then(res => res.json())
      //   .then(res => {
      //     const { count, entries } = res
      //     this.count = count
      //     this.entries = entries
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
      //   //end fetch
    }, //end search val
  },

  created() {
    // this.validator = new Validator({
    //     dob: "required"
    // });
  },
  mounted() {
    // EventBus.$emit('SHOW_LOADER', true);
    const analytes = JSON.parse(localStorage.getItem('analytes'));
    this.$options.default_analytes = analytes;
    this.clearTestOrders();
    this.init();
    EventBus.$on('CLOSE_PATIENT_DIALOG', (payload) => {
      if (Object.keys(payload).length != 0) {
        this.loadPatient(payload);
      }
      this.patients = null;
    });

    // console.log('CLOSE_PATIENT_DIALOG - MOUNTED');
    // EventBus.$emit('SHOW_LOADER', false);
  },
  beforeDestroy() {
    EventBus.$off('CLOSE_PATIENT_DIALOG');
    console.log('CLOSE_PATIENT_DIALOG - DESTROYED');
  },
};
</script>

<style scoped>
/* .v-list-item__subtitle { */
/* color: red !important; */
/* } */

::v-deep .v-chip__content {
  font-size: 0.8rem !important;
}

.v-autocomplete__content .v-list__tile {
  height: 800px !important;
}

.patient {
  font-weight: bold;
}

.v-input--selection-controls__ripple {
  margin-left: -19px !important;
}

/* ::v-deep .chk-color label {
  color: #263238;
}

::v-deep .chk-color-dark label {
  color: #fafafa;
} */

.hidden {
  display: none !important;
}

.v-text-field__slot {
  height: 20px !important;
}

/* .v-input__control .v-text-field__slot .v-input__slot {
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
} */

/* .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
} */

/* .v-label {
    color: #263238 !important;
} */

/* .chkcolor {
    color: #263238 !important;
} */

/* .v-messages__wrapper
    color: #263238 !important */

/* .chkcolor {
color: #263238 !important
}


.title_center {
text-align: center !important
}


.align_center {
    text-align: center !important
} */
</style>

<style lang="sass">
.v-input--selection-controls__ripple
  margin-left: 7px !important
</style>
