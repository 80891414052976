<template>
  <v-container fill-height fluid grid-list-xl>
    <!-- <v-layout row wrap justify-center>
      <v-container py-0>
    <v-layout wrap>-->
    <v-row>
      <!-- SOURCE -->
      <v-card>
        <v-col xs="12" md="12">
          <div id="printResult">
            <div class="fullReport">
              <div class="page-header">
                <div class="header" align="center">
                  <div class="logo_container">
                    <img v-bind:src="'/images/' + logo" class="logo" />
                  </div>
                  <table class="tableheader">
                    <tr>
                      <td class="bold" colspan="2">
                        {{ data.user_settings.site_info.site }}
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        {{ data.user_settings.site_info.address }}
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        {{ data.user_settings.site_info.contact }}
                        &nbsp;
                      </td>
                    </tr>
                  </table>

                  <table class="patient-table" cellpadding="3">
                    <thead>
                      <tr class="patientrow">
                        <td style="width: 100mm">
                          Patient Name:
                          <span class="bold">
                            {{ PatientName }}
                          </span>
                        </td>
                        <td style="width: 35mm">CHARGE SLIP #:</td>
                        <td style="width: 65mm">{{ ChargeSlip }}</td>
                      </tr>
                      <tr class="patientrow">
                        <td style="width: 135mm">
                          Birthdate:
                          {{ Birthdate }}
                        </td>
                        <td style="width: 35mm">DATE ORDERED:</td>
                        <td style="width: 65mm">{{ DateOrdered }}</td>
                      </tr>
                      <tr class="patientrow">
                        <td style="width: 100mm">
                          Age / Sex:
                          {{ Age }} /
                          {{ Gender }}
                        </td>
                        <!-- <td style="width: 65mm; background: red"> {{ PatientName }} </td> -->
                        <td style="width: 35mm">DATE PRINTED:</td>
                        <td style="width: 65mm">{{ DatePrinted }}</td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>

              <div class="result-body" align="center">
                <table>
                  <thead>
                    <tr>
                      <td>
                        <!--place holder for the fixed-position header-->
                        <div class="page-header-space"></div>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <!--*** CONTENT GOES HERE ***-->
                        <div class="page" align="center">
                          <div class="section" align="center">
                            CLINICAL CHEMISTRY
                          </div>
                          <table class="items-table" id="items">
                            <thead>
                              <tr
                                class="items-table-header"
                                style="border-bottom: 1px solid black"
                              >
                                <td style="width: 100mm" class="left">
                                  TEST NAME
                                </td>
                                <td style="width: 30mm" class="center">
                                  RESULT
                                </td>
                                <td style="width: 20mm" class="center">FLAG</td>
                                <td style="width: 20mm" class="center">UNIT</td>
                                <td style="width: 50mm" class="center">
                                  REFERENCE RANGE
                                </td>
                              </tr>
                            </thead>
                            <tbody
                              v-for="group in data.analyte_groups"
                              class="items-body"
                            >
                              <!-- <tr v-if="group.code != 'NONE'"> -->
                              <!-- <tr
                                :class="group.code == 'NONE' ? 'groupheight' : ''"
                                v-if="group.code != 'NONE'"
                              >-->
                              <tr
                                :class="
                                  group.code == 'NONE' ? 'groupheight' : ''
                                "
                                v-if="group.code != 'NONE'"
                              >
                                <!-- <tr
                                                                :class="
                                                                    group.code ==
                                                                    'NONE'
                                                                        ? 'skip-row'
                                                                        : ''
                                                                "
                                >-->
                                <!-- <tr> -->
                                <td colspan="5" class="left bold">
                                  {{ group.analyte_group }}
                                </td>
                              </tr>

                              <!-- <tr
                                v-for="item in data.validated_results"
                                v-if="item.result.result_group_id == group.id"
                                class="col-result"
                              >-->
                              <tr
                                v-for="item in data.validated_results"
                                v-if="
                                  group.analyte_group_id ==
                                  item.result.analyte_group_id
                                "
                              >
                                <!-- <td
                                  style="width: 100mm"
                                  :class="group.code != 'NONE' ? 'indent' : ''"
                                >-->
                                <td
                                  style="width: 100mm"
                                  :class="
                                    group.code != 'NONE' ? 'colresult' : ''
                                  "
                                >
                                  {{ item.result.analyte }}
                                </td>
                                <!-- <td style="width: 100mm" class="center bold"> {{ item.result.analyte }} </td> -->
                                <td style="width: 30mm" class="center bold">
                                  {{ item.result.result }}
                                </td>
                                <td style="width: 30mm" class="center bold">
                                  {{ item.result.interpretation }}
                                </td>
                                <td style="width: 20mm" class="center">
                                  {{ item.result.unit1 }}
                                </td>
                                <td style="width: 50mm" class="center">
                                  {{ item.result.reference_range }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <!-- <table class="total">
                <tr class="right">
                  <td style="width: 100mm"> </td>
                  <td style="width: 35mm"> </td>
                  <td style="width: 35mm" class="right"> TOTAL AMOUNT: </td>
                  <td style="width: 30mm" class="right"> {{ Total }} </td>
                </tr>
                          </table>-->
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>
                        <!--place holder for the fixed-position footer-->
                        <div class="page-footer-space"></div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="page-footer" align="center">
                <table class="table-footer">
                  <tr>
                    <td class="signatory">
                      {{ data.user_settings.user.fullname_with_suffix }}
                    </td>
                    <td class="spacer"></td>
                    <td class="signatory">
                      {{ data.user_settings.site_info.pathologist }}
                    </td>
                  </tr>

                  <tr>
                    <td class="position">Medical Technologist</td>
                    <td class="spacer"></td>
                    <td class="position">Pathologist</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- OUTPUT -->
          <div align="right">
            <v-card>
              <v-card-actions>
                <v-btn color="indigo" @click="print()">
                  PRINT
                  <v-icon right>mdi-printer</v-icon>
                </v-btn>

                <v-btn color="cyan lighten-2" @click="close()">
                  Close
                  <v-icon right>mdi-close-circle</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-card>
    </v-row>
    <!-- </v-layout>
      </v-container>
    </v-layout>-->
  </v-container>
</template>

<script>
import EventBus from '@/plugins/eventbus';
// import VueHtmlToPaperFull from '@/plugins/vuehtmltopaperfull';
// import Vue from 'vue';

// const options2 = {
//   name: '_blank',
//   specs: ['fullscreen=no', 'titlebar=yes', 'scrollbars=yes'],
//   styles: ['/styles/result.css'],
//   title: 'RESULT',
// };

// Vue.use(VueHtmlToPaperFull, options2);

export default {
  props: {
    resultdata: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      logo: 'Makati_Logo.png',
      output: null,
      data: {
        order_details: {
          ordered_at: null,
        },
        charge_slip: {
          total_amount: 0,
          id: null,
        },
        user_settings: {
          site_info: {
            site: null,
          },
          user: {
            fullname_with_suffix: null,
          },
        },
      },
      patient: {
        full_name: null,
        birthdata: null,
        age_simple: null,
        gender: null,
      },
      updated_data: {},
      interval: null,
      loading: null,
    };
  },
  methods: {
    getItemName(item) {
      // console.log(Object.keys(this.data).length)
      // if(!Object.keys(this.data).length)
      if (item.analyte_order_name) return item.analyte_order_name;
      else return item.analyte_group;
    },
    print() {
      // Pass the element id here
      let patient_name = this.data.patient.last_name;
      patient_name += '_' + this.data.patient.first_name;
      patient_name += '_' + this.data.patient.middle_name;

      let title = patient_name + '_' + this.data.order_details.barcode;
      this.$htmlToPaperFull('printResult', title, ['/styles/result.css']);
      // this.data = {}
    },
    close() {
      EventBus.$emit('CLOSE_RESULT_VIEW');
    },
    getData() {
      this.interval = setInterval(() => {
        // console.log('RESULT_DATA', this.data);
        // console.log(this.interval);

        if (this.data.patient.full_name != undefined) {
          this.print();
          clearInterval(this.interval);
          EventBus.$emit('CLOSE_RESULT_VIEW');
        }
      }, 200);
    },
  },
  computed: {
    Site() {
      return 'Site';
      //   return this.data.user_settings.site_info.site;
    },
    PatientName() {
      return this.patient.full_name;
    },
    Birthdate() {
      return this.patient.birthdate;
    },
    Age() {
      return this.patient.age_simple;
    },
    Gender() {
      return this.patient.gender;
    },
    DateOrdered() {
      return this.data.order_details.ordered_at;
    },
    DatePrinted() {
      return this.data.printed_at;
    },
    ChargeSlip() {
      return this.data.order_details.chargeslip;
    },
    Total() {
      return this.data.charge_slip.total_amount.toLocaleString();
    },
  },
  watch: {
    resultdata(val) {
      this.data = val;
      this.patient = this.data.patient;
      // console.log('result_data_component', this.data)
    },
    updated_data(val) {
      console.log('UPDATED');
      this.data = val;
      this.patient = val.data.patient;
    },
  },
  mounted() {
    EventBus.$on('UPDATE_RESULT_VIEW', (payload) => {
      console.log('result_data_component', this.payload);
      this.updated_data = payload;
      this.data = payload;
      this.patient = this.data.patient;

      // this.updated_data = this.$store.getters['settings/getUserSettings']
    });
    this.getData();
  },
  created() {
    // // this.patient = this.data.patient
    // let data = this.$store.getters['cache/getResultsData']
    // // console.log(data)
    // // this.data = data
    // if (!data) {
    //   this.data = data
    // }

    const user_settings = this.$store.getters['settings/getUserSettings'];
    console.log('user_settings', user_settings);
    this.data.user_settings = user_settings;
    this.data = this.$store.getters['cache/getResultsData'];
    this.patient = this.data.patient;
    // console.log('created');
    // console.log('data_result', this.data);
  },
  beforeDestroy() {
    EventBus.$off('UPDATE_RESULT_VIEW');
  },
  destroyed() {
    // console.log('data', this.data)
    // this.data = {}
  },
};
</script>

<style scoped>
::v-deep .colresult {
  padding-left: 30px !important;
}

::v-deep .groupheight {
  height: 0px !important;
}
/*
@import '../../../../public/styles/result.css';
*/
</style>
