<template>
    <v-card
        class="v-card--wizard"
        elevation="12"
        :max-width="maxWidth"
        :maxHeight="maxHeight"
    >
        <v-card-title class="justify-center display-2 font-weight-bold pt-5"
            >OSPITAL NG MAYNILA QUEUEING SYSTEM</v-card-title
        >

        <!-- <div class="text-center display-1 grey--text font-weight-light mb-6">-</div> -->

        <v-tabs
            ref="tabs"
            v-model="internalValue"
            background-color="green lighten-5"
            color="white"
            grow
            slider-size="50"
        >
            <v-tabs-slider class="mt-1" color="success" />

            <v-tab
                v-for="(item, i) in items"
                :key="i"
                :ripple="false"
                :disabled="!availableSteps.includes(i)"
                >{{ item }}</v-tab
            >
        </v-tabs>

        <div class="my-6" />

        <v-card-text>
            <v-tabs-items v-model="internalValue">
                <slot />
            </v-tabs-items>
        </v-card-text>

        <v-card-actions class="pb-4 pa-4">
            <v-btn
                :disabled="internalValue === 0 || internalValue === 2"
                class="white--text"
                color="grey darken-2"
                min-width="125"
                @click="$emit('click:prev')"
                >Previous</v-btn
            >

            <v-spacer />
            <span class="z1footer">
                &copy; 2020, made with
                <v-icon size="20">mdi-heart</v-icon> by Z1 Solutions for a
                better Queueing.
            </span>
            <v-spacer />
            <v-btn
                :disabled="!availableSteps.includes(internalValue + 1)"
                color="success"
                min-width="100"
                @click="$emit('click:next')"
                >{{
                    internalValue === items.length - 1 ? 'Finish' : 'Next'
                }}</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable';

export default {
    name: 'BaseMaterialWizard',

    mixins: [Proxyable],

    props: {
        availableSteps: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        window: {
            width: 0,
            height: 0
        },
        btnSize: {
            width: 0,
            height: 0
        },
        maxWidth: 0,
        maxHeight: 0
    }),

    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;

            this.btnSize.width = this.window.width - 200;
            this.btnSize.height = this.window.height / 4;
            console.log(this.window.width, this.window.height);
            this.maxWidth = this.window.width - 200;
            this.maxHeight = this.window.height - 100;
        }
    },

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }
};
</script>

<style lang="sass">
.v-card--wizard
  overflow: visible

  .v-tabs-bar
    height: 56px
    padding: 0 8px

    .v-tabs-slider-wrapper
      overflow: visible

    .v-tabs-slider
      border-radius: 4px

    .v-tabs-slider-wrapper
      contain: initial
      z-index: 0
.z1footer
  font-size: 0.8rem
</style>
