export default {
	currentUser: state => {
		return state.currentUser;
	},
	isLoggedIn: state => {
		return state.isLoggedIn;
	},
	loading: state => {
		return state.loading;
	},
	auth_error: state => {
		return state.auth_error;
	},
}