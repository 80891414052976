// import { getLocalUser } from "./auth";
// // import { mapState, mapGetters, mapMutations } from "vuex";

// const user = getLocalUser();

// const state = {
// 	currentUser: user,
// 	isLoggedIn: !!user,
// 	loading: false,
// 	auth_error: null,
// };

// const mutations = {
// 	// LOG_IN: (state, payload) => {

// 	// },
// 	'login'(state) {
// 		state.loading = true;
// 		state.auth_error = null;
// 		console.log("LOGGED IN");
// 	},
// 	'loginSuccess'(state, payload) {
// 		state.auth_error = null;
// 		state.isLoggedIn = true;
// 		state.loading = false;
// 		state.currentUser = Object.assign({}, payload.user, { token: payload.access_token });
// 		// console.log(payload.user);
// 		// console.log(payload.access_token);
// 		// console.log(state.currentUser);
// 		localStorage.setItem("user", JSON.stringify(state.currentUser));
// 		//localStorage.setItem("user", Object.assign({}, payload.user, {token: payload.access_token}));
// 	},
// 	'loginFailed'(state, payload) {
// 		state.loading = false;
// 		state.auth_error = payload.error;
// 	},
// 	'logout'(state) {
// 		// localStorage.removeItem("vuex");

// 		localStorage.removeItem("user");
// 		state.isLoggedIn = false;
// 		state.currentUser = null;
// 	}
// };

// const actions = {
// 	login(context) {
// 		context.commit("login");
// 	}
// };

// const getters = {
// 	currentUser: state => {
// 		return state.currentUser;
// 	},
// 	isLoggedIn: state => {
// 		return state.isLoggedIn;
// 	},
// 	loading: state => {
// 		return state.loading;
// 	},
// 	auth_error: state => {
// 		return state.auth_error;
// 	},
// };

// export default {
// 	state,
// 	mutations,
// 	actions,
// 	getters
// };

import { getLocalUser } from './auth';
// import { mapState, mapGetters, mapMutations } from "vuex";
// localStorage.getItem("user");
const user = getLocalUser();
export default {
    state: {
        currentUser: user,
        isLoggedIn: !!user,
        loading: false,
        auth_error: null
    },

    mutations: {
        // LOG_IN: (state, payload) => {

        // },
        login(state) {
            state.loading = true;
            state.auth_error = null;
        },
        loginSuccess(state, payload) {
            state.auth_error = null;
            state.isLoggedIn = true;
            state.loading = false;
            state.currentUser = Object.assign({}, payload.user, {
                token: payload.access_token
            });
            // console.log(payload.user);
            // console.log(payload.access_token);
            // console.log(state.currentUser);
            localStorage.setItem('user', JSON.stringify(state.currentUser));
            //localStorage.setItem("user", Object.assign({}, payload.user, {token: payload.access_token}));
        },
        loginFailed(state, payload) {
            state.loading = false;
            state.auth_error = payload.error;
        },
        logout(state) {
            // localStorage.removeItem("vuex");

            localStorage.removeItem('user');
            state.isLoggedIn = false;
            state.currentUser = null;
        }
    },

    actions: {
        login(context) {
            context.commit('login');
            // alert(3);
        }
    },

    getters: {
        currentUser: state => {
            return state.currentUser;
        },
        isLoggedIn: state => {
            return state.isLoggedIn;
        },
        loading: state => {
            return state.loading;
        },
        auth_error: state => {
            return state.auth_error;
        }
    }
};
