<template>
  <v-container fluid>
    <!-- <v-layout justify-center wrap> -->
    <v-row>
      <v-col cols="12" md="12">
        <base-lisa-material-card
          color="orange"
          title="Result Validation"
          icon="mdi-file-document-multiple"
          inline
          class="px-5 py-3"
        >
          <!-- <v-flex xs="12" md="12"> -->
          <!-- <v-container class="pa-1" grid-list-xl fluid>
          <v-layout wrap class="pa-2">
          <v-flex xs="12" md="12">-->
          <v-card elevation="20" class="mb-3">
            <v-card-text>
              <!--
                    * PATIENT DETAILS
              -->
              <v-row>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Patient Name
                  </div>
                  <div class="bold">{{ data.patient.full_name }}</div>
                </v-col>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Date of Birth
                  </div>
                  <div>{{ data.patient.birthdate }}</div>
                </v-col>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Age / Sex
                  </div>
                  <div>
                    {{ data.patient.age_simple }} /
                    {{ data.patient.gender }}
                  </div>
                </v-col>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Patient ID
                  </div>
                  <div>{{ data.patient.patient_id }}</div>
                </v-col>
              </v-row>

              <!--
                    * ORDER DETAILS
              -->
              <v-row>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">Barcode</div>
                  <div class="bold">{{ data.order_details.barcode }}</div>
                </v-col>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Specimen
                  </div>
                  <div>{{ data.order_details.specimen }}</div>
                </v-col>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Ordered at
                  </div>
                  <div>{{ data.order_details.ordered_at }}</div>
                </v-col>
                <v-col xs="12" md="3">
                  <div class="caption orange--text text-uppercase">
                    Chargeslip #
                  </div>
                  <div>{{ data.order_details.chargeslip }}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!--
              * ANALYTE RESULT DETAILS
          -->
          <v-card class="mt-2">
            <v-card-text>
              <v-data-table
                :headers="resultheaders"
                :items="data.analytes"
                class="resultstable"
                :sort-by="['data.analytes.analyte.id']"
                :footer-props="footerProps"
              >
                <!-- hide-default-footer -->
                <template slot="headerCell" slot-scope="{ header }">
                  <span
                    class="subheading text-uppercase amber--text text--darken-4"
                    v-text="header.text"
                  />
                </template>
                <template slot="item" slot-scope="{ item, index }">
                  <tr @contextmenu="show" @click.right="test(index)">
                    <td
                      class="text-left resultstable body-2 font-weight-medium"
                    >
                      {{ item.analyte.id }}
                    </td>
                    <td
                      class="text-right resultstable body-2 font-weight-medium"
                    >
                      {{ item.analyte.analyte }}
                    </td>
                    <td
                      class="
                        text-center
                        resultstable
                        results
                        body-2
                        font-weight-medium
                      "
                    >
                      <!-- <div v-on:click="editResult(index)" v-if="!onedit"> aa {{ item.result.result }} </div> -->
                      <!-- <div v-else align="center"> -->
                      <!-- <input
                            v-model.trim="item.result.result"
                            label="Result"
                            placeholder="Enter Result"
                            outlined
                            class="resulttext"
                            v-on:keyup="inputActivity(index)"
                            v-on:click="beforeEditCache(index)"
                            @blur="doneEdit(index)"
                            @keyup.enter="nextInput(index)"
                            :ref="`text${index}`"
                            :disabled="item.settings.input_disabled"
                            loading: true
                            color="info"
                      >-->

                      <v-combobox
                        v-model.trim="item.result.result"
                        label="Result"
                        placeholder="Enter Result"
                        outlined
                        class="mt-n2 resulttext body-2 font-weight-medium"
                        v-on:keyup="inputActivity(index)"
                        v-on:click="beforeEditCache(index)"
                        @blur="doneEdit(index)"
                        @keyup.enter="nextInput(index)"
                        :ref="`text${index}`"
                        :disabled="item.settings.input_disabled"
                        color="orange"
                        single-line
                        dense
                        v-if="item.result_options"
                        :items="item.result_options"
                      ></v-combobox>

                      <v-text-field
                        v-else
                        v-model.trim="item.result.result"
                        label="Result"
                        placeholder="Enter Result"
                        outlined
                        class="mt-n2 resulttext body-2 font-weight-medium"
                        v-on:keyup="inputActivity(index)"
                        v-on:click="beforeEditCache(index)"
                        @blur="doneEdit(index)"
                        @keyup.enter="nextInput(index)"
                        :ref="`text${index}`"
                        :disabled="item.settings.input_disabled"
                        color="orange"
                        single-line
                        dense
                      ></v-text-field>

                      <!-- :loading="loading" -->
                      <!-- </div> -->
                    </td>

                    <td
                      class="text-center resultstable body-2 font-weight-medium"
                    >
                      <span v-for="prev_result in item.previous_results">
                        <v-tooltip top content-class="top">
                          <template v-slot:activator="{ on }">
                            <!-- v-for="group in data.analyte_groups" -->
                            <v-chip
                              color="light-blue lighten-1"
                              class="text-uppercase"
                              v-on="on"
                              text-color="white"
                            >
                              <!-- style="text-color: white !important" -->

                              {{ prev_result.result | truncate(10, '...') }}
                            </v-chip>
                          </template>
                          <span>Result:</span>
                          <span class="bold">
                            {{ prev_result.result }}
                          </span>
                          <span>
                            <br />Validated by
                            <br />
                            {{ prev_result.full_name }}
                            <br />
                            {{ prev_result.validated_at }}
                            <br />
                            ({{ prev_result.validated_at_human }})
                          </span>
                        </v-tooltip>
                      </span>
                    </td>

                    <td class="text-center resultstable">
                      {{ item.ref.ref_range }}
                    </td>
                    <td class="text-center resultstable">
                      <v-tooltip top content-class="top">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            color="deep-orange"
                            class="flags text-uppercase"
                            v-show="item.flags.code"
                            v-on="on"
                          >
                            {{ item.flags.code }}
                          </v-chip>
                        </template>
                        <span>
                          {{ item.flags.flag }}
                        </span>
                      </v-tooltip>
                    </td>
                    <td
                      class="text-center resultstable body-2 font-weight-medium"
                    >
                      {{ item.result.result2 }}
                    </td>
                    <td
                      class="text-center resultstable body-2 font-weight-medium"
                    >
                      <span v-if="item.analyte.unit1 != 'NONE'">{{
                        item.analyte.unit1
                      }}</span>
                    </td>

                    <td
                      class="text-center resultstable body-2 font-weight-medium"
                    >
                      <!-- <div v-if="!item.settings.loading"> -->
                      <div>
                        <v-tooltip top content-class="top">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              :color="item.status_info.status.bg_color"
                              :text-color="item.status_info.status.text_color"
                              small
                              class="status text-uppercase font-weight-light"
                              v-on="on"
                            >
                              {{ item.status_info.status.status }}
                            </v-chip>
                          </template>
                          <span>
                            {{ item.status_info.status.description }}
                            {{ item.status_info.user.full_name }}
                          </span>
                        </v-tooltip>
                      </div>
                    </td>

                    <td
                      class="
                        text-center
                        resultstable
                        uppercase
                        body-2
                        font-weight-medium
                      "
                    >
                      {{ item.status_info.user.username }}
                    </td>

                    <td
                      class="text-right resultstable body-2 font-weight-medium"
                    >
                      <!-- class="samplebutton" -->
                      <v-btn
                        color="amber darken-4"
                        @click="processItem(index, 'validate')"
                        class="font-weight-light"
                        depressed
                        small
                        :disabled="item.settings.disabled"
                        :loading="item.settings.loading"
                      >
                        {{ item.settings.button_label }}
                        <v-icon right>mdi-check</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <!-- REMARKS -->
          <!-- <v-flex xs="12" md="12"> -->
          <v-expand-transition>
            <!-- <v-slide-y-transition
                class="py-0"
            >-->
            <v-card class="mt-4" v-if="showRemarks">
              <v-card-text>
                <!-- <v-layout wrap>
                <v-flex md="12" xs="12">-->
                <!-- <div v-if="showRemarks"> -->
                <!-- <div> Remarks </div> -->
                <div>
                  <v-textarea
                    v-model="data.remarks.remarks"
                    class="orange-input"
                    outlined
                    placeholder="Please type remarks..."
                    label="Remarks"
                    color="deep-purple accent-4"
                  ></v-textarea>
                </div>
                <div class="mt-n3">
                  <v-btn
                    color="deep-purple accent-4"
                    outlined
                    @click="saveRemarks()"
                    :loading="btnRemarks.loading"
                  >
                    Save Remarks
                    <!-- <v-icon right>mdi-close-circle</v-icon> -->
                  </v-btn>
                </div>
                <!-- </div> -->
                <!-- </v-flex>
                </v-layout>-->
              </v-card-text>
            </v-card>
          </v-expand-transition>
          <!-- </v-slide-y-transition> -->

          <!-- ACTIONS -->
          <v-card class="mt-4" elevation="5">
            <!-- <v-layout wrap class="right"> -->
            <v-card-actions class="text-xs-right">
              <!-- <v-menu
                transition="slide-y-transition"
                v-model="showMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
              >
                <v-list>
                  <v-list-tile
                    v-for="(menuItem, index) in menuItems"
                    :key="index"
                    @click="clickAction(index)"
                  >
                    <v-list-tile-title>
                      {{ menuItem }}
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu> -->

              <!-- <v-menu
                      transition="slide-y-transition"
                      bottom
                      :open-on-hover="right_click"
                      activator="right_click"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="purple"
                          color="primary"
                          dark
                          v-on="on"
                        >
                          Slide Y Transition
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-tile
                          v-for="(item, i) in items"
                          :key="i"
                          @click=""
                        >
                          <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
              </v-menu>-->
              <v-row justify="end">
                <v-col>
                  <div align="right">
                    <v-btn
                      color="dark--grey"
                      outlined
                      @click="closeValidation()"
                    >
                      Close
                      <v-icon right>mdi-close-circle</v-icon>
                    </v-btn>

                    <v-btn
                      class="mx-1 font-weight-light left"
                      color="indigo"
                      @click="addRemarks()"
                      :loading="printing_loading"
                    >
                      Add Remarks
                      <v-icon right>mdi-file-document</v-icon>
                    </v-btn>

                    <v-btn
                      class="mx-1 font-weight-light"
                      color="indigo"
                      @click="printResult()"
                      :loading="printing_loading"
                    >
                      PRINT RESULTS
                      <v-icon right>mdi-printer</v-icon>
                    </v-btn>

                    <v-btn
                      class="mx-1 font-weight-light"
                      color="amber darken-4"
                      v-on:click="processItem(null, 'validate_all')"
                      :disabled="validation_disabled"
                      :loading="loading"
                    >
                      VALIDATE ALL
                      <v-icon right>mdi-file-document-edit</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
            <!-- </v-layout> -->
          </v-card>
          <!-- </v-flex>
          </v-layout>
          </v-container>-->
          <!-- </v-flex> -->
        </base-lisa-material-card>
      </v-col>
    </v-row>
    <!-- </v-layout> -->
    <v-dialog v-model="dialog2" width="100%">
      <user-result :resultdata="resultdata" :key="resultkey" class="hidden" />
    </v-dialog>

    <v-dialog v-model="dialog" width="100%">
      <user-result2 :resultdata="resultdata" :key="resultkey" class="hidden" />
    </v-dialog>
  </v-container>
</template>

<script>
import EventBus from '@/plugins/eventbus';
import axios from 'axios';

export default {
  props: {
    validationdata: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loadingUpdateAllResults: false,
      initializing: true,
      dialog2: undefined,
      footerProps: {
        'items-per-page-options': [-1],
        'items-per-page-all-text': 'All',
        'items-per-page': '100',
      },
      showResult2Units: undefined,
      data: {
        order_details: {
          barcode: undefined,
        },
        patient: {
          full_name: undefined,
        },
        ref: {
          ref_range: undefined,
        },
        result: {
          result: undefined,
        },
        results: [
          {
            analyte: undefined,
            result: undefined,
          },
        ],
        analytes: [
          {
            analyte: {
              id: undefined,
            },
            result: {
              result: undefined,
            },
            ref: {
              ref_range: undefined,
            },
            flags: {
              flag: undefined,
            },
            status_info: {
              status: {
                bg_color: undefined,
              },
              user: {
                username: undefined,
              },
            },
            settings: {
              disabled: false,
            },
          },
        ],
        analyte_groups: [],
        user_settings: [],
        printed_at: undefined,
        remarks: {
          id: undefined,
          remarks: undefined,
        },
      },
      btnRemarks: {
        loading: undefined,
      },
      resultdata: {},
      resultheaders: [],
      patient: {},
      onedit: undefined,
      tab: undefined,
      loading: undefined,
      validation_disabled: false,
      printing_loading: false,
      dialog: false,
      disabled: false,
      user_settings: [],
      resultkey: 0,
      validate_all: false,
      print: false,
      result_cache: undefined,
      clicked: false,
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      showMenu: false,
      x: 0,
      y: 0,
      menuItems: ['Rerun', 'create directory'],
      right_click: false,
      index: undefined,
      prev_index: undefined,
      showRemarks: undefined,
      remarks: undefined,
      _timerID: undefined,
      _timerID2: undefined,
      initialized: undefined,
    };
  },
  methods: {
    show(e) {
      // e.preventDefault();
      // console.log(e)
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        let analyte = this.data.analytes[this.index].analyte.analyte;
        // this.index = -1
        // console.log(analyte)
        let rerun = 'Rerun - ' + analyte;
        this.menuItems = [rerun, 'Validate'];

        // console.log('this.index', this.index)
        this.showMenu = true;
      });
    },
    // clickAction(e){
    clickAction(index) {
      // console.log('index', index)
      let status_id = this.data.analytes[this.index].status_info.status.id;
      switch (index) {
        case 0:
          // RERUN
          // console.log('id', this.data.analytes[this.index].status_info.status);
          // console.log(
          //   'id',
          //   this.data.analytes[this.index].status_info.status.id
          // );
          // if (this.data.analytes[index].status_info.status.status_id != 5) {

          // status = parseInt(status_id)
          if (status_id != 5 && status_id != 2) {
            this.processItem(this.index, 'rerun');
          }
          break;
        case 1:
          // VALIDATE
          let result = this.data.analytes[this.index].result.result;

          if (result != null && result != '' && status_id != 1) {
            this.processItem(this.index, 'validate');
          }
          break;
      }
      // this.processItem(this.index,'validate')
    },
    addRemarks() {
      this.showRemarks = !this.showRemarks;
    },
    async saveRemarks() {
      // console.log(this.data);
      this.btnRemarks.loading = true;
      let action = !this.data.remarks.id ? 'new' : 'edit';

      let item =
        action == 'new'
          ? {
              order_details: this.data.order_details,
              remarks: this.data.remarks,
            }
          : this.data.remarks;

      let payload = {
        action: action,
        item: item,
      };

      // console.log('item', item);
      // console.log('action', action);
      //   return;
      this.$store
        .dispatch('remarks/processItem', payload)
        .then((res) => {
          // console.log('res_remarks', res.data);
          let notif = {
            msg: res.data.message,
          };
          EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);
        })
        .catch((error) => {
          let notif = {
            option: 'error',
            msg:
              'Server can not save the Remarks (' + error.response.status + ')',
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally((fin) => {
          this.btnRemarks.loading = false;
        });
    },
    init() {
      this.resultheaders = this.$store.getters['validation/getHeaders'];
      this.user_settings = this.$store.getters['settings/getUserSettings'];
      this.data.user_settings = this.user_settings;
      this.$store.commit('cache/UPDATE_RESULTS_DATA', this.data);
      // console.log('init', this.data);
      //   this.calculateResult();
      this.$nextTick(function () {
        this.calculateResult();
      });
      // console.log(this.user_settings)
    },
    editResult(index) {
      // alert(this.data.analytes[index].result.result)
      this.onedit = true;
    },
    test(index) {
      console.log('index', index);
      this.index = index;
      // this.right_click = true;
    },
    beforeEditCache(index) {
      if (!this.clicked) {
        this.clicked = true;
        this.result_cache = this.data.analytes[index].result.result;
        // console.log('result_cache', this.result_cache);
      }
    },
    doneEdit(index) {
      this.replaceFlag(index);
      this.roundDecimal(index);
      let result = this.data.analytes[index].result.result;
      if (result == '' || result == null) {
        result = null;
        this.data.analytes[index].result.result = null;
      }
      // console.log(this.result_cache + ' != ' + result)
      if (this.result_cache != result) {
        if (result != null || result != '') {
          const result_cache = '';

          clearTimeout(this._timerId);
          // this._timerId = setTimeout(() => {
          //     this.updateResult(index);
          // }, 500);
        }
      }

      if (result == null) {
        this.data.analytes[index].settings.disabled = true;
      }
      this.result_cache = null;
      // this.enableValidationButton(index)
      // console.log('doneEdit')
      this.calculateResult(index);
    },
    calculateResult(index) {
      // let index = 0;

      var rechecked = undefined;
      var iteration = 0;
      do {
        var i = 0;
        this.data.analytes.forEach((element) => {
          let calculated = element.analyte.calculated;
          let index = element.index;
          let formula = undefined;
          // let valid = true;
          // let nullFound = false;
          // console.log(element.analyte.analyte, element.result.result);

          if (element.formula.formula != null) {
            formula = element.formula.formula;
            // console.log(element.analyte.analyte, formula);
          }

          let status_id = element.status_info.status.status_id;
          if (calculated) {
            // if (status_id !== 1) {
            //     // this.processItem(i, 'rerun');
            // }
            try {
              var regExp = /\{([^}]+)\}/;
              var matches = true;
              var value = undefined;

              // var replaced = undefined;
              while (matches != null) {
                matches = regExp.exec(formula);
                // console.log(true);
                if (matches != null) {
                  let testResult = undefined;
                  this.data.analytes.forEach((element) => {
                    // console.log(element.analyte.code);
                    if (element.analyte.code == matches[1]) {
                      testResult = element.result.result;
                    }
                  });
                  // let replaced = undefined;

                  if (testResult != null) {
                    let replaced = formula.replace(matches[0], testResult);
                    formula = replaced;
                  } else {
                    formula = undefined;
                  }
                }

                // console.log('matches[0]', matches[0]);
                // console.log('matches[1]', matches[1]);
                // console.log('formula', formula);
              }
              // console.log('final_Formula', formula);
              //matches[1] contains the value between the parentheses

              if (formula) {
                try {
                  let result = eval(formula);
                  // console.log('result_' + i, result);
                  element.result.result = result;
                  // this.data.analytes[i].result.result = result;
                  element.settings.disabled = false;
                  // clearTimeout(this._timerID);

                  let i = 0;
                  this.data.analytes.forEach((el) => {
                    if (el.id == element.id) {
                      this.replaceFlag(i);
                      this.roundDecimal(i);
                    }
                    i++;
                  });
                } catch (ex) {
                  // console.log('error_1', ex);
                  element.result.result = null;
                  element.settings.disabled = true;
                  // element.result.result = null;
                  // this.data.analytes[i].result.result = null;
                }
              } else {
                element.result.result = null;
                element.settings.disabled = true;
              }
            } catch (ex) {
              valid = false;
              element.result.result = null;
              element.settings.disabled = true;
              // this.data.analytes[i].result.result = null;
              // console.log('error', ex);
            }
          }
          // index++;
          // console.log('iteration', iteration);
          i++;
        });
        // rechecked = true;
        // console.log('iteration', iteration);
        iteration++;
      } while (iteration < 2);

      this.calculateConversions();

      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        // this.updateResult();
        this.updateAllResults(index);
      }, 1000);

    },
    calculateConversions() {
      // if (!this.initialized) {
      //   this.initialized = true;
      //   return;
      // }
      this.data.analytes.forEach((element) => {
        let res = null;
        // Hot fix to remove the error: Undefined index: result2
        // element.result.result2 = null;

        switch (element.analyte.operator) {
          case '*':
            if (
              !isNaN(element.result.result) &&
              !isNaN(element.analyte.factor) &&
              element.result.result
            ) {
              res = element.result.result * element.analyte.factor;
            }
            break;
          case '/':
            if (
              !isNaN(element.result.result) &&
              !isNaN(element.analyte.factor) &&
              element.result.result
            ) {
              res = element.result.result / element.analyte.factor;
              // console.log('result2', res)
            }
            break;
        }
        /**
         * ROUND RESULT2
         */

        if (res) {
          let result = parseFloat(res);
          let decimal = parseInt(element.analyte.decimal2);
          res = Number(result.toFixed(decimal));
        }
        element.result.result2 = res;
      });
    },
    roundDecimal(index) {
      if (typeof this.data.analytes[index].result.result == 'string') {
        return;
      }
      var result_string = this.data.analytes[index].result.result;
      var result = parseFloat(result_string);
      var decimal = parseInt(this.data.analytes[index].analyte.decimal);
      var res = Number(result.toFixed(decimal));
      if (!result) {
        res = result_string;
      }
      this.data.analytes[index].result.result = res;
    },
    enableValidationButton(index) {
      const result = this.data.analytes[index].result.result;
      if (result) {
        this.data.analytes[index].settings.disabled = false;
      } else {
        this.data.analytes[index].settings.disabled = true;
      }
    },
    enableInput(index) {
      this.data.analytes[index].settings.input_disabled = true;
    },
    inputActivity(index) {
      this.replaceFlag(index);
      this.enableValidationButton(index);
    },
    replaceFlag(index) {
      let code = '';
      let flag = '';

      var result = parseFloat(this.data.analytes[index].result.result);
      const type = this.data.analytes[index].ref.type;

      let low_code = 'L';
      let low_flag = 'LOW';
      let high_code = 'H';
      let high_flag = 'HIGH';

      if (!type && result != NaN) {
        this.data.analytes[index].flags.code = code;
        this.data.analytes[index].flags.flag = flag;
        return;
      }

      switch (type) {
        case 'RANGE':
          var lower_limit = parseFloat(
            this.data.analytes[index].ref.lower_limit
          );
          var upper_limit = parseFloat(
            this.data.analytes[index].ref.upper_limit
          );

          if (result < lower_limit) {
            code = low_code;
            flag = low_flag;
          }

          if (result > upper_limit) {
            code = high_code;
            flag = high_flag;
          }

          break;
        case 'SYMBOL':
          let symbol = this.data.analytes[index].ref.symbol;
          var value = this.data.analytes[index].ref.value;

          switch (symbol) {
            case '>':
            case '>=':
              if (symbol == '>=' && result == value) {
                break;
              }

              if (result < value) {
                code = low_code;
                flag = low_flag;
              }
              break;
            case '<':
            case '<=':
              if (symbol == '<=' && result == value) {
                break;
              }

              if (result > value) {
                code = high_code;
                flag = high_flag;
              }
              break;
          }

          break;
        case 'TEXT':
          break;
      }

      this.data.analytes[index].flags.code = code;
      this.data.analytes[index].flags.flag = flag;
    },
    nextInput(index) {
      this.data.analytes[index].settings.loading = true;

      let el = 'text' + (index + 1);
      var i = index + 1;
      var x = index;
      for (i = index + 1; i < this.data.analytes.length; i++) {
        if (this.data.analytes[i].settings.input_disabled == false) {
          x = i;
          break;
        }
      }

      if (this.data.analytes.length - 1 == index || x == index) {
        el = 'text' + index;
        this.$refs[el].blur();
      } else {
        el = 'text' + x;
        this.$nextTick(() => this.$refs[el].focus());
      }
      this.replaceFlag(index);
    },
    getCode(item) {
      return item.flags.code;
    },
    showFlag(item) {
      if (item.flags.code) {
        return true;
      }
      return false;
    },
    async processItem(index, action) {

      this.disableButtons(true, index);
      clearTimeout(this._timerId);

      let payload = {
        action: action,
        data: this.data,
      };

      let resultdata = {};
      let status = null;
      let result = null;
      let print = null;

      if (index != null) {
        resultdata = {
          order_details: this.data.order_details,
          patient: this.data.patient,
          analytes: [this.data.analytes[index]],
        };

        status = this.data.analytes[index].status_info.status.id;
        result = this.data.analytes[index].result.result;
      }

      // VALIDATE ONE (1) RESULT ONLY
      if (action == 'validate' && index != null && status != 1) {
        payload = {
          action: action,
          data: resultdata,
        };
      }

      // FOR RERUN
      if ((index != null && status == 1) || action == 'rerun') {
        this.data.analytes[index].result.result = null;
        this.data.analytes[index].result.result2 = null;
        payload = {
          action: 'rerun',
          data: resultdata,
        };
      }

      if (action == 'validate_all') {
        EventBus.$emit('SHOW_LOADER', true);
      }

      let notif = {
        option: 'success',
        msg: 'All Results are Successfully Validated!',
        show: true,
      };

      // console.log('this.data.order_details', this.data.order_details);
      //VALIDATE
      this.$store
        .dispatch('results/validate', payload)
        .then((res) => {
          notif = {
            option: res.data.option,
            msg: res.data.message,
            show: true,
          };

          this.data.analyte_groups = res.data.analyte_groups;
          this.data.results = res.data.results;
          this.data.validated_results = res.data.validated_results;
          this.data.printed_at = res.data.printed_at;

          let status_info = res.data.status_info;

          switch (payload.action) {
            case 'rerun':
              this.afterRerun(index);
              this.changeStatus(index, status_info);

              break;
            case 'validate':
              this.afterValidation(index);
              this.changeStatus(index, status_info);

              break;
            case 'validate_all':
              this.validate_all = true;
              this.changeStatus('all');
              break;
          }

          EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);
          // console.log('res_Validate', res);
          print = res.data.print;
          if (res.data.print) {
            // this.printResult();
            this.disableButtons(false, index);
            EventBus.$emit('SHOW_LOADER', false);
            // let session = this.user_settings.user.id + '|' + this.user_settings.user.session;
            let session = this.user_settings.user.id + '|' + this.user_settings.session;
            console.log('sesson_2', session)
            console.log('this.data.order_details_2', this.data.order_details)
            console.log('this.data.order_details_id_2', this.data.order_details.id) 
            window.open('/result/pdf/' + this.data.order_details.id + '|' + session, '_blank');
          } else {
            // let msg = 'No Validated Results to Print';
            // notif = { option: 'info', msg: msg, show: true };
            // EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          }
        })
        .catch((error) => {
          notif = {
            option: 'error',
            msg:
              'Server can not process the Validation. (' +
              error.response.status +
              ')',
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally((fin) => {
          this.disableButtons(false, index);
          if (!print) {
            EventBus.$emit('SHOW_LOADER', false);
          }
        });
    },
    afterRerun(index) {
      this.data.analytes[index].result.result = null;
      this.data.analytes[index].settings.button_label = 'VALIDATE';
      this.data.analytes[index].settings.input_disabled = false;
      this.data.analytes[index].settings.disabled = true;
    },
    afterValidation(index) {
      this.data.analytes[index].settings.button_label = 'RERUN';
      this.data.analytes[index].settings.disabled = false;
      this.data.analytes[index].settings.input_disabled = true;
    },
    changeStatus(index, status_info) {
      if (index == 'all') {
        const results = this.data.results;
        const analytes = this.data.analytes;

        for (var i = 0; i < this.data.results.length; i++) {
          const results = this.data.results[i];
          const id = this.data.results[i].result.id;
          const a_index = analytes.findIndex(
            (analyte) => analyte.analyte.id == id
          );
          this.data.analytes[a_index].status_info = results.status_info;

          if (this.data.analytes[a_index].result.result) {
            this.afterValidation(a_index);
          }
        }
      } else {
        // console.log('status', status_info);
        this.data.analytes[index].status_info = status_info;
      }
    },
    disableButtons(option, index) {
      this.loading = option;
    },
    async printResult() {
      EventBus.$emit('SHOW_LOADER', true);
      this.user_settings = this.$store.getters['settings/getUserSettings'];
      this.data.user_settings = this.user_settings;

      // console.log('this.user_settings', this.user_settings);

      let payload = {
        action: 'print_all',
        sample_order_id: this.data.order_details.id,
      };

      await this.getValidatedResults(payload);
      return;
      /**
       * END
       */
      //PRINTRESULT
      if (this.validate_all) {
        this.validate_all = false;
        print = true;

        this.resultdata = this.data;
        this.$store.commit('cache/UPDATE_RESULTS_DATA', this.data);
        this.resultkey += 1;
        this.dialog = true;
        EventBus.$emit('SHOW_LOADER', false);
      } else {
        let payload = {
          action: 'print_all',
          sample_order_id: this.data.order_details.id,
        };

        await this.getValidatedResults(payload);
      }
    },
    async getValidatedResults(payload) {
      this.printing_loading = true;
      let notif = {};
      this.$store
        .dispatch('results/fetchValidatedResults', payload)
        .then((res) => {
          console.log('fetchValidatedResults', res);

          this.data.analyte_groups = res.data.analyte_groups;
          this.data.results = res.data.results;
          this.data.validated_results = res.data.validated_results;
          this.data.printed_at = res.data.printed_at;
          const print = res.data.print;

          if (print) {
            /**
             * WORKING
             */
            // this.resultdata = this.data;
            // this.$store.commit('cache/UPDATE_RESULTS_DATA', this.data);

            // this.resultkey += 1;
            // this.dialog = true;
            /**
             * WORKING
             */

            // this.disableButtons(false, index);
            EventBus.$emit('SHOW_LOADER', false);
            let session = this.user_settings.user.id + '|' + this.user_settings.session;
            console.log('session_1', session)
            console.log('this.data.order_details_1', this.data.order_details)
            console.log('this.data.order_details_id_1', this.data.order_details.id) 
            window.open('/result/pdf/' + this.data.order_details.id + '|' + session, '_blank');
          } else {
            let msg = 'No Validated Results to Print';
            notif = { option: 'info', msg: msg, show: true };
            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          }
        })
        .catch((error) => {
          // console.log(error)
          notif = {
            option: 'fail',
            msg:
              'Server can not fet Validated Results (' +
              error.response.status +
              ')',
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          // }
          return false;
        })
        .finally((fin) => {
          this.printing_loading = false;
          EventBus.$emit('SHOW_LOADER', false);
        });
    },
    async updateAllResults(index) {
      // if (!this.data.analytes[index].result.result) return;
      if (this.initializing) {
        this.initializing = !this.initializing;
        return;
      }

      if (this.loadingUpdateAllResults) return;

      let resultdata = {
        order_details: this.data.order_details,
        analyte: this.data.analytes,
      };

      // let payload = {
      //   action: 'edit',
      //   data: resultdata,
      // };

      let payload = {
        action: 'edit',
        data: this.createResultsPayload(),
      };

      // updateAllResults
      this.loadingUpdateAllResults = true;
      this.$store
        .dispatch('results/updateAllResults', payload)
        .then((results) => {
          // console.log(results);
          let i = 0;
          results.data.forEach((res) => {
            // console.log('res_' + i, res);
            this.data.analytes.forEach((element) => {
              if (res.analyte_id === element.analyte.id) {
                // console.log('element', element);
                element.status_info = res.status_info;
                

                // break;
              }
              element.settings.loading = false;
              element.settings.disabled = res.disabled;
            }); // END this.data.analytes.forEach

            i++;
          }); // END res.forEach
        })
        .finally(() => {
          this.loadingUpdateAllResults = false;
        });
    },

    createResultsPayload() {
      let orderDetails = {
        sample_id: this.data.order_details.sample_id,
        id: this.data.order_details.id,
        patient_id: this.data.order_details.patient_id,
      }

      let analytes = [];
      // this.branches.push({ id: 0, branch: 'ALL' });
      this.data.analytes.forEach((element) => {
        // console.log('element', element)
        // element.settings.loading = true;
        let analyte = {
          order: {
            id: element.order.id,
            analyte_id: element.order.analyte_id,
            analyte_group_id: element.order.analyte_group_id,
          },
          result: element.result,
          ref: {
            ref_range: element.ref.ref_range,
          },
          flags: {
            code: element.flags.code
          }
        };
        analytes.push(analyte);
      });

      let payload = {
        order_details: orderDetails,
        analyte: analytes,
      };

      return payload;
      
    },
    
    async updateResult(index, updateCalculatedResult) {
      /**
       * DEPRECATED METHOD - SEE UPDATEALL METHOD
       */

      var apiCalls = [];
      var i = 0;
      this.data.analytes.forEach((element) => {
        this.data.analytes[i].settings.loading = true;
        let resultdata = {
          order_details: this.data.order_details,
          analyte: this.data.analytes[i],
        };

        let payload = {
          action: 'edit',
          data: resultdata,
        };
        apiCalls[i] = axios.post('/api/results/update', payload);
        i++;
      });

      axios
        .all(apiCalls)
        .then(
          axios.spread((...responses) => {
            i = 0;
            this.data.analytes.forEach((element) => {
              this.data.analytes[i].status_info = responses[i].data.status_info;
              this.data.analytes[i].settings.loading = false;
              this.data.analytes[i].settings.disabled = false;

              // console.log('status_info_' + i, responses[i]);
              i++;
            });
            // use/access the results
          })
        )
        .catch((errors) => {
          // react on errors.
          // console.log('apiCall_Errors', errors);
        });

      return;
      if (updateCalculatedResult) api = 'results/processCalculatedResult';

      this.$store
        .dispatch('results/processItem', payload)
        .then((res) => {
          console.log('res_updated_result_status', res.data);
          let status_info = res.data.status_info;
          let disabled = res.data.disabled;
          this.data.analytes[index].status_info = status_info;
          this.data.analytes[index].settings.loading = false;
          this.data.analytes[index].settings.disabled = false;
        })
        .catch((error) => {
          notif = {
            option: 'error',
            msg:
              'Server can not Update the Result (' +
              error.response.status +
              ')',
          };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally((fin) => {
          this.disableButtons(false, index);
          this.clicked = false;
          clearTimeout(this._timerId);
        });
    },
    async updateResult2(data, index) {
      let resultdata = {};
      let notif = {};
      resultdata = {
        order_details: this.data.order_details,
        analyte: data,
      };

      let payload = {
        action: 'edit',
        data: resultdata,
      };

      this.$store
        .dispatch('results/processCalculatedResult', payload)
        .then((res) => {
          console.log('res_updated_result_status_2', res.data);
          let status_info = res.data.status_info;
          let disabled = res.data.disabled;
          let i = 0;
          this.data.analytes.forEach((element) => {
            if (element.index == index) {
              this.data.analytes[i].status_info = status_info;
            }
            i++;
          });
          // this.data.analytes[index].status_info = status_info;
          // this.data.analytes[index].settings.disabled = disabled;
          // this.data.analytes[index].settings.loading = false;
        })
        .catch((error) => {
          console.log(error);
          // notif = {
          //     option: 'error',
          //     msg:
          //         'Server can not Update the Result (' +
          //         error.response.status +
          //         ')'
          // };
          // EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
        })
        .finally((fin) => {
          // this.disableButtons(false, index);
          this.clicked = false;
          clearTimeout(this._timerID2);
        });
    },
    closeValidation() {
      this.showRemarks = undefined;
      EventBus.$emit('CLOSE_VALIDATION');
    },
  },
  computed: {
    getFlagCode(index) {
      // return this.data.analytes[index].ref.code
      return 1;
    },
  },
  watch: {
    validationdata(val) {
      this.data = val;
    },
  },
  mounted() {
    this.init();
    EventBus.$on('CLOSE_RESULT_VIEW', (payload) => {
      this.dialog = false;
    });
    this.data = this.validationdata;
    // EventBus.$on('GET_UPDATED_RESULTS', () => {
    //   return this.data
    // });
  },
  destroyed() {
    // console.log('validation destroyed');
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none !important;
}
.my-text-style >>> .v-text-field__slot input {
  color: red;
}

.resulttext {
  text-align: center;
  height: 30px;
  /* padding-left: 10px;
  padding-right: 10px; */
}
.status {
  align-items: center;
  border-radius: 13px;
  display: inline-flex;
  font-size: 12px;
  margin: 1px;
  outline: none;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
  height: 25px;
}
.flags {
  align-items: center;
  border-radius: 13px;
  display: inline-flex;
  font-size: 12px;
  margin: 1px;
  outline: none;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  vertical-align: middle;
  height: 30px;
}

.uppercase {
  text-transform: uppercase;
}

::v-deep .v-chip__content {
  color: white;
}
</style>
