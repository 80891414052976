<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col xs="12" md="12" class="mb-4">
        <v-btn color="primary" @click="newRefRange()" ref="new">
          <v-icon>mdi-plus</v-icon>NEW REFERENCE RANGE
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="expand">
        <!-- <v-card v-if="expand"> -->
        <v-row>
          <validation-observer ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateForm)">
              <v-row>
                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="priority"
                    v-slot="{ errors }"
                    name="Priority"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model.trim="item_.priority"
                      label="Priority"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="priority"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="sex"
                    v-slot="{ errors }"
                    name="Gender"
                    rules="required"
                  >
                    <v-select
                      v-model.trim="item_.sex"
                      :items="sex"
                      label="Sex"
                      :error-messages="errors"
                      class="orange-input"
                      item-text="name"
                      item-value="id"
                      color="orange"
                      ref="sex"
                      @change="setSex"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="agefrom"
                    v-slot="{ errors }"
                    name="Age From"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model.trim="item_.age_from"
                      label="Age From"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="agefrom"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="ageto"
                    v-slot="{ errors }"
                    name="Age To"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model.trim="item_.age_to"
                      label="Age To"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="ageto"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="timeperiod"
                    v-slot="{ errors }"
                    name="Time Period"
                    rules="required"
                  >
                    <v-select
                      v-model.trim="item_.time_period"
                      :items="timeperiod"
                      label="Time Period"
                      :error-messages="errors"
                      class="orange-input"
                      item-text="name"
                      item-value="id"
                      color="orange"
                      ref="timeperiod"
                      @change="setTimePeriod"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="type"
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model.trim="item_.type"
                      :items="type"
                      label="Type"
                      :error-messages="errors"
                      class="orange-input"
                      item-text="name"
                      item-value="id"
                      color="orange"
                      ref="type"
                      @change="setType"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="lower_limit"
                    v-slot="{ errors }"
                    name="Lower LImit"
                    rules="required"
                  >
                    <v-text-field
                      v-model.trim="item_.lower_limit"
                      label="Lower Limit"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="lowerlimit"
                      :disabled="range"
                      v-if="!range"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="upper_limit"
                    v-slot="{ errors }"
                    name="Upper Limit"
                    rules="required"
                  >
                    <v-text-field
                      v-model.trim="item_.upper_limit"
                      label="Upper Limit"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="upperlimit"
                      :disabled="range"
                      v-if="!range"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="symbol"
                    v-slot="{ errors }"
                    name="Symbol"
                    rules="required"
                  >
                    <v-select
                      v-model.trim="item_.symbol"
                      :items="symbols"
                      label="Symbol"
                      :error-messages="errors"
                      class="orange-input"
                      item-text="name"
                      item-value="id"
                      color="orange"
                      ref="symbol"
                      :disabled="symbol"
                      v-if="!symbol"
                      @change="setSymbol"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="value"
                    v-slot="{ errors }"
                    name="Value"
                  >
                    <v-text-field
                      v-model.trim="item_.value"
                      label="Value"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="value"
                      :disabled="symbol"
                      v-if="!symbol"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="12" class="mx-2">
                  <validation-provider
                    vid="textrange"
                    v-slot="{ errors }"
                    name="Range in Text"
                    rules="required"
                  >
                    <v-textarea
                      v-model.trim="item_.text_range"
                      label="Range in Text"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="textrange"
                      :disabled="text"
                      v-if="!text"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="12" class="mx-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Force Secondary Reference Range"
                  >
                    <v-switch
                      v-model="secondary"
                      label="Force Secondary Reference Range"
                      color="orange"
                      @change="setSecondary"
                    ></v-switch>
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="12" class="mx-2">
                  <validation-provider
                    vid="secondrange"
                    v-slot="{ errors }"
                    name="Secondary Reference Range"
                    rules="required"
                  >
                    <v-text-field
                      v-model="item_.secondary_range"
                      label="Secondary Reference Range"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="secondrange"
                      v-if="range2"
                    />
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="3" class="mx-2">
                  <validation-provider
                    vid="instrument"
                    v-slot="{ errors }"
                    name="Instrument"
                    rules="required"
                  >
                    <v-select
                      v-model.trim="item_.instrument_id"
                      :items="instruments"
                      label="Instrument"
                      :error-messages="errors"
                      class="orange-input"
                      item-text="instrument"
                      item-value="id"
                      color="orange"
                      ref="instrument"
                      @change="setInstrument"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="12" class="mx-2">
                  <validation-provider
                    vid="active"
                    v-slot="{ errors }"
                    name="active"
                  >
                    <v-switch
                      v-model="active"
                      label="Active"
                      color="orange"
                      @change="setActive"
                    ></v-switch>
                  </validation-provider>
                </v-col>

                <v-col xs="12" md="12" ma-3 text-xs-left>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="indigo"
                    v-on:click="processItem()"
                    :disabled="loading"
                    :loading="loading"
                  >
                    <!-- <v-btn
                class="mx-0 font-weight-light"
                color="orange"
                type="submit"
                :disabled="loading"
                :loading="loading"
                    >-->
                    {{ getButtonLabel() }}
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="dark--grey"
                    outlined
                    v-on:click="clearAndRefresh()"
                    >Clear</v-btn
                  >
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-row>
        <!-- </v-card> -->
      </div>
    </v-expand-transition>

    <v-row>
      <v-col xs="12" md="12">
        <v-data-table :headers="refrangeheaders" :items="items">
          <template slot="headerCell" slot-scope="{ header }">
            <span
              class="font-weight-light text-warning text--darken-3"
              v-text="header.text"
            />
          </template>

          <template v-slot:item.time_period="{ item }">
            <span class="lisa-font-weight-bold">{{
              setTimePeriodLabel(item.time_period)
            }}</span>
          </template>

          <template v-slot:item.instrument="{ item }">
            <span class="lisa-font-weight-bold">{{
              setInstrument(item.instrument_id)
            }}</span>
          </template>

          <template v-slot:item.active="{ item }">
            <span class="lisa-font-weight-bold">
              <v-chip :color="setColor(item.active)" dark :small="true">{{
                setActiveLabel(item.active)
              }}</v-chip>
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top content-class="top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  slot="activator"
                  class="v-btn--simple"
                  color="success"
                  icon
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <!-- $vuetify.goTo(target, scrollOptions); -->
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <!-- <v-tooltip top content-class="top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  slot="activator"
                  class="v-btn--simple"
                  color="danger"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>-->
          </template>
          <!-- <template slot="items" slot-scope="{ index, item }">
            <td class="text-xs-right">{{ item.priority }}</td>
            <td class="text-xs-right">{{ item.sex }}</td>
            <td class="text-xs-right">{{ item.age_from }}</td>
            <td class="text-xs-right">{{ item.age_to }}</td>
            <td class="text-xs-right">{{ setTimePeriodLabel(item.time_period) }}</td>
            <td class="text-xs-right">{{ item.type }}</td>
            <td class="text-xs-right">{{ item.lower_limit }}</td>
            <td class="text-xs-right">{{ item.upper_limit }}</td>
            <td class="text-xs-right">{{ item.symbol }}</td>
            <td class="text-xs-right">{{ item.value }}</td>
            <td class="text-xs-right">{{ item.text_range }}</td>
            <td class="text-xs-right">{{ setInstrument(item.instrument_id) }}</td>
            <td class="text-xs-right">
              <v-chip
                :color="setColor(item.active)"
                dark
                :small="true"
              >{{ setActiveLabel(item.active) }}</v-chip>
            </td>

            <td class="text-xs-right">
              <v-tooltip top content-class="top">
                <v-btn
                  slot="activator"
                  class="v-btn--simple"
                  color="success"
                  icon
                  @click="editItem(item)"
                >
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip top content-class="top">
                <v-btn
                  slot="activator"
                  class="v-btn--simple"
                  color="danger"
                  icon
                  @click="deleteItem(item)"
                >
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </template>-->
        </v-data-table>
      </v-col>

      <v-col xs="12" text-xs-right ma-5>
        <v-btn
          class="mx-0 font-weight-light"
          color="orange"
          v-on:click="closeDialog()"
          >Close</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns';
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  // Validator
} from 'vee-validate';

import EventBus from '@/plugins/eventbus';

export default {
  props: {
    action: {
      type: String,
      default() {
        return '';
      },
    },
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      expand: false,
      refrangeheaders: [],
      refrangeitems: [],
      item_: {
        id: '',
        analyte_id: '',
        priority: '1',
        sex: '',
        age_from: '',
        age_to: '',
        time_period: '',
        type: '',
        lower_limit: '',
        upper_limit: '',
        symbol: '',
        value: '',
        text_range: '',
        enable_2nd_range: false,
        secondary_range: '',
        instrument_id: '',
        active: true,
      },
      sex: [
        {
          id: 'A',
          name: 'ALL',
        },
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Female',
        },
      ],
      timeperiod: [
        {
          id: 'D',
          name: 'Days',
        },
        {
          id: 'M',
          name: 'Months',
        },
        {
          id: 'Y',
          name: 'Years',
        },
      ],
      type: [
        {
          id: 'RANGE',
          name: 'Range',
        },
        {
          id: 'SYMBOL',
          name: 'Symbol < / >',
        },
        {
          id: 'TEXT',
          name: 'Text',
        },
      ],
      symbols: [
        {
          id: '>',
          name: '>',
        },
        {
          id: '>=',
          name: '>=',
        },
        {
          id: '<',
          name: '<',
        },
        {
          id: '<=',
          name: '<=',
        },
      ],
      instruments: [],
      range: false,
      symbol: false,
      text: false,
      range2: false,
      loading: false,
      dialog: false,
      options: {
        actiontext: [''],
        action: 'new',
      },
      id: '',
      item: '',
      itemdata: {},
      message: '',
      module: '',
      snackbar: false,
      closedialog: false,
      switchlabel: 'Inactive',
      analyte_id: null,
      items: [],
      savebuttonlabel: 'SAVE REFERENCE RANGE',
      validator: null,
      rangevalidator: null,
      symbolvalidator: null,
      textvalidator: null,
      range2validator: null,
      active: true,
      secondary: false,
      intialized: false,
      analyte_id: null,
      //SCROLLING
      scrollType: 'element',
      number: 9999,
      selector: '#first',
      selections: ['#first', '#second', '#third'],
      selected: 'Button',
      elements: ['Button', 'Radio group'],
      duration: 1000,
      offset: 500,
      easing: 'easeInOutCubic',
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    validateForm(scope) {
      this.processItem();
    },
    setSex(id) {
      this.item_.sex = id;
    },
    setTimePeriod(id) {
      this.item_.time_period = id;
    },
    setType(id) {
      this.item_.type = id;
      if (id == 'RANGE') {
        this.range = false;
        this.symbol = this.text = true;

        this.setFocus(0);
      } else if (id == 'SYMBOL') {
        this.symbol = false;
        this.range = this.text = true;

        // this.$refs.lowerlimit.reset()
        // this.$refs.upperlimit.reset()

        this.setFocus(1);
      } else {
        this.text = false;
        this.range = this.symbol = true;
        this.setFocus(2);
      }
    },
    setSymbol(id) {
      this.item_.symbol = id;
      this.setFocus(3);
    },
    setSecondary(id) {
      this.secondary = id;
      this.range2 = this.secondary;

      this.item_.enable_2nd_range = id;
      this.setFocus(4);
    },
    setInstrument(id) {
      this.item_.instrument_id = id;
    },
    setActive(id) {
      // console.log(id)
      // this.item_.active = id
    },
    setColor(value) {
      if (value === 'YES' || value === 1) {
        this.items.active = 'YES';
        return 'orange';
      } else {
        return '';
      }
    },
    setActiveLabel(value) {
      if (value === 'YES' || value === 1) {
        return 'YES';
      } else {
        return 'NO';
      }
    },
    setTimePeriodLabel(value) {
      switch (value) {
        case 'D':
          return 'DAYS';
          break;
        case 'M':
          return 'MONTHS';
          break;
        case 'Y':
          return 'YEARS';
          break;
      }
    },
    setInstrument(value) {
      var [instrument] = this.instruments.filter((el) => el.id == value);
      return instrument.instrument;
    },
    closeDialog() {
      // this.dialog = true
      this.expand = false;
      // EventBus.$emit('CLEAR_REFRANGE');
      EventBus.$emit('CLOSE_ANALYTE_DIALOG');
    },
    async setOption(opt) {
      this.options = opt;
      return true;
    },
    async fetchItems() {
      if (!this.analyte_id) return;

      this.itemdata = {};
      this.refrangeitems = {};
      await this.$store
        .dispatch('referenceranges/fetchItems', this.analyte_id)
        .then((res) => {
          console.log('referenceranges/fetchItems', res);
          this.items = res.data;
          // this.refrangeitems = res.data
        })
        .catch((error) => {
          var notif = {
            option: 'error',
            msg: 'Error in getting ' + this.title + ' data.',
          };
          // this.showNotification(notif);

          // notif = {
          //   option: 'error',
          //   msg:
          //     error.response.data.message + ' (' + error.response.status + ')',
          //   error: error,
          // };
          EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

          // console.log(error)
        });
    },
    async processItem() {
      // this.loading = true
      // return false
      // EventBus.$emit('CALL_EVENT_BUS')
      var isValid2 = false;
      var isValid3 = true;

      // console.log('ACTION: ' + this.options.action)

      // if (this.options.action != 'delete') {
      //   const isValid = await this.$refs.observer.validate();

      //   const isAllValid = await this.validator
      //     .validateAll({
      //       priority: this.item_.priority,
      //       sex: this.item_.sex,
      //       agefrom: this.item_.age_from,
      //       ageto: this.item_.age_to,
      //       timeperiod: this.item_.time_period,
      //       type: this.item_.type,
      //     })
      //     .then((result) => {
      //       if (result) return true;
      //       else return false;
      //     });

      //   if (isAllValid) {
      //     switch (this.item_.type) {
      //       case 'RANGE':
      //         isValid2 = await this.rangevalidator
      //           .validateAll({
      //             type: this.item_.type,
      //             lowerlimit: this.item_.lower_limit,
      //             upperlimit: this.item_.upper_limit,
      //           })
      //           .then((result) => {
      //             if (result) return true;
      //             else return false;
      //           });
      //         break;
      //       case 'SYMBOL':
      //         isValid2 = await this.symbolvalidator
      //           .validateAll({
      //             type: this.item_.type,
      //             symbol: this.item_.symbol,
      //             value: this.item_.value,
      //           })
      //           .then((result) => {
      //             if (result) return true;
      //             else return false;
      //           });
      //         break;
      //       case 'TEXT':
      //         isValid2 = await this.textvalidator
      //           .validateAll({
      //             type: this.item_.type,
      //             textrange: this.item_.text_range,
      //           })
      //           .then((result) => {
      //             if (result) return true;
      //             else return false;
      //           });
      //         break;
      //     }

      //     // if (this.item_.type == 'RANGE') {
      //     //     isValid2 = await this.rangevalidator.validateAll({
      //     //       type: this.item_.type,
      //     //       lowerlimit: this.item_.lower_limit,
      //     //       upperlimit: this.item_.upper_limit
      //     //     }).then((result) => {
      //     //       if (result)
      //     //         return true
      //     //       else return false
      //     //     });
      //     //   } else if (this.item_.type == 'SYMBOL') {
      //     //     isValid2 = await this.symbolvalidator.validateAll({
      //     //       type: this.item_.type,
      //     //       symbol: this.item_.symbol,
      //     //       value: this.item_.value
      //     //     }).then((result) => {
      //     //       if (result)
      //     //         return true
      //     //       else return false
      //     //     });
      //     //   } else if (this.item_.type == 'TEXT') {
      //     //     isValid2 = await this.textvalidator.validateAll({
      //     //       type: this.item_.type,
      //     //       textrange: this.item_.text_range
      //     //     }).then((result) => {
      //     //       if (result)
      //     //         return true
      //     //       else return false
      //     //     });
      //     //   }
      //   }

      //   if (this.item_.enable_2nd_range) {
      //     isValid3 = await this.range2validator
      //       .validateAll({
      //         enable_2nd_range: this.item_.enable_2nd_range,
      //         secondrange: this.item_.secondary_range,
      //       })
      //       .then((result) => {
      //         if (result) return true;
      //         else return false;
      //       });
      //   }
      // } else {
      //   // ELSE DELETE OPTION
      //   isValid2 = true;
      //   isValid3 = true;
      // }

      isValid2 = true;
      isValid3 = true;

      if (isValid2 && isValid3) {
        // this.item_.name = this.item
        // this.item_.name = ''
        this.item_.analyte_id = this.analyte_id;
        this.item_.enable_2nd_range = this.range2;
        this.item_.active = this.active;

        this.loading = true;

        var payload = {
          action: this.options.action,
          item: this.item_,
          module: 'referenceranges',
        };

        this.$store
          .dispatch('referenceranges/processItem', payload)
          .then((res) => {
            var notif = { option: 'success', msg: '', show: true };

            if (this.options.action == 'delete')
              notif = {
                option: 'success',
                msg: 'Delete Successful!',
                show: true,
              };

            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

            this.clear();
            console.log('res_referenceranges', res);
            this.items = res.data;
            // this.fetchItems();
          })
          .catch((error) => {
            var notif = { option: 'error', msg: '' };
            // console.log('error', error.response.data);
            if (error.response.data.errors) {
              this.$refs.form.setErrors(error.response.data.errors);
              //   this.$refs.form.setErrors(err.response.data.errors);
            }
            notif = {
              option: 'error',
              msg:
                error.response.data.message +
                ' (' +
                error.response.status +
                ')',
              error: error,
            };
            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
            // this.loading = false;
            // this.clear()
          })
          .finally((this.loading = false));
      } else {
        // Throw Validation Error
      }
    },
    async setFocus(index) {
      return;
      // if (index == 0) this.$nextTick(() => this.$refs.form.lowerlimit.focus());
      // else if (index == 1) this.$nextTick(() => this.$refs.form.symbol.focus());
      // else if (index == 2)
      //   this.$nextTick(() => this.$refs.form.textrange.focus());
      // else if (index == 3) this.$nextTick(() => this.$refs.form.value.focus());
      // else if (index == 4)
      //   this.$nextTick(() => this.$refs.form.secondrange.focus());
    },
    async init() {
      this.instruments = this.$store.getters['instruments/getItems'];
      this.analyte_id = this.$store.getters['cache/getAnalyteID'];
      this.item_.analyte_id = this.analyte_id;
      this.refrangeheaders = this.$store.getters['referenceranges/getHeaders'];

      if (!this.initialized) {
        this.module = 'referenceranges';
        await this.$store
          .dispatch('instruments/fetchItems')
          .then((res) => {
            this.instruments = res.data;
          })
          .catch((error) => {
            var notif = {
              option: 'error',
              msg: 'Error in getting Instruments data.',
            };
            // this.showNotification(notif);

            // notif = {
            //   option: 'error',
            //   msg:
            //     error.response.data.message +
            //     ' (' +
            //     error.response.status +
            //     ')',
            //   error: error,
            // };
            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

            // console.log(error)
          });
      }

      let analyteSettings = this.$store.getters['cache/getAnalyteSettings'];
      this.item_ = analyteSettings.itemdata;
      // console.log('analyteSettings', analyteSettings);

      // this.options = analyteSettings.option;
      switch (analyteSettings.option.action) {
        case 'new':
          this.clear(false);
          this.items = [];
          break;
        case 'edit':
          // EventBus.$emit('LOAD_REFERENCE_RANGE');
          this.fetchItems();
          break;
      }

      this.initialized = true;
    },
    newRefRange() {
      this.expand = true;
      this.clear(false);
      // alert('newRefRange')
      // EventBus.$emit('CLEAR_REFRANGE')
    },
    editItem(payload) {
      this.expand = true;
      this.clear(false);
      this.item_ = payload;

      // SET VALUES

      this.secondary = this.item_.enable_2nd_range == '1' ? true : false;

      if (this.setSecondary) this.setSecondary(this.secondary);
      this.active = this.item_.active == '1' ? true : false;
      this.setType(this.item_.type);

      this.options.action = 'edit';
      this.savebuttonlabel = 'UPDATE REFERENCE RANGE';
      //   this.$vuetify.goTo(this.target, this.scrollOptions);
      // document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
      document.getElementsByClassName('v-dialog--active')[0].scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth',
      // });

      // var el = this.$el.getElementsByClassName("v-dialog--active'")[0];
      // el.scrollIntoView({ behavior: 'smooth' });

      //   this.$vuetify.goTo(0);
      //   $vuetify.goTo(target, options);
    },
    deleteItem(payload) {
      // this.itemdata.id = id
      // this.itemdata.name = name
      this.item_ = payload;
      this.item_.name = 'REFERENCE RANGE';
      this.options.action = 'delete';
      EventBus.$emit('SHOW_DELETE_DIALOG', this.item_, this.module, true);
      this.clear;
    },
    getButtonLabel() {
      if (!this.loading) return this.savebuttonlabel;
      else return 'SAVING...';
    },
    async clear(toggle) {
      this.item_ = {};
      this.itemdata = {};

      requestAnimationFrame(() => {
        // this.$refs.observer.reset();
        if (this.expand) this.$refs.form.reset();
      });

      //   this.$nextTick(() => {
      //     this.errors.clear();
      //     this.$nextTick(() => {
      //         this.$validator.reset();
      //     });
      // });
      this.range = false;
      this.symbol = false;
      this.text = false;
      this.loading = false;
      this.savebuttonlabel = 'SAVE REFERENCE RANGE';
      this.range2 = false;

      this.item_ = {};
      this.analyte_id = this.$store.getters['cache/getAnalyteID'];
      this.item_.analyte_id = this.analyte_id;
      this.item_.priority = '1';
      this.item_.enable_2nd_range = false;
      this.item_.active = true;
      this.secondary = false;
      this.active = true;
      this.options.action = 'new';
    },
    clearAndRefresh() {
      this.clear(false);
      this.init();
    },
    async setFocus(index) {
      return;
      // if (index == 0) this.$nextTick(() => this.$refs.form.lowerlimit.focus());
      // else if (index == 1) this.$nextTick(() => this.$refs.form.symbol.focus());
      // else if (index == 2)
      //   this.$nextTick(() => this.$refs.form.textrange.focus());
      // else if (index == 3) this.$nextTick(() => this.$refs.form.value.focus());
      // else if (index == 4)
      //   this.$nextTick(() => this.$refs.form.secondrange.focus());
      // else if (index == 5)
      //   this.$nextTick(() => this.$refs.form.priority.focus());
    },
  },
  computed: {
    showTextfield() {
      if (this.options.action != 'delete') return true;
      else return false;
    },
    getIcon() {
      return this.options.icon;
    },
    getAction() {
      return this.options.action;
    },
    getItem() {
      return this.item_.name;
    },
    target() {
      //   return 'element';
      return this[this.scrollType];
      //   return this['element'];
      const value = this[this.scrollType];
      if (!isNaN(value)) return Number(value);
      else return value;
    },
    scrollOptions() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
    element() {
      return this.$refs.new;
      //   if (this.selected === 'Button') return this.$refs.button;
      //   else if (this.selected === 'Radio group') return this.$refs.radio;
      //   else return null;
    },
  },
  mounted() {
    EventBus.$on('CLEAR_REFRANGE', (payload) => {
      this.expand = false;
      this.clear(false);
      this.items = [];
    });
    // EventBus.$on('CLEAR_DIALOG', (payload) => {
    //   console.log('CLEAR_DIALOG')
    //   this.clear(false)
    // }),
    EventBus.$on('LOAD_REFERENCE_RANGE', (payload) => {
      this.clear(false);
      this.init();
      // EventBus.$off
    });

    this.clear(false);
    this.init();
  },
  beforeDestroy() {
    EventBus.$off('CLEAR_REFRANGE');
    EventBus.$off('LOAD_REFERENCE_RANGE');
  },
  created() {
    // this.validator = new validator({
    //   priority: 'required|numeric',
    //   sex: 'required',
    //   agefrom: 'required|numeric',
    //   ageto: 'required|numeric',
    //   timeperiod: 'required',
    //   type: 'required',
    // });
    // this.rangevalidator = new validator({
    //   type: 'required',
    //   lowerlimit: 'required|decimal',
    //   upperlimit: 'required|decimal',
    // });
    // this.symbolvalidator = new validator({
    //   type: 'required',
    //   symbol: 'required',
    //   value: 'required|decimal',
    // });
    // this.textvalidator = new validator({
    //   type: 'required',
    //   textrange: 'required',
    // });
    // this.range2validator = new validator({
    //   enable_2nd_range: 'required',
    //   secondrange: 'required',
    // });
  },
};
</script>

<style>
.v-toolbar__content {
  margin-left: 0px !important;
}

.v-datatable__actions {
  color: black;
}

.v-datatable__actions .v-btn {
  color: orange !important;
}
.v-messages__message {
  color: red !important;
}
</style>
