import Vue from 'vue';
import Router from 'vue-router';
import VueAnalytics from 'vue-analytics';
import Meta from 'vue-meta';

Vue.use(Router);
Vue.use(Meta);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/lisa',
            component: () => import('@/views/dashboard/Index'),
            children: [
                {
                    name: 'lisa',
                    path: '/',
                    component: () => import('@/views/lisa/Index'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'logout',
                    path: '/logout',
                    component: () => import('@/views/lisa/Logout'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Orders',
                    path: 'orders',
                    component: () => import('@/views/lisa/Orders'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Receiving',
                    path: 'render',
                    component: () => import('@/views/lisa/Receiving'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Validation',
                    path: 'samples',
                    // component: () => import('@/views/lisa/Samplesnew'),
                    component: () => import('@/views/lisa/Samplesv2'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Patients',
                    path: 'patients',
                    // component: () => import('@/views/lisa/Patients'),
                    component: () => import('@/views/lisa/PatientsV2'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Statistics',
                    path: 'statistics',
                    component: () => import('@/views/lisa/Statistics'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'DOHStatistics',
                    path: 'doh-statistics',
                    component: () => import('@/views/lisa/Dohstatistics'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'SOA',
                    path: 'soa',
                    component: () => import('@/views/lisa/Soa'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'SOA - Results',
                    path: 'soa-results',
                    component: () => import('@/views/lisa/SoaResults'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'SOA - Deleted Chargeslips',
                    path: 'soa-deleted',
                    component: () => import('@/views/lisa/Soadeletedcs'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Instrument Transmissions',
                    path: 'transmissions',
                    component: () => import('@/views/lisa/InstrumentTransmissions'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Settings',
                    path: 'settings',
                    component: () => import('@/views/lisa/Settings'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Analytes',
                    path: 'analytes',
                    component: () => import('@/views/lisa/Analytes'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'User',
                    path: 'users',
                    component: () => import('@/views/lisa/Users'),
                    meta: {
                        requiresAuth: true
                    }
                }
            ]
        },
        // {
        //     path: "/terminal",
        //     component: () => import("@/views/terminal/Index"),
        //     children: [
        //         {
        //             name: "Terminal",
        //             path: "terminal",
        //             component: () => import("@/views/terminal/Wizard"),
        //             meta: {
        //                 requiresAuth: false
        //             }
        //         }
        //     ]
        // },
        {
            path: '/pages',
            component: () => import('@/views/pages/Index'),
            children: [
                {
                    name: 'Lock',
                    path: 'lock',
                    component: () => import('@/views/pages/Lock'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'Login',
                    path: 'login',
                    component: () => import('@/views/pages/Login'),
                    meta: {
                        requiresAuth: false
                    }
                },
                // {
                //     name: 'Terminal',
                //     path: 'terminal',
                //     component: () => import('@/views/pages/Terminal'),
                //     meta: {
                //         requiresAuth: false
                //     }
                // },
                {
                    name: 'Pricing',
                    path: 'pricing',
                    component: () => import('@/views/pages/Pricing')
                },
                {
                    name: 'Register',
                    path: 'register',
                    component: () => import('@/views/pages/Register')
                }
            ]
        },
        {
            path: '/',
            component: () => import('@/views/dashboard/Index'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard',
                    path: '',
                    component: () => import('@/views/dashboard/Dashboard'),
                    meta: {
                        requiresAuth: true
                    }
                },
                // Pages
                {
                    name: 'RTL',
                    path: 'pages/rtl',
                    component: () => import('@/views/dashboard/pages/Rtl')
                },
                {
                    name: 'User Profile',
                    path: 'pages/user',
                    component: () =>
                        import('@/views/dashboard/pages/UserProfile')
                },
                {
                    name: 'Timeline',
                    path: 'pages/timeline',
                    component: () => import('@/views/dashboard/pages/Timeline')
                },
                // Components
                {
                    name: 'Buttons',
                    path: 'components/buttons',
                    component: () =>
                        import('@/views/dashboard/component/Buttons')
                },
                {
                    name: 'Grid System',
                    path: 'components/grid-system',
                    component: () => import('@/views/dashboard/component/Grid')
                },
                {
                    name: 'Tabs',
                    path: 'components/tabs',
                    component: () => import('@/views/dashboard/component/Tabs')
                },
                {
                    name: 'Notifications',
                    path: 'components/notifications',
                    component: () =>
                        import('@/views/dashboard/component/Notifications')
                },
                {
                    name: 'Icons',
                    path: 'components/icons',
                    component: () => import('@/views/dashboard/component/Icons')
                },
                {
                    name: 'Typography',
                    path: 'components/typography',
                    component: () =>
                        import('@/views/dashboard/component/Typography')
                },
                // Forms
                {
                    name: 'Regular Forms',
                    path: 'forms/regular',
                    component: () =>
                        import('@/views/dashboard/forms/RegularForms')
                },
                {
                    name: 'Extended Forms',
                    path: 'forms/extended',
                    component: () =>
                        import('@/views/dashboard/forms/ExtendedForms')
                },
                {
                    name: 'Validation Forms',
                    path: 'forms/validation',
                    component: () =>
                        import('@/views/dashboard/forms/ValidationForms')
                },
                {
                    name: 'Wizard',
                    path: 'forms/wizard',
                    component: () => import('@/views/dashboard/forms/Wizard')
                },
                // Tables
                {
                    name: 'Regular Tables',
                    path: 'tables/regular-tables',
                    component: () =>
                        import('@/views/dashboard/tables/RegularTables')
                },
                {
                    name: 'Extended Tables',
                    path: 'tables/extended-tables',
                    component: () =>
                        import('@/views/dashboard/tables/ExtendedTables')
                },
                {
                    name: 'Data Tabels',
                    path: 'tables/data-tables',
                    component: () =>
                        import('@/views/dashboard/tables/DataTables')
                },
                // Maps
                {
                    name: 'Google Maps',
                    path: 'maps/google-maps',
                    component: () => import('@/views/dashboard/maps/GoogleMaps')
                },
                {
                    name: 'Full Screen Map',
                    path: 'maps/full-screen-map',
                    component: () =>
                        import('@/views/dashboard/maps/FullScreenMap')
                },
                // Root level
                {
                    name: 'Widgets',
                    path: 'widgets',
                    component: () => import('@/views/dashboard/Widgets')
                },
                {
                    name: 'Charts',
                    path: 'charts',
                    component: () => import('@/views/dashboard/Charts')
                },
                {
                    name: 'Calendar',
                    path: 'calendar',
                    component: () => import('@/views/dashboard/Calendar')
                }
            ]
        },
        {
            path: '*',
            component: () => import('@/views/pages/Index'),
            children: [
                {
                    name: '404 Error',
                    path: '',
                    component: () => import('@/views/pages/Error')
                }
            ]
        }
    ]
});
