<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap
    >
    	<ValidationObserver ref="observer">
        	<v-layout wrap slot-scope="{ invalid, validated }" v-if="expand">
                 
                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Priority" rules="required|numeric">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model.trim="item_.priority"
                      label="Priority"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="priority"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>
        	</v-layout>
      	</ValidationObserver>


     </v-layout>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  // Validator
} from "vee-validate";

import EventBus from '../../plugins/eventbus';

export default {
  $_veeValidate: {
    validator: "new"
  },

  props: {
      action: {
        type: String, 
        default() { return ''; },
      }
  },
  data() {
    return {
      item_: {
      },
      sex: [
        {
          id: 'A',
          name: 'ALL'
        },
        {
          id: 'M',
          name: 'Male'
        },
        {
          id: 'F',
          name: 'Female'
        }
      ],
      timeperiod: [
        {
          id: 'D',
          name: 'Days'
        },
        {
          id: 'M',
          name: 'Months'
        },
        {
          id: 'Y',
          name: 'Years'
        }
      ],
      type: [
        {
          id: 'RANGE',
          name: 'Range'
        },
        {
          id: 'SYMBOL',
          name: 'Symbol < / >'
        },
        {
          id: 'TEXT',
          name: 'Text'
        }
      ],
      symbols: [
        {
          id: '>',
          name: '>'
        },
        {
          id: '>=',
          name: '>='
        },
        {
          id: '<',
          name: '<'
        },
        {
          id: '<=',
          name: '<='
        }
      ],
      loading: false,
      dialog: false,
      options: { 
        actiontext: [''], 
        action: 'new' 
      },

    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    setSex(id) {
      this.item_.sex = id
    },
    setTimePeriodLabel(value) {
      switch(value) {
        case 'D':
          return 'DAYS'
          break;
        case 'M':
          return 'MONTHS'
          break;
        case 'Y':
          return 'YEARS'
          break;
      }
    },
    setInstrument(value) {
      var [instrument] = this.instruments.filter(el => el.id==value);
      return instrument.instrument
    },
    closeDialog() {
      // this.dialog = true
      this.expand = false
      // EventBus.$emit('CLEAR_REFRANGE');
      EventBus.$emit('CLOSE_ANALYTE_DIALOG');
      
    },
    async fetchItems() {
        this.itemdata = {}
        this.refrangeitems = {}
        await this.$store.dispatch('referenceranges/fetchItems', this.analyte_id)
          .then(res => {
              // console.log(res)
              this.items = res.data
              // this.refrangeitems = res.data
          })
          .catch(error => {
            var notif = { option: 'fail', msg: 'Error in getting ' + this.title + ' data.' }
            this.showNotification(notif)
            // console.log(error)
          });
    },
    async processItem() {

    	const isValid = await this.$refs.observer.validate()
      
      	if(isVal) {
        // this.item_.name = this.item
        // this.item_.name = ''
        this.item_.analyte_id = this.analyte_id
        this.item_.enable_2nd_range = this.range2
        this.item_.active = this.active

        this.loading = true

        var payload = { 
          action: this.options.action,
          item: this.item_,
          module: 'referenceranges'
        }

        this.$store.dispatch('referenceranges/processItem', payload)
            .then(res => {

              var notif = { option: 'success', msg: '', show: true }

              if(this.options.action == 'delete')
                notif = { option: 'success', msg: 'Delete Successful!', show: true }

              EventBus.$emit('SHOW_NOTIFICATION', notif)
              this.clear()
              this.fetchItems()
              
          })
          .catch(error => {
            var notif = { option: 'fail', msg: '' }
            if(error.response.status === 422) {
              var err = this.$store.getters['settings/getErrors']
              var errors = ''
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(key => errors = err.data.errors[key][0]);
              notif = { option: 'fail', msg: errors }
              EventBus.$emit('SHOW_NOTIFICATION', notif)
            } else {
              notif = { option: 'fail', msg: 'Server can not process the Item. (' + error.response.status + ')' }
              EventBus.$emit('SHOW_NOTIFICATION', notif)
            }
            this.loading = false
            // this.clear()
          }); 
        
      } else {
        // Throw Validation Error
      }
    },
    async setFocus(index) {
      if(index == 0)
        this.$nextTick(() => this.$refs.lowerlimit.focus())
      else if(index == 1)
        this.$nextTick(() => this.$refs.symbol.focus())
      else if(index == 2)
        this.$nextTick(() => this.$refs.textrange.focus())
      else if(index == 3)
        this.$nextTick(() => this.$refs.value.focus())
      else if(index == 4)
        this.$nextTick(() => this.$refs.secondrange.focus())
    },
    async init() {
      this.instruments = this.$store.getters['instruments/getItems']
      this.analyte_id = this.$store.getters['cache/getAnalyteID']
      this.item_.analyte_id = this.analyte_id
      this.refrangeheaders = this.$store.getters['referenceranges/getHeaders']

      if(!this.initialized) {
        this.module = 'referenceranges'
        await this.$store.dispatch('instruments/fetchItems')
          .then(res => {
            this.instruments = res.data
          })
          .catch(error => {
            var notif = { option: 'fail', msg: 'Error in getting Instruments data.' }
            this.showNotification(notif)
            // console.log(error)
          });
      }
      this.fetchItems()
      this.initialized = true
    },
    newRefRange() {
      this.expand = true
      this.clear(false)
      // alert('newRefRange')
      // EventBus.$emit('CLEAR_REFRANGE')
    },
    editItem(payload) {
      this.expand = true
      this.clear(false)
      this.item_ = payload
      
      // SET VALUES
      
      this.secondary = (this.item_.enable_2nd_range == '1') ? true : false;

      if(this.setSecondary)
        this.setSecondary(this.secondary)
      this.active = (this.item_.active == '1') ? true : false;
      this.setType(this.item_.type)

      this.options.action = 'edit'
      this.savebuttonlabel = 'UPDATE REFERENCE RANGE'
    },
    deleteItem(payload) {
      // this.itemdata.id = id
      // this.itemdata.name = name
      this.item_ = payload
      this.item_.name = 'REFERENCE RANGE'
      this.options.action = 'delete'
      EventBus.$emit('SHOW_DELETE_DIALOG', this.item_, this.module, true)
      this.clear
    },
    getButtonLabel() {
      if(!this.loading)
        return this.savebuttonlabel
      else
        return 'SAVING...'
    },
    async clear(toggle) {

      this.item_ = {}
      this.itemdata = {}
      // this.setType(undefined)
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });

    //   this.$nextTick(() => {
    //     this.errors.clear();
    //     this.$nextTick(() => {
    //         this.$validator.reset();
    //     });
    // });
      this.range = false
      this.symbol = false
      this.text = false
      this.loading = false
      this.savebuttonlabel = 'SAVE REFERENCE RANGE'
      this.range2 = false
      
      this.item_ = {}
      this.analyte_id = this.$store.getters['cache/getAnalyteID']
      this.item_.analyte_id = this.analyte_id
      this.item_.priority = '1'
      this.item_.enable_2nd_range = false
      this.item_.active = true
      this.secondary = false
      this.active = true
      this.options.action = 'new'
      
    },
    clearAndRefresh() {
      this.clear(false)
      this.init()
    },
    async setFocus(index) {
      if(index == 0)
        this.$nextTick(() => this.$refs.lowerlimit.focus())
      else if(index == 1)
        this.$nextTick(() => this.$refs.symbol.focus())
      else if(index == 2)
        this.$nextTick(() => this.$refs.textrange.focus())
      else if(index == 3)
        this.$nextTick(() => this.$refs.value.focus())
      else if(index == 4)
        this.$nextTick(() => this.$refs.secondrange.focus())
      else if(index == 5)
        this.$nextTick(() => this.$refs.priority.focus())
    }
  },
  computed: {
    
    // showTextfield() {
    //   if(this.options.action != 'delete')
    //     return true
    //   else
    //     return false
    // },
    // getIcon() {
    //   return this.options.icon
    // },
    // getAction() {
    //   return this.options.action
    // },
    // getItem() {
    //   return this.item_.name
    // },
    
  },
  mounted() {
    
    // EventBus.$on('CLEAR_REFRANGE', (payload) => {
    //   this.expand = false
    //   this.clear(false)
    // }),

    // EventBus.$on('LOAD_REFERENCE_RANGE', (payload) => {
    //   this.clear(false)
    //   this.init()
    //   // EventBus.$off
    // })
    

  },
  created() {
  }
}
</script>

<style>


.v-toolbar__content {
    margin-left: 0px !important;
  }

.v-datatable__actions {
  color: black;
}

.v-datatable__actions .v-btn {
  color: orange !important;
}
.v-messages__message {
  color: red !important;
}
</style>