<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout row wrap justify-center>
      <!-- <v-container py-0>
                <v-layout wrap>
                    <v-row>
      <v-col xs="12" md="12">-->
      <v-card class="hidden">
        <div id="printMe">
          <div class="fullReport">
            <div class="page-header" style="text-align: center" align="center">
              <div class="header">
                <table class="tableheader">
                  <tr>
                    <td class="bold">BRANCH HOSPITAL</td>
                  </tr>
                  <tr>
                    <td>ADDRESS</td>
                  </tr>
                  <tr>
                    <td>Telephone #</td>
                  </tr>
                </table>
              </div>
              <table class="patient-table" cellpadding="3">
                <thead>
                  <tr class="patientrow">
                    <td style="width: 100mm">
                      Patient Name:
                      <span class="bold">{{ PatientName }}</span>
                    </td>
                    <td style="width: 35mm">CHARGE SLIP #:</td>
                    <td style="width: 65mm">{{ ChargeSlip }}</td>
                  </tr>
                  <tr class="patientrow">
                    <td style="width: 135mm">
                      Birthdate:
                      {{ Birthdate }}
                    </td>
                    <td style="width: 35mm">DATE ORDERED:</td>
                    <td style="width: 65mm">{{ DateOrdered }}</td>
                  </tr>
                  <tr class="patientrow">
                    <td style="width: 100mm">
                      Age / Sex:
                      {{ Age }} /
                      {{ Gender }}
                    </td>
                    <!-- <td style="width: 65mm; background: red"> {{ PatientName }} </td> -->
                    <td style="width: 35mm">DATE PRINTED:</td>
                    <td style="width: 65mm">{{ DatePrinted }}</td>
                  </tr>
                </thead>
              </table>
            </div>

            <table>
              <thead>
                <tr>
                  <td>
                    <!--place holder for the fixed-position header-->
                    <div class="page-header-space"></div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <!--*** CONTENT GOES HERE ***-->
                    <div class="page" style="text-align: center">
                      <table class="items-table">
                        <thead>
                          <tr class="items-table-header">
                            <td style="width: 100mm">TEST NAME</td>
                            <td style="width: 35mm">SPECIMEN</td>
                            <td style="width: 35mm">SECTION</td>
                            <td style="width: 30mm" class="right">AMOUNT</td>
                          </tr>
                        </thead>
                        <tr v-for="item in data.charges" :key="item.id" class="left">
                          <td style="width: 100mm">{{ getItemName(item) }}</td>
                          <td style="width: 35mm">{{ item.specimen }}</td>
                          <td style="width: 35mm">{{ item.section }}</td>
                          <td style="width: 30mm" class="right">{{ transformAmount(item.amount) }}</td>
                        </tr>
                      </table>

                      <table class="total">
                        <tr class="right">
                          <td style="width: 100mm"></td>
                          <td style="width: 35mm"></td>
                          <td style="width: 35mm" class="right">TOTAL AMOUNT:</td>
                          <td style="width: 30mm" class="right">{{ Total }}</td>
                        </tr>
                      </table>
                    </div>
                    <!-- <div class="page">PAGE 2</div> -->
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <!--place holder for the fixed-position footer-->
                    <div class="page-footer-space"></div>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div class="page-footer">
              <table class="table-footer">
                <tr>
                  <td class="signatory_no_border">&nbsp;</td>
                  <td class="spacer"></td>
                  <td class="signatory">{{ User }}</td>
                </tr>

                <tr>
                  <td class="position">&nbsp;</td>
                  <td class="spacer"></td>
                  <td class="position">Prepared by:</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- OUTPUT -->
        <div style="justify-center">
          <v-card>
            <v-btn color="cyan lighten-2" @click="print()">
              PRINT
              <v-icon right>mdi-close-circle</v-icon>
            </v-btn>
          </v-card>
        </div>
      </v-card>
      <!-- </v-col>
                    </v-row>
                </v-layout>
      </v-container>-->
    </v-layout>
  </v-container>
</template>

<script>
import EventBus from '@/plugins/eventbus';
export default {
  props: {
    patientdata: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      output: null,
      data: {
        sample_data: {
          ordered_at: null,
        },
        charge_slip: {
          total_amount: 0,
          id: null,
        },
        user: {
          last_name: null,
          first_name: null,
        },
      },
      patient: {
        full_name: null,
        birthdata: null,
        age_simple: null,
      },
      loading: false,
      interval: null,
    };
  },
  methods: {
    getItemName(item) {
      // console.log(Object.keys(this.data).length)
      // if(!Object.keys(this.data).length)
      if (item.analyte_order_name) return item.analyte_order_name;
      else return item.analyte_group;
    },
    print() {
      // Pass the element id here
      // let patient_name = this.data.patient.last_name;
      // patient_name += '_' + this.data.patient.first_name;
      // patient_name += '_' + this.data.patient.middle_name;

      // let title = patient_name + '_' + this.data.charge_slip.id;
      let title = this.patient.full_name + '_' + this.data.charge_slip.id;
      // this.$htmlToPaperFull('printResult', title);
      this.$htmlToPaperFull('printMe', title, ['/styles/chargeslip.css']);
    },
    getData() {
      this.interval = setInterval(() => {
        // console.log(this.data.user.last_name);
        // console.log(1);
        if (this.loading == false) {
          // this.loading = true;

          if (this.data.user.last_name != undefined) {
            console.log(this.data.user.last_name);
            this.print();
            // console.log(2);
            clearInterval(this.interval);
          }
        }
      }, 300);
    },
    transformAmount(val) {
      return val.toLocaleString();
    },
  },
  computed: {
    PatientName() {
      return this.patient.full_name;
    },
    Birthdate() {
      return this.patient.birthdate;
    },
    Age() {
      return this.patient.age_simple;
    },
    Gender() {
      return this.patient.sex;
    },
    DateOrdered() {
      // console.log(Object.keys(this.data).length)
      // if(!Object.keys(this.data).length)
      return this.data.sample_data.ordered_at;
    },
    DatePrinted() {
      return this.data.sample_data.printed_at;
    },
    ChargeSlip() {
      return this.data.charge_slip.id;
    },
    Total() {
      return this.data.charge_slip.total_amount.toLocaleString();
    },
    User() {
      return this.data.user.first_name + ' ' + this.data.user.last_name;
    },
  },
  watch: {
    patientdata(val) {
      this.data = val;
      this.patient = this.data.patient;
      // console.log('this.data1', this.data);
      // if (this.data.user.last_name) {
      //     this.print();
      // }
    },
  },
  mounted() {
    this.data = this.patientdata;
    this.patient = this.data.patient;
    // this.data.user = this.patientdata.user;
    // console.log('this.data2', this.data.user);

    // if (this.data.user.last_name) {
    //     this.print();
    // }
    EventBus.$on('LOAD_CHARGESLIP', (payload) => {
      // console.log(Object.keys(payload).length)
      // console.log('LOAD_CHARGESLIP');
      this.getData();
    });
    this.getData();
  },
  created() {
    // this.patient = this.data.patient
    // console.log(this.data)
  },
  beforeDestroy() {
    EventBus.$off('LOAD_CHARGESLIP');
    // clearInterval(this.interval);
    // console.log('CS DESTROYED');
  },
};
</script>

<style>
/* .chargeslipcard2 {
    background: white !important;
}
.body2 {
    font-family: Arial !important;
} */

.hidden {
  display: none;
}
</style>
