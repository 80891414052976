<template>
  <div>
    <!-- <v-row>
      <v-col cols="6"> -->
    <ul id="pagelist">
      <li>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="btnColor"
              class="mx-2"
              fab
              small
              v-bind="attrs"
              v-on="on"
              :disabled="value == 1 || disableComponent"
              @click="previous()"
            >
              <v-icon> {{ prevIcon }} </v-icon>
            </v-btn>
          </template>
          <span>Previous</span>
        </v-tooltip>
      </li>
      <li class="mt-3">
        <span class="mx-2 page-title text-uppercase">Page</span>
      </li>
      <li class="mt-n2">
        <span>
          <v-text-field
            v-model.trim="page"
            :color="color"
            class="text-align-right page-text"
            @keyup.enter="handlePageChange()"
            :disabled="disableComponent"
          />
        </span>
      </li>
      <li class="mt-3">
        <span class="mx-2 page-title text-uppercase">of {{ length }}</span>
      </li>
      <li>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="btnColor"
              class="mx-2"
              fab
              small
              v-bind="attrs"
              v-on="on"
              :disabled="length <= 1 || value >= length || disableComponent"
              @click="next()"
            >
              <v-icon> {{ nextIcon }} </v-icon>
            </v-btn>
          </template>
          <span>Next</span>
        </v-tooltip>
      </li>
      <!-- <li>
            <button
              type="button"
              aria-label="Previous page"
              class="v-pagination__navigation v-pagination__navigation--disabled"
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate fa fa fa fa-chevron-left theme--light"
              ></i>
            </button>
          </li>
          <li>
            <button
              type="button"
              aria-current="true"
              aria-label="Current Page, Page 1"
              class="v-pagination__item v-pagination__item--active info"
            >
              1
            </button>
          </li>
          <li>
            <button
              type="button"
              aria-label="Next page"
              class="v-pagination__navigation v-pagination__navigation--disabled"
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate fa fa fa fa-chevron-right theme--light"
              ></i>
            </button>
          </li> -->
    </ul>
    <!-- </v-col>
    </v-row> -->
  </div>
</template>

<script>
import EventBus from '@/plugins/eventbus';
export default {
  name: 'Z1Pagination',

  props: {
    circle: Boolean,
    disabled: Boolean,
    length: {
      type: Number,
      default: 1,
    },
    nextIcon: {
      type: String,
      default: 'mdi-menu-right',
    },
    prevIcon: {
      type: String,
      default: 'mdi-menu-left',
    },
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'primary',
    },
    btnColor: {
      type: String,
      default: 'primary',
    },
    context: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      maxButtons: 0,
      selected: null,
      page: 0,
      oldQuery: undefined,
      disableComponent: false,
    };
  },

  methods: {
    init() {
      // this.selected = null;
      this.page = null;
      // this.$nextTick(this.onResize);
      // TODO: Change this (f75dee3a, cbdf7caa)
      setTimeout(() => (this.page = this.value), 100);
    },
    next() {
      this.$emit('input', this.value + 1);
      // this.$emit('next');
    },
    previous() {
      this.$emit('input', this.value - 1);
      // this.$emit('previous');
    },
    handlePageChange() {
      let notif = {
        option: 'success',
        msg: 'Page can not be higher than Total Number of Pages',
      };

      if (this.page > this.length) {
        EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);
        return;
      }
      // else if (this.page == 0) {
      //   notif.msg = 'Page can not be Zero (0)';
      //   EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);
      //   return;
      // }
      this.$emit('input', Number(this.page));
    },
  },

  watch: {
    value(value) {
      // this.init();

      this.page = value;
      this.oldQuery = this.query;
      // console.log('changed', this.page);
    },
    query(value) {
      // console.log('oldQuery', this.oldQuery);
      // console.log('query', this.query);
      if (this.oldQuery == value) {
        this.disableComponent = false;
        return;
      }
      this.query = value;
      this.oldQuery =
        this.oldQuery || this.query == '' ? this.oldQuery : this.query;
    },
    length() {
      this.oldQuery = this.query;
    },
    disabled(value) {
      this.disableComponent = value;
    },
  },

  mounted() {
    this.init();
  },
};
</script>


<style scoped>
::v-deep .page-title {
  font-size: 10pt;
}

::v-deep ul#pagelist li {
  display: inline !important;
  list-style-type: none !important;
  float: left;
}

.page-text {
  width: 25px;
}

/* .text-align-right >>> input
  text-align: right

.v-alert--material
  margin-top: 32px

.v-alert__icon
  top: -36px

.v-alert__dismissible
  align-self: flex-start
  margin: 0 !important
  padding: 0 !important */
</style>
