<template>
  <v-container fluid>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(validateForm)">
        <v-row>
          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="hl7code"
              v-slot="{ errors }"
              name="HL7 Code"
              rules="required|max:255"
            >
              <v-text-field
                v-model="item_.hl7_code"
                label="HL7 Code"
                class="orange-input"
                :error-messages="errors"
                required
                ref="hl7code"
              />
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="code"
              v-slot="{ errors }"
              name="Code"
              rules="required|max:255"
            >
              <v-text-field
                v-model="item_.code"
                label="Code"
                class="orange-input"
                :error-messages="errors"
                required
                ref="code"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col xs="12" md="6" mr-auto>
            <validation-provider
              vid="analyte"
              v-slot="{ errors }"
              name="Analyte"
              rules="required|max:255"
            >
              <v-text-field
                v-model="item_.analyte"
                label="Analyte"
                class="orange-input"
                :error-messages="errors"
                required
                ref="analyte"
              />
            </validation-provider>
          </v-col>

          <v-col xs="12" md="6" mx-2>
            <validation-provider
              vid="order_name"
              v-slot="{ errors }"
              name="Analyte Order Name"
              rules="required|max:255"
            >
              <v-text-field
                v-model="item_.analyte_order_name"
                label="Analyte Order Name"
                class="orange-input"
                :error-messages="errors"
                required
                ref="order_name"
              />
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="section"
              v-slot="{ errors }"
              name="Section"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.section_id"
                :items="sections"
                label="Section"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="section"
                @change="setSection"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="specimen"
              v-slot="{ errors }"
              name="Specimen"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.specimen_id"
                :items="specimens"
                label="Specimen"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="specimen"
                @change="setSpecimen"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="unit"
              v-slot="{ errors }"
              name="Unit"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.unit_id"
                :items="units"
                label="Unit"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="unit"
                @change="setUnit"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="analytegroup"
              v-slot="{ errors }"
              name="Analyte Group"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.analyte_group_id"
                :items="analytegroups"
                label="Analyte Group"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="analytegroup"
                @change="setAnalyteGroup"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="decimal"
              v-slot="{ errors }"
              name="Decimal"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.decimal"
                :items="decimals"
                label="Decimal"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="decimal"
                @change="setDecimal"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="operator"
              v-slot="{ errors }"
              name="Operator"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.operator"
                :items="operators"
                label="Operator"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="operator"
                @change="setOperator"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="factor"
              v-slot="{ errors }"
              name="Factor"
              rules="required|max:20"
            >
              <v-text-field
                v-model="item_.factor"
                label="Factor"
                class="orange-input"
                :error-messages="errors"
                required
                ref="factor"
              />
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="unit2"
              v-slot="{ errors }"
              name="Secondary Unit"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.unit_id2"
                :items="units"
                label="Secondary Unit"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="unit2"
                @change="setUnit2"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="decimal2"
              v-slot="{ errors }"
              name="Secondary Decimal"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.decimal2"
                :items="decimals"
                label="Secondary Decimal"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="decimal2"
                @change="setDecimal2"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="amount"
              v-slot="{ errors }"
              name="Amount"
              rules="required|max:20"
            >
              <v-text-field
                v-model="item_.amount"
                label="Amount"
                class="orange-input"
                :error-messages="errors"
                required
                ref="amount"
              />
            </validation-provider>
          </v-col>

          <v-col xs="12" md="3" mx-2>
            <validation-provider
              vid="sort"
              v-slot="{ errors }"
              name="Sort"
              rules="required|numeric"
            >
              <v-text-field
                v-model="item_.sort"
                label="Sort"
                class="orange-input"
                :error-messages="errors"
                required
                ref="sort"
              />
            </validation-provider>
          </v-col>

          <v-col xs="12" md="4" mx-2>
            <validation-provider
              vid="reporttemplate"
              v-slot="{ errors }"
              name="Report Template"
              rules="required|max:255"
            >
              <v-select
                v-model="item_.report_template_id"
                :items="reporttemplates"
                label="Report Template"
                :error-messages="errors"
                class="orange-input"
                item-text="name"
                item-value="id"
                color="orange"
                ref="reporttemplate"
                @change="setReportTemplate"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="12" mx-2>
            <validation-provider
              vid="calculated"
              v-slot="{ errors }"
              name="Calculated"
            >
              <v-checkbox
                v-model="item_.calculated"
                label="Calculated Test"
                color="orange"
              ></v-checkbox>
            </validation-provider>
          </v-col>

          <v-col xs="12" md="12" mx-2>
            <validation-provider vid="active" v-slot="{ errors }" name="Active">
              <v-switch
                v-model="item_.active"
                :label="switchlabel"
                @change="setSwitchLabel()"
                color="orange"
              ></v-switch>
            </validation-provider>
          </v-col>

          <v-col xs="12" text-xs-right>
            <!-- <span v-show="loading" wrap>
                  <v-progress-circular
                    indeterminate
                    color="orange"
                    :size="30"
                    :value="80"
                    v-show="loading"
            ></v-progress-circular> <h6> Saving ... </h6></span>-->
            <!-- <v-btn
                    class="mx-0 font-weight-light"
                    color="orange"
                    v-on:click="processItem()"
                    :disabled="loading"
                    :loading="loading"
            >-->
            <div align="right">
              <v-btn
                class="mx-1 font-weight-light"
                color="dark--grey"
                outlined
                v-on:click="clear(true)"
                >Close</v-btn
              >

              <v-btn
                class="mx-0 font-weight-light"
                color="orange"
                type="submit"
                :loading="loading"
                >Save Analyte</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
} from 'vee-validate';

import EventBus from '@/plugins/eventbus';
export default {
  props: {
    analyteData: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },

  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      expand: false,
      refrangeheaders: [],
      refrangeitems: [],
      decimals: [
        {
          id: 0,
          name: '0',
        },
        {
          id: 1,
          name: '1',
        },
        {
          id: 2,
          name: '2',
        },
        {
          id: 3,
          name: '3',
        },
      ],
      operators: [
        {
          id: '*',
          name: '( * ) Multiply',
        },
        {
          id: '/',
          name: '( / ) Divide',
        },
      ],
      sex: [
        {
          id: 'A',
          name: 'ALL',
        },
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Male',
        },
      ],
      sections: [],
      specimens: [],
      units: [],
      analytegroups: [],
      reporttemplates: [],
      tab: null,
      text: null,
      loading: false,
      gradients: 'to top right, #c5deea, #8abbd7',
      dialog: false,
      options: {
        actiontext: [''],
        action: 'new',
      },
      id: '',
      item: '',
      item_: {
        id: '',
        hl7_code: '',
        code: '',
        analyte: '',
        analyte_group_id: undefined,
        analyte_order_name: '',
        specimen: { id: 3 },
        analyte_group_id: null,
        specimen_id: null,
        unit_id: null,
        section_id: null,
        decimal: null,
        operator: '',
        factor: 1,
        unit_id2: null,
        decimal2: null,
        amount: null,
        sort: null,
        calculated: false,
        report_template_id: null,
        active: null,
        default: false,
      },
      item_default: {
        id: '',
        hl7_code: '',
        code: '',
        analyte: '',
        analyte_group_id: undefined,
        analyte_order_name: '',
        specimen: { id: 3 },
        analyte_group_id: null,
        specimen_id: null,
        unit_id: null,
        section_id: null,
        decimal: null,
        operator: '',
        factor: 1,
        unit_id2: null,
        decimal2: null,
        amount: null,
        sort: null,
        calculated: false,
        report_template_id: null,
        active: null,
        default: false,
      },
      itemdata: {},
      message: '',
      module: '',
      snackbar: false,
      closedialog: false,
      switchlabel: 'Inactive',
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    validateForm(scope) {
      this.processItem();
      // alert(2);
    },
    setAnalyteOrderName(val) {
      this.item_.order_name = val;
    },
    setAnalyteGroup(id) {
      this.item_.analyte_group_id = id;
    },
    setDefaultSpecimen(id) {
      this.item_.specimenid = id;
    },
    setSpecimen(id) {
      this.item_.specimen_id = id;
    },
    setUnit(id) {
      this.item_.unit_id = id;
    },
    setSection(id) {
      this.item_.section_id = id;
    },
    setDecimal(id) {
      this.item_.decimal = id;
    },
    setOperator(id) {
      this.item_.operator = id;
    },
    setUnit2(id) {
      this.item_.unit_id2 = id;
    },
    setDecimal2(id) {
      this.item_.decimal2 = id;
    },
    setReportTemplate(id) {
      this.item_.report_template_id = id;
    },
    setSex(id) {
      this.refrangeitems.sex = id;
    },
    closeDialog() {
      // this.dialog = true
      // this.clear()
      EventBus.$emit('CLOSE_ANALYTE_DIALOG');
    },
    async setOption(opt) {
      this.options = opt;
      return true;
    },
    setSwitchLabel() {
      if (
        this.item_.active === undefined ||
        this.item_.active === 'NO' ||
        this.item_.active == false
      ) {
        this.item_.active = false;
        this.switchlabel = 'Inactive';
      } else {
        this.item_.active = true;
        this.switchlabel = 'ACTIVE';
      }
    },
    setActionText(index) {
      return this.options.actiontext[index];
    },
    async processItem() {
      // alert(1);
      this.loading = true;
      var payload = {
        action: this.options.action,
        item: this.item_,
      };
      EventBus.$emit('PROCESS_ANALYTE', payload);
    },
    async clear(toggle) {
      requestAnimationFrame(() => {
        // this.$refs.observer.reset();
        this.$refs.form.reset();
      });
      this.loading = false;

      this.item = '';
      this.item_ = this.item_default;
      this.itemdata = this.item_default;

      if (this.options.action == 'new') {
        this.SetNewDefaults();
      }

      if (this.options.action != 'new' || toggle || this.closedialog) {
        this.closeDialog();
      }
    },
    async setFocus() {
      this.$nextTick(() => this.$refs.hl7code.focus());
    },

    // async setLabel() {
    //   console.log(this.item_.default)
    //   if (this.item_.default === undefined || this.item_.default === 'NO' || this.item_.default == false) {
    //     this.item_.default = false
    //     return 'Set Default'
    //   }
    //   else {
    //     return 'Default Selected'
    //   }
    // }
    SetNewDefaults() {
      //   this.item_.operator = '*';
      //   this.item_.factor = 1;
      //   this.item_.amount = 0;
      //   this.item_.sort = 1;
      //   this.item_.analyte_ group_id = 0;
      //   this.item_.calculated = false;
      //   this.switchlabel = 'ACTIVE';
      //   this.item_.active = true;
    },
    DisplayConsole() {
      console.log(this.sections);
    },
    setColor(value) {
      if (value === 'YES' || value === 1) {
        this.items.active = 'YES';
        return 'orange';
      } else {
        return '';
      }
    },
    setActiveLabel(value) {
      if (value === 'YES' || value === 1) {
        return 'YES';
      } else {
        return 'NO';
      }
    },
    async initialize() {
      // console.log('this.item_', this.item_);
      // return;
      let analyteSettings = this.$store.getters['cache/getAnalyteSettings'];

      //   let analyteSettings = this.analyteData;
      // option: option,
      //     itemdata: this.itemdata,
      //     module: this.module,
      //     sections: this.sections,
      //     specimens: this.specimens,
      //     units: this.units,
      //     analytegroups: this.analytegroups,
      //     reporttemplates: this.reporttemplates,
      //     instruments: this.instruments,
      console.log('analyteSettings.itemdata', analyteSettings.itemdata);
      console.log('this.analyteData', this.analyteData);
      //   this.item_ = analyteSettings.itemdata;
      this.item_ = this.analyteData;
      this.setOption(analyteSettings.option);
      this.module = analyteSettings.module;
      this.sections = analyteSettings.sections;
      this.specimens = analyteSettings.specimens;
      this.units = analyteSettings.units;
      this.analytegroups = analyteSettings.analytegroups;
      this.reporttemplates = analyteSettings.reporttemplates;
      this.setSwitchLabel();

      if (this.options.action == 'new') {
        this.SetNewDefaults();
      }

      EventBus.$emit(
        'SET_REFRANGE_OPTION',
        analyteSettings.option,
        analyteSettings.instruments
      );
    },
  },
  computed: {
    showTextfield() {
      if (this.options.action != 'delete') return true;
      else return false;
    },
    getIcon() {
      return this.options.icon;
    },
    getAction() {
      return this.options.action;
    },
    getItem() {
      return this.item_.name;
    },
  },
  watch: {
    // analyteData(old, newVal) {
    //   // return;
    //   // let analyteSettings = newVal;
    //   // // this.item_ = newVal;
    //   // this.item_ = analyteSettings.itemdata;
    //   // this.setOption(analyteSettings.option);
    //   // this.module = analyteSettings.module;
    //   // this.sections = analyteSettings.sections;
    //   // this.specimens = analyteSettings.specimens;
    //   // this.units = analyteSettings.units;
    //   // this.analytegroups = analyteSettings.analytegroups;
    //   // this.reporttemplates = analyteSettings.reporttemplates;
    //   // this.setSwitchLabel();
    //   // if (this.options.action == 'new') {
    //   //     this.SetNewDefaults();
    //   // }
    //   // EventBus.$emit(
    //   //     'SET_REFRANGE_OPTION',
    //   //     analyteSettings.option,
    //   //     analyteSettings.instruments
    //   // );
    // },
  },
  mounted() {
    console.log('Analyte Mounted');
    this.initialize();
    EventBus.$on(
      'SET_ANALYTE_DIALOG_OPTION',
      (
        payload,
        itemdata,
        module,
        sections,
        specimens,
        units,
        analytegroups,
        reporttemplates,
        instruments
      ) => {
        this.item_ = itemdata;
        this.setOption(payload);
        this.module = module;
        this.sections = sections;
        this.specimens = specimens;
        this.units = units;
        this.analytegroups = analytegroups;
        this.reporttemplates = reporttemplates;
        this.setSwitchLabel();

        // if (this.options.action == 'new')

        this.SetNewDefaults();
        this.initialize();
        // setAnalyteID

        EventBus.$emit('SET_REFRANGE_OPTION', payload, instruments);
        // if(this.options.action == 'edit') {
        //   this.refrangeheaders = this.$store.getters['referenceranges/getHeaders']
        // }
      }
    );
    EventBus.$on('CLEAR_DIALOG', (payload) => {
      this.clear();
      this.closedialog = payload;
    });
    EventBus.$on('PROCESS_CALL', (payload) => {
      EventBus.$emit('PROCESS_ITEM', payload);
    });
    this.setDefaultSpecimen(3);
  },
  beforeDestroy() {
    EventBus.$off('SET_ANALYTE_DIALOG_OPTION');
    EventBus.$off('CLEAR_DIALOG');
    EventBus.$off('PROCESS_CALL');
  },
  created() {
    // this.setDefaultSpecimen(3)
    // this.DisplayConsole();
  },
};
</script>

<style>
.v-toolbar__content {
  margin-left: 0px !important;
}

.v-datatable__actions {
  color: black;
}

.v-datatable__actions .v-btn {
  color: orange !important;
}
.v-messages__message {
  color: red !important;
}
</style>
