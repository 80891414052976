<template>
  <!-- color="#68b0ab" -->
  <base-lisa-material-card color="info" :title="title" icon="mdi-face">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(validateForm)">
        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="patient_id"
              v-slot="{ errors }"
              name="Patient ID"
              :rules="txtPatientID.rules"
            >
              <v-text-field
                v-model.trim="item_.patient_id"
                :disabled="txtPatientID.disabled"
                label="Patient ID"
                :error-messages="errors"
                ref="patient_id"
                :color="color"
                @blur="checkPatientID()"
                :loading="patientIDLoading"
                prepend-icon="mdi-id-card"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="8">
            <v-scroll-x-transition>
              <div v-if="showpatientIDMsg">
                <div class="mt-5 ml-n5" v-if="patientIDFound">
                  <v-icon color="error" class="mb-1">mdi-close-thick</v-icon>
                  <span class="mb-n1 red--text text-subtitle-2">{{
                    patientIDMsg
                  }}</span>
                </div>

                <div class="mt-5 ml-n5" v-else>
                  <v-icon color="success" class="mb-1">mdi-check-bold</v-icon>
                  <span
                    class="mb-n1 text-success text-subtitle-2"
                    style="color: #388e3c"
                    >{{ patientIDMsg }}</span
                  >
                </div>
              </div>
            </v-scroll-x-transition>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="employee_id"
              v-slot="{ errors }"
              name="Employee ID"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="item_.employee_id"
                label="Employee ID"
                class="color"
                :error-messages="errors"
                ref="employee_id"
                :color="color"
                prepend-icon="mdi-id-card"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="senior_id"
              v-slot="{ errors }"
              name="Senior ID"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="item_.senior_id"
                label="Senior ID"
                class="color"
                :error-messages="errors"
                ref="senior_id"
                :color="color"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="pwd_id"
              v-slot="{ errors }"
              name="PWD ID"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="item_.pwd_id"
                label="PWD ID"
                class="color"
                :error-messages="errors"
                ref="pwd_id"
                :color="color"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="last_name"
              v-slot="{ errors }"
              name="Last Name"
              rules="required|max:255"
            >
              <v-text-field
                v-model.trim="item_.last_name"
                label="Last Name"
                class="color"
                :error-messages="errors"
                required
                ref="last_name"
                :color="color"
                prepend-icon="mdi-face-profile"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="first_name"
              v-slot="{ errors }"
              name="First Name"
              rules="required|max:255"
            >
              <v-text-field
                v-model.trim="item_.first_name"
                label="First Name"
                class="color"
                :error-messages="errors"
                required
                ref="first_name"
                :color="color"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="middle_name"
              v-slot="{ errors }"
              name="Middle Name"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="item_.middle_name"
                label="Middle Name"
                class="color"
                :error-messages="errors"
                ref="middle_name"
                :color="color"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="sex"
              v-slot="{ errors }"
              name="Gender"
              rules="required"
            >
              <v-select
                v-model.trim.trim="item_.sex"
                :items="sex"
                label="Sex"
                :error-messages="errors"
                class="color"
                item-text="name"
                item-value="id"
                :color="color"
                ref="sex"
                @change="setSex"
                prepend-icon="mdi-gender-male-female"
                item-color="deep-purple accent-4"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="250"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  vid="dob"
                  v-slot="{ errors }"
                  name="Date of Birth"
                  rules="required"
                >
                  <v-text-field
                    v-model="item_.dob"
                    label="Date of Birth (YYYY-MM-DD)"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    color="purple"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                ref="picker"
                v-model.trim="date"
                @input="menu = false"
                @change="save"
                color="purple"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>

            <!-- <v-menu
              ref="menu"
              v-model.trim="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <validation-provider
                  vid="dob"
                  v-slot="{ errors }"
                  name="Date of Birth"
                  rules="required"
                >
                  <v-text-field
                    v-model.trim="item_.dob"
                    label="Date of Birth (YYYY-MM-DD)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :error-messages="errors"
                    :color="color"
                  ></v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                ref="picker"
                v-model.trim="date"
                :max="new Date().toISOString().substr(0, 10)"
                @change="save"
                :color="color"
                dark
              ></v-date-picker>
            </v-menu>-->
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="mobile_no"
              v-slot="{ errors }"
              name="Mobile Number"
              rules="numeric|min:11|max:11"
            >
              <v-text-field
                v-model.trim="item_.mobile_no"
                label="Mobile No."
                class="color"
                :error-messages="errors"
                ref="mobile_no"
                :color="color"
                prepend-icon="mdi-cellphone-iphone"
                placeholder=" 11-Digits Mobile No. (091********)"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              vid="contact_no"
              v-slot="{ errors }"
              name="Contact Number"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="item_.contact_no"
                label="Contact Number"
                class="color"
                :error-messages="errors"
                ref="contact_no"
                :color="color"
                prepend-icon="mdi-phone"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              vid="email"
              v-slot="{ errors }"
              name="Email Address"
              rules="email|max:255"
            >
              <v-text-field
                v-model.trim="item_.email"
                label="Email Address"
                class="color"
                :error-messages="errors"
                ref="email"
                :color="color"
                prepend-icon="mdi-email"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <!--  :color="color"  -->
          <v-col cols="12" md="8">
            <validation-provider
              vid="street_no"
              v-slot="{ errors }"
              name="Street Number"
              rules="max:255"
            >
              <v-text-field
                v-model.trim="item_.street_no"
                label="Street Number / House Number / Building / Floor "
                class="color"
                :error-messages="errors"
                ref="street_no"
                :color="color"
                prepend-icon="mdi-home"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="5">
            <v-autocomplete
              v-model="item_.street_id"
              :items="street"
              :loading="autoCompleteStreet.loading"
              :search-input.sync="autoCompleteStreet.search"
              color="grey darken-1"
              item-text="street"
              item-value="id"
              label="Street"
              placeholder="Search Street..."
              return-object
              @change="setStreet"
              hide-details
              prepend-icon="mdi-home-circle"
              item-color="deep-purple accent-4"
              class="mb-6"
            >
              <!-- <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                > -->
              <!-- <v-icon left>fa-user-circle-o</v-icon> -->
              <!-- <span v-text="item.street"></span>
                </v-chip>
              </template> -->
            </v-autocomplete>
          </v-col>

          <v-col md="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-n5 mt-4"
                  text
                  icon
                  color="red lighten-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="20">mdi-map-marker-plus</v-icon>
                </v-btn>
              </template>
              <span>Add / Edit Street</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" md="5">
            <v-autocomplete
              v-model="item_.barangay_id"
              :items="barangay"
              :loading="autoCompleteBarangay.loading"
              :search-input.sync="autoCompleteBarangay.search"
              color="grey darken-1"
              item-text="barangay"
              item-value="id"
              label="Barangay"
              placeholder="Search Barangay..."
              return-object
              @change="setBarangay"
              hide-details
              prepend-icon="mdi-home-circle"
              item-color="deep-purple accent-4"
              class="mb-6"
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <!-- <v-icon left>fa-user-circle-o</v-icon> -->
                  <span v-text="item.barangay"></span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-n5 mt-4"
                  text
                  icon
                  color="red lighten-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <!-- mdi-map-marker-plus mdi-home-plus-->
                  <v-icon size="20">mdi-map-marker-plus</v-icon>
                </v-btn>
              </template>
              <span>Add / Edit Barangay</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-select
              :items="city"
              label="City"
              color="deep-purple accent-4"
              item-text="city"
              item-value="id"
              v-model="item_.city_id"
              item-color="deep-purple accent-4"
              prepend-icon="mdi-city"
            ></v-select>
          </v-col>

          <v-col cols="12" md="8">
            <validation-provider
              vid="address"
              v-slot="{ errors }"
              name="Address"
            >
              <v-text-field
                v-model.trim="item_.address"
                label="Address"
                class="color"
                :error-messages="errors"
                ref="address"
                :color="color"
                prepend-icon="mdi-home"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <v-expand-transition>
              <div v-if="showNotes">
                <validation-provider
                  vid="notes"
                  v-slot="{ errors }"
                  name="Notes"
                >
                  <v-textarea
                    v-model.trim="item_.notes"
                    label="Notes"
                    class="color"
                    :error-messages="errors"
                    ref="notes"
                    :color="color"
                    prepend-icon="mdi-note"
                  />
                </validation-provider>
              </div>
            </v-expand-transition>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" text-xs-right>
            <v-btn
              class="mx-0 font-weight-light"
              color="info"
              type="submit"
              :disabled="btnSave.disabled"
              :loading="btnSave.loading"
            >
              {{ buttonLabel }}
              <v-icon right>mdi-account-check</v-icon>
            </v-btn>

            <!-- <v-btn
                    color="grey darken-2"
                    @click="clear(); patients = true"
                  >
                    New Patient
                    <v-icon right>mdi-account-plus</v-icon>
            </v-btn>-->

            <v-btn color="dark--grey" outlined @click="addNotes()">
              Add Notes
              <v-icon right>mdi-file-document-edit</v-icon>
            </v-btn>
            <v-btn color="dark--grey" outlined @click="close()">
              Close
              <v-icon right>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </base-lisa-material-card>
  <!-- </v-layout>
  </v-container>-->
</template>

<script>
// import {
//   ValidationObserver,
//   ValidationProvider,
//   withValidation,
//   // Validator
// } from "vee-validate";

import EventBus from '@/plugins/eventbus';
import {
  showNotification,
  closePatientDialog,
} from '@/store/modules/proc/notification';

export default {
  props: {
    action: {
      type: String, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return '';
      },
    },
    module: {
      type: String, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return '';
      },
    },
    patientData: {
      type: Object,
      defaault() {
        return {};
      },
    },
  },

  $_veeValidate: {
    validator: 'new',
  },

  data() {
    return {
      txtPatientID: {
        disabled: undefined,
        rules: undefined,
      },
      street: [
        {
          id: 1,
          street: 'Street 1',
        },
        {
          id: 2,
          street: 'Street 2',
        },
      ],
      barangay: [
        {
          id: 1,
          barangay: 'Barangay 1',
        },
        {
          id: 2,
          barangay: 'Barangay 2 Barangay 2 ',
        },
      ],
      city: [
        {
          id: 1,
          city: 'Makati City',
        },
      ],
      autoCompleteStreet: {
        search: undefined,
        loading: undefined,
      },
      autoCompleteBarangay: {
        search: undefined,
        loading: undefined,
      },
      showNotes: undefined,
      search: undefined,
      model: undefined,
      showpatientIDMsg: undefined,
      patientIDFound: undefined,
      patientIDMsg: undefined,
      patientIDLoading: undefined,
      btnSave: {
        loading: undefined,
        disabled: undefined,
      },
      color: '#52575d',
      item_: {
        city_id: 1,
      },
      options: {},
      menu: false,
      date: null,
      loading: false,
      // validator: null,
      // buttonLabel: 'Save Patient',
      sex: [
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Female',
        },
      ],
      errors: {},
    };
  },
  // components: {
  //   ValidationProvider,
  //   ValidationObserver
  // },
  methods: {
    setStreet(val) {
      this.item_.street_id = val.id;
    },
    setBarangay(val) {
      this.item_.barangay_id = val.id;
    },
    setSex(id) {
      this.item_.sex = id;
    },
    save(date) {
      this.$refs.menu.save(date);
      this.item_.dob = date;
    },
    validateForm(scope) {
      this.processItem();
    },
    async processItem() {
      var notif = { option: 'success', msg: '', show: true };

      this.loading = true;
      this.item_.action = this.options.action;
      var payload = {
        action: this.options.action,
        item: this.item_,
        module: null,
      };
      this.$store
        .dispatch('patients/processItem', payload)
        .then((res) => {
          this.clear();
          // console.log('this.module', this.module);

          switch (this.options.action) {
            case 'new':
              showNotification(notif);
              break;
            case 'edit':
              this.clear();
              notif = {
                option: 'success',
                msg: 'Patient Successfully Updated',
              };
              EventBus.$emit('SHOW_NOTIFICATION_TOP_SNACKBAR', notif);
              closePatientDialog(payload);
              break;
          }

          if (this.module == 'orders') {
            var payload = { show: true, data: res.data };
            this.clear();
            closePatientDialog(payload);
          }
        })
        .catch((err) => {
          console.log('SAVING PATIENT ERROR', err.response);
          const the_errors = err.response.data.errors;

          // SHOW ERROR IN FIELDS
          if (err.response.data.errors) {
            this.$refs.form.setErrors(err.response.data.errors);
          }

          notif = {
            option: 'error',
            response_status: err.response.status,
            msg: err.response.data.message,
            action: 'save',
            error: err,
          };

          showNotification(notif);
        })
        .finally((this.loading = false));
    },
    async checkPatientID() {
      console.log(this.item_.patient_id);

      if (
        this.item_.patient_id == undefined ||
        this.item_.patient_id.length == 0
      ) {
        this.showpatientIDMsg = false;
        return;
      }

      this.loading = true;
      this.patientIDLoading = true;

      let id = this.options.action == 'edit' ? this.item_.id : null;

      let payload = {
        action: this.options.action,
        id: id,
        patient_id: this.item_.patient_id,
      };

      this.$store
        .dispatch('patients/checkPatientID', payload)
        .then((res) => {
          this.showpatientIDMsg = true;
          this.patientIDFound = res.data.found;
          this.patientIDMsg = res.data.message;

          this.btnSave.disabled = this.patientIDFound ? true : false;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
          this.patientIDLoading = false;
        });
    },
    addNotes() {
      this.showNotes = !this.showNotes;
    },
    async clear(toggle) {
      this.item_ = {};
      this.showpatientIDMsg = false;
      this.item_.city_id = 1;
      this.showNotes = false;
      requestAnimationFrame(() => {
        this.$refs.form.reset();
      });
    },
    async close() {
      this.clear();
      // EventBus.$emit("CLOSE_DIALOG", {});
      closePatientDialog({});
    },
    init() {
      this.options.action = this.action;
      this.$nextTick(function () {
        switch (this.options.action) {
          case 'new':
            this.clear();
            this.txtPatientID.disabled = undefined;
            this.txtPatientID.rules = 'max:255';

            break;
          case 'edit':
            this.txtPatientID.disabled = undefined;
            this.txtPatientID.rules = '';
            this.item_ = this.patientData;

            break;
        }

        this.app_settings = JSON.parse(localStorage.getItem('app_settings'));
        this.barangay = this.app_settings.barangays;
        // if (this.app_settings) {
        //     this.branches = [];
        //     this.branches = this.app_settings.branches;

        // }
      });
    },
  },

  // mounted() {

  // },

  beforeDestroy() {
    EventBus.$off('UPDATE_PATIENT_DATA');
  },

  created() {
    this.init();
    EventBus.$on('UPDATE_PATIENT_DATA', (payload) => {
      this.init();
    });
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  computed: {
    buttonLabel() {
      if (this.module == 'orders') {
        return 'Save Patient and Use';
      } else {
        return 'Save Patient';
      }
    },
    title() {
      if (this.action == 'new') {
        return 'New Patient';
      } else {
        return 'Edit Patient';
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-chip__content {
  font-size: 0.8rem;
}
.v-label {
  color: #ff9800 !important;
}
</style>
