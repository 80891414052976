// https://vuex.vuejs.org/en/modules.html
import createPersistedState from 'vuex-persistedstate';

const requireModule = require.context('.', true, /\.js$/);
const modules = {};

requireModule.keys().forEach(fileName => {
    if (fileName === './index.js') return;
    // console.log(fileName);
    // Replace ./ and .js
    const path = fileName.replace(/(\.\/|\.js)/g, '');

    const [moduleName, imported] = path.split('/');

    if (!modules[moduleName]) {
        // console.log(moduleName)
        modules[moduleName] = {
            namespaced: true
        };
    }

    modules[moduleName][imported] = requireModule(fileName).default;
});

////////////////////

// import camelCase from "lodash/camelCase";
//   const requireModule = require.context(".", false, /\.js$/); //extract js files inside modules folder
//   const modules = {};

//   // requireModule = require.context(".", false, /\.js$/); //extract js files inside modules folder

//   requireModule.keys().forEach(fileName => {
//     if (fileName === "./index.js") return; //reject the index.js file

//     const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, "")); //

//     modules[moduleName] = requireModule(fileName).default;
//     console.log(modules)
//   });

export default modules;
