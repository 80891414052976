export default {
    SAVE_ITEMS(state, payload) {
        state.items = payload;
    },
    UPDATE_STATISTICS_DATA(state, payload) {
        state.items = payload;
    },
    //
    UPDATE_REFRESH(state, payload) {
        state.refresh = payload;
    }
};
