export default {
    api: '/api/validation',
    items: [],
    buttontip: 'New Sample',
    title: 'Patients',
    text: 'Laboratory Samples',
    options: [
        {
            action: 'new',
            label: 'New Sample',
            actiontext: ['Enter New Sample'],
            icon: 'mdi-new-box'
        },
        {
            action: 'edit',
            label: 'Edit Sample',
            actiontext: ['Edit Sample'],
            icon: 'mdi-pencil'
        },
        {
            action: 'delete',
            label: 'Delete Sample',
            actiontext: 'Delete Sample',
            icon: 'mdi-delete'
        }
    ],
    headers: [
        {
            sortable: false,
            text: 'ID',
            value: 'id'
        },
        {
            sortable: false,
            text: 'Analyte',
            value: 'analyte',
            align: 'right'
        },
        {
            sortable: false,
            text: 'Result',
            value: 'result',
            align: 'center'
        },
        {
            sortable: false,
            text: 'Previous Results',
            value: 'previousresults',
            align: 'center'
        },
        {
            sortable: false,
            text: 'Reference Range',
            value: 'referencerange',
            align: 'center'
        },
        {
            sortable: false,
            text: 'Flags',
            value: 'flags',
            align: 'center'
        },
        {
            sortable: false,
            text: '2nd Result',
            value: 'result2',
            align: 'center'
        },
        // {
        //     sortable: false,
        //     text: 'Instrument Flags',
        //     value: 'instrumentflags',
        //     align: 'center'
        // },
        {
            sortable: false,
            text: 'Unit',
            value: 'unit',
            align: 'center'
        },
        // {
        //     sortable: false,
        //     text: 'Previous Results',
        //     value: 'previousresults',
        //     align: 'center'
        // },
        {
            sortable: false,
            text: 'Status',
            value: 'status',
            align: 'center'
        },
        {
            sortable: false,
            text: 'User',
            value: 'user',
            align: 'center'
        },
        {
            sortable: false,
            text: 'Actions',
            value: 'actions',
            align: 'right'
        }
    ]
};
