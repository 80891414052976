<template>
    <v-container>
        <v-text-field
            v-model.trim="formula"
            label="Formula"
            class="color"
            required
            ref="formula"
            color="info"
        />
        <v-btn color="info" @click="checkFormula()">
            Check
            <v-icon right>mdi-check</v-icon>
        </v-btn>
    </v-container>
</template>

<script>
import _eval from '@/store/modules/proc/jsformulacalculator';
export default {
    name: 'Formula',
    data: () => ({
        formula: undefined,
        form: {
            username: '',
            password: ''
        },
        error: null,
        snackbar: null
    }),
    methods: {
        checkFormula() {
            let x = undefined;
            let valid = true;
            var formula = this.formula;
            try {
                var regExp = /\{([^}]+)\}/;
                var matches = true;

                // var replaced = undefined;
                while (matches != null) {
                    matches = regExp.exec(formula);
                    // console.log(true);
                    if (matches != null) {
                        let replaced = formula.replace(matches[0], 1);
                        formula = replaced;
                    }

                    // console.log('matches[0]', matches[0]);
                    // console.log('matches[1]', matches[1]);
                    // console.log('formula', formula);
                }
                //matches[1] contains the value between the parentheses
            } catch (ex) {
                valid = false;
                console.log('error', ex);
            }

            var e = undefined;
            if (valid) {
                try {
                    var e = eval(formula);
                } catch (ex) {
                    valid = false;
                }
            }
            console.log('e', e);

            if (!valid) alert('INVALID FORMULA');
            // console.log(x);
        }
    },
    created() {
        this.formula = '{TEST} + 1';
    },
    computed: {}
};
</script>
