<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
    @click="action(item.action)"
  >
    <v-list-item-icon
      v-if="text"
      class="v-list-item__icon--text"
      v-text="computedText"
    />

    <v-list-item-icon v-else-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title v-text="item.title" />

      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import EventBus from '@/plugins/eventbus';
import Themeable from 'vuetify/lib/mixins/themeable';
import { logout } from '@/store/modules/proc/auth';
export default {
  name: 'Item',

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
        action: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userSettings: undefined,
    userData: undefined,
    userAction: undefined,
  }),
  methods: {
    action(action) {
      // console.log(action);
      if (action === 'logout') {
        logout()
          .then((res) => {
            this.$store.dispatch('usermod/logout');
            this.$router.push({ name: 'Login' });
          })
          .catch((error) => {
            //   console.log(error);
          });
      }

      if (action === 'settings') {
        this.userSettings = this.$store.getters['settings/getUserSettings'];
        // let user = this.$store.getters['users/getUserSettings'];
        // console.log('this.userSettings', this.userSettings);
        let id = this.userSettings.user.id;
        // let id = user.id;

        this.$store
          .dispatch('users/fetchItem', id)
          .then((res) => {
            if (res) {
              //   console.log('user', res);
              EventBus.$emit('SHOW_USER_SETTINGS', res);

              //   console.log('this.userSettings', this.userSettings);
            }
          })
          .catch((error) => {
            console.log('error', error);
            // notif = {
            //   option: 'error',
            //   msg:
            //     error.response.data.message +
            //     ' (' +
            //     error.response.status +
            //     ')',
            //   error: error,
            // };
            // EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
          })
          .finally(() => {
            // this.loading = false;
          });

        // EventBus.$emit('UPDATE_USER_DATA', item);
      }
    },
    none() {
      return;
    },
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) return '';

      let text = '';

      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? '#' : undefined);
    },
  },
};
</script>
