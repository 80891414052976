<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12>
        <material-card
          :color="color"
          title="Patient"
          text="Complete Patient Details"
        >
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateForm)">
              <v-flex xs12 md4>
                <validation-provider
                  v-slot="{ errors }"
                  name="Patient ID"
                  rules="required|max:255"
                >
                  <v-text-field
                    v-slot="{
                      errors,
                    }"
                    v-model="item_.patient_id"
                    label="Patient ID"
                    class="color"
                    :error-messages="errors"
                    ref="patient_id"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12 md8> </v-flex>
              <v-flex xs12 md4>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:255"
                  name="email"
                >
                  <v-text-field
                    v-slot="{
                      errors,
                    }"
                    v-model="item_.last_name"
                    label="Last Name"
                    class="color"
                    :error-messages="errors"
                    required
                    ref="last_name"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12 md4>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:255"
                  name="first_name"
                >
                  <v-text-field
                    v-slot="{
                      errors,
                    }"
                    v-model="item_.first_name"
                    label="First Name"
                    class="color"
                    :error-messages="errors"
                    required
                    ref="first_name"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12 md4>
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:255"
                  name="middle_name"
                >
                  <v-text-field
                    v-slot="{
                      errors,
                    }"
                    v-model="item_.middle_name"
                    label="Middle Name"
                    class="color"
                    :error-messages="errors"
                    ref="middle_name"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12 md4>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="gender"
                >
                  <v-select
                    v-slot="{
                      errors,
                    }"
                    v-model.trim="item_.sex"
                    :items="sex"
                    label="Sex"
                    :error-messages="errors"
                    class="color"
                    item-text="name"
                    item-value="id"
                    :color="color"
                    ref="sex"
                    @change="setSex"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12 md4>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="dob"
                    >
                      <v-text-field
                        v-slot="{ errors }"
                        v-model="item_.dob"
                        label="Date of Birth"
                        prepend-inner-icon="mdi-map-marker"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save"
                    :color="color"
                    dark
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md4> </v-flex>
              <v-flex xs12 md12>
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:255"
                  name="address"
                >
                  <v-text-field
                    v-slot="{
                      errors,
                    }"
                    v-model="item_.address"
                    label="Address"
                    class="color"
                    :error-messages="errors"
                    ref="address"
                  />
                </validation-provider>
              </v-flex>
              <v-flex xs12 md12>
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:255"
                  name="remarks"
                >
                  <v-textarea
                    v-slot="{
                      errors,
                    }"
                    v-model="item_.remarks"
                    label="Remarks"
                    class="color"
                    :error-messages="errors"
                    ref="remarks"
                  />
                </validation-provider>
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  :color="color"
                  v-on:click="processItem"
                  :disabled="loading"
                  :loading="loading"
                >
                  Save Patient
                </v-btn>
              </v-flex>
              <!-- </v-layout> -->

              <!-- </v-layout>
            </ValidationObserver> -->
            </form>
          </validation-observer>

          <!-- </v-form> -->
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import {
//   ValidationObserver,
//   ValidationProvider,
//   withValidation,
//   // Validator
// } from "vee-validate";

import EventBus from '@/plugins/eventbus';

export default {
  //
  $_veeValidate: {
    validator: 'new',
  },

  data() {
    return {
      // errors: "errortest",
      color: 'success',
      item_: {},
      options: {},
      menu: false,
      date: null,
      loading: false,
      // validator: null,
      sex: [
        {
          id: 'M',
          name: 'Male',
        },
        {
          id: 'F',
          name: 'Female',
        },
      ],
    };
  },
  // components: {
  //   ValidationProvider,
  //   ValidationObserver
  // },
  methods: {
    setSex(id) {
      this.item_.sex = id;
    },
    save(date) {
      this.$refs.menu.save(date);
      this.item_.dob = date;
    },
    validateForm(scope) {
      alert('Form Submitted!');
    },
    async processItem() {
      const isValid = await this.$refs.observer.validate();
      var notif = {};
      if (isValid) {
        this.loading = true;

        var payload = {
          action: this.options.action,
          item: this.item_,
          module: null,
        };

        this.$store
          .dispatch('patients/processItem', payload)
          .then((res) => {
            notif = { option: 'success', msg: '', show: true };

            if (this.options.action == 'delete')
              notif = {
                option: 'success',
                msg: 'Delete Successful!',
                show: true,
              };

            EventBus.$emit('SHOW_NOTIFICATION', notif);

            // this.clear()
            // this.fetchItems()
          })
          .catch((error) => {
            notif = { option: 'fail', msg: '' };
            this.loading = false;
            if (error.response.status === 422) {
              var err = this.$store.getters['settings/getErrors'];
              var errors = '';
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(
                (key) => (errors = err.data.errors[key][0])
              );
              notif = { option: 'fail', msg: errors };
              EventBus.$emit('SHOW_NOTIFICATION', notif);
            } else {
              notif = {
                option: 'fail',
                msg:
                  'Server can not process the Item. (' +
                  error.response.status +
                  ')',
              };
              EventBus.$emit('SHOW_NOTIFICATION', notif);
            }
          });
      } else {
        console.log(this.item_.dob);
        // const ValidDob = await this.validator
        //     .validateAll({
        //         dob: this.item_.dob
        //     })
        //     .then(result => {
        //         if (result) return true;
        //         else return false;
        //     });
        // if (!ValidDob)
        //     notif = {
        //         option: "fail",
        //         msg: "Date of Birth is Required",
        //         show: true
        //     };
        // else
        //     notif = {
        //         option: "fail",
        //         msg: "Please fill in all required fields",
        //         show: true
        //     };

        EventBus.$emit('SHOW_NOTIFICATION', notif);
      }
    },
    async clear(toggle) {
      this.item_ = {};
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
  },
  created() {
    // this.validator = new Validator({
    //     dob: "required"
    // });
    console.log('TEST PATIENTS.VUE');
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
};
</script>
