export default {
    SAVE_ERRORS(state, payload) {
        state.errors = payload;
    },
    SAVE_SETTINGS(state, payload) {
        // const user_settings = { 'user_settings': payload }

        const user_settings = payload;
        localStorage.setItem('lisa', JSON.stringify(user_settings));
        state.usersettings = payload;
        // console.log('SAVE_SETTINGS-SETTINGS', payload)
    },
    SAVE_LOGGED_IN(state, payload) { }
};
