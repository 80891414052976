export default {
    getTerminalID: state => {
        return state.terminal_id;
    },
    getAnalyteID: state => {
        return state.analyte_id;
    },
    getAnalyteSettings: state => {
        return state.analyteSettings;
    },
    getAnalytes: state => {
        return state.analytes;
    },
    getAppSettings: state => {
        // return state.app_settings;
        const app_settings = JSON.parse(localStorage.getItem('app_settings'));
        return app_settings;
    },
    // getReferenceRanges: state => {
    //     return state.reference_ranges;
    // },
    // getSelectedAnalytes: state => {
    //     return state.selected_analytes;
    // },
    getResultsData: state => {
        return state.results_data;
    },
    // getTitle: state => {
    //     return state.title;
    // },
    // getText: state => {
    //     return state.text;
    // },
    // getButtonTip: state => {
    //     return state.buttontip;
    // },
    // getItems: state => {
    //     return state.items;
    // },
    // getNewOption: state => {
    //     return state.optnew;
    // },
    // getEditOption: state => {
    //     return state.optedit;
    // },
    // getOptions: state => {
    //     return state.options;
    // },
    // getHeaders: state => {
    //     return state.headers;
    // },
    refresh: state => {
        return state.refresh;
    }
};
