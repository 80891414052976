
export default {
	api: '/api/instruments',
	items: [],
	buttontip: 'New Instrument',
	title: 'Instruments',
	text: 'Laboratory Analyzers',
	options: [
		{
			action: 'new',
			label: 'New Instrument',
			actiontext: ['Enter New Instrument', 'Enter New Instrument'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Instrument',
			actiontext: ['Edit Instrument', 'Edit Instrument'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Instrument',
			actiontext: 'Delete Instrument',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Instrument',
          value: 'instrument',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Sex',
          value: 'sex',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Age From',
          value: 'agefrom',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Age To',
          value: 'ageto',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Time Period',
          value: 'timeperiod',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Type',
          value: 'type',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Lower Limit',
          value: 'lowerlimit',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Upper Limit',
          value: 'upperlimit',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Symbol',
          value: 'symbol',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Value',
          value: 'value',
          align: 'left'
        },
        {
          sortable: true,
          text: 'Text Range',
          value: 'textrange',
          align: 'left'
        },
        // {
        //   sortable: true,
        //   text: '2nd Range',
        //   value: 'enable_2nd_range',
        //   align: 'left'
        // },
        // {
        //   sortable: true,
        //   text: 'Secondary Range',
        //   value: 'secondary_range',
        //   align: 'left'
        // },
        // {
        //   sortable: true,
        //   text: 'Instrument ID',
        //   value: 'instrumentid',
        //   align: 'left'
        // },
        {
          sortable: true,
          text: 'Active',
          value: 'active',
          align: 'left'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
}