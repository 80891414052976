// import { set, toggle } from '../../../utils/vuex'

export default {
    // mutations: {
    //     SET_BAR_IMAGE(state, payload) {
    //         state.barImage = payload;
    //     },
    //     SET_DRAWER(state, payload) {
    //         state.drawer = payload;
    //     },
    //     SET_SCRIM(state, payload) {
    //         state.barColor = payload;
    //     }
    // }
};
