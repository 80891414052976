var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.color,"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.getIcon))])],1),_vm._v(" "),_c('div',{staticClass:"toolbarlabel"},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.options.label))])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},nativeOn:{"click":function($event){return _vm.clear(true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_vm._v(" "),_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[(_vm.getAction==='delete')?_c('v-card-title',[_vm._v("\n              Do you want to delete "+_vm._s(_vm.getItem)+"?\n              "),_c('br'),_vm._v(" "),_c('br'),_vm._v("This process can't be undone.\n            ")]):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"my-auto",attrs:{"md":"12"}},[(_vm.showTextByModule(0))?_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:255","vid":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"code",staticClass:"orange-input",attrs:{"label":_vm.setActionText(1),"error-messages":errors,"required":""},model:{value:(_vm.item_.code),callback:function ($$v) {_vm.$set(_vm.item_, "code", $$v)},expression:"item_.code"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"my-auto",attrs:{"md":"12"}},[(_vm.showTextfield)?_c('ValidationProvider',{attrs:{"name":"item","rules":"required|max:255","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"item",staticClass:"orange-input",attrs:{"label":_vm.setActionText(0),"error-messages":errors,"required":""},model:{value:(_vm.item_.name),callback:function ($$v) {_vm.$set(_vm.item_, "name", $$v)},expression:"item_.name"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"my-auto",attrs:{"md":"12"}},[(_vm.showTextByModule(1))?_c('ValidationProvider',{attrs:{"name":"sort","rules":"numeric|required|max:255","vid":"sort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"sort",staticClass:"orange-input",attrs:{"label":_vm.setActionText(1,2),"error-messages":errors,"required":""},model:{value:(_vm.item_.sort),callback:function ($$v) {_vm.$set(_vm.item_, "sort", $$v)},expression:"item_.sort"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"my-auto",attrs:{"md":"12"}},[(_vm.showTextByModule(2))?_c('ValidationProvider',{attrs:{"name":"view","rules":"required|max:255","vid":"view"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"view",staticClass:"orange-input",attrs:{"label":_vm.setActionText(1),"error-messages":errors,"required":""},model:{value:(_vm.item_.view),callback:function ($$v) {_vm.$set(_vm.item_, "view", $$v)},expression:"item_.view"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"my-auto"},[(_vm.showTextByModule(3))?_c('ValidationProvider',{attrs:{"name":"default","vid":"default"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.switchlabel,"color":_vm.color},on:{"change":function($event){return _vm.setSwitchLabel()}},model:{value:(_vm.item_.default),callback:function ($$v) {_vm.$set(_vm.item_, "default", $$v)},expression:"item_.default"}})]}}],null,true)}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-card-actions',{attrs:{"right":""}},[_c('v-spacer'),_vm._v(" "),_c('div',[(_vm.getAction!='delete')?_c('v-btn',{attrs:{"text":"","color":_vm.color,"loading":_vm.loading},on:{"click":_vm.processItem}},[_vm._v("Save")]):_vm._e(),_vm._v(" "),(_vm.getAction==='delete')?_c('v-btn',{staticClass:"orange--text",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.clear}},[_vm._v("Cancel")]):_vm._e(),_vm._v(" "),(_vm.getAction==='delete')?_c('v-btn',{staticClass:"orange--text",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.processItem}},[_vm._v("Yes")]):_vm._e()],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }