<template>
  <v-snackbar
    v-bind="$attrs"
    :timeout="timeout"
    v-model="snackbar"
    right
    rounded
    top
    elevation="24"
    :color="snackbarProps.color"
    :multi-line="true"
    transition="slide-x-transition"
  >
    <v-icon
      class="v-alert__icon elevation-6 white"
      light
      :color="snackbarProps.color"
      >{{ snackbarProps.icon }}</v-icon
    >
    <span class="v-alert__content pr-1">
      <!-- This is a notification with close button and icon and have many lines. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style. -->
      {{ snackbarProps.msg }}
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="snackbar = false" icon small>
        <v-icon>$vuetify.icons.cancel</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
// Components
import { VSnackbar } from 'vuetify/lib';
import EventBus from '@/plugins/eventbus';

export default {
  name: 'LisaSnackbar',

  //   extends: VSnackbar,

  data: () => ({
    // color: "info",
    colors: ['info', 'success', 'warning', 'error'],
    dialog: false,
    dialog2: false,
    dialog3: false,
    direction: 'top right',
    directions: [
      'top left',
      'top center',
      'top right',
      'bottom left',
      'bottom center',
      'bottom right',
    ],
    snackbar: false,
    timeout: 3000,
    increments: 1,
    snackbarProps: {
      color: 'info',
      icon: 'mdi-check',
      msg: null,
    },
    snackbarsettings: {},
  }),
  props: {
    // optSnackbar: {
    //   type: Object,
    //   default: {
    //     color: "deep-purple accent-4",
    //     icon: "mdi-bell",
    //   },
    // },
    type: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'deep-purple accent-4',
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    showSnackbar(param) {
      // console.log("showSnackbar");
      if (param.show == false) {
        this.dialog = false;
        return;
      }
      // this.snackbar = false;
      var opt = param.option;
      var [option] = this.snackbarsettings.filter((el) => el.option == opt);

      this.snackbarProps = Object.assign({}, option);

      if (this.action === 'delete') this.snackbarProps.msg = 'Delete Success!';

      if (param.msg != (null || '')) this.snackbarProps.msg = param.msg;

      if (param.option == 'error' && param.error != undefined) {
        let response_status = param.error.response.status;
        if (response_status == '422') {
          this.snackbarProps.msg = param.error.response.data.message;
        } else {
          this.snackbarProps.msg =
            'Server can not process data. (' +
            response_status +
            ') Please retry again.';
        }
      }

      this.snackbar = true;
      // console.log('showSnackbar', this.snackbarProps);
    },
  },
  created() {
    // EventBus.$on("SHOW_NOTIFICATION_SNACKBAR", (payload) => {
    //   this.showSnackbar(payload);
    // });
  },
  beforeDestroy() {
    EventBus.$off('SHOW_NOTIFICATION_SNACKBAR');
    // console.log("SHOW_NOTIFICATION_SNACKBAR - DESTROYED");
  },
  mounted() {
    EventBus.$on('SHOW_NOTIFICATION_SNACKBAR', (payload) => {
      this.showSnackbar(payload);
    }),
      (this.snackbarsettings = this.$store.getters[
        'settings/getSnackbarSettings'
      ]);
    // console.log('SHOW_NOTIFICATION_SNACKBAR_DASHBOARD_VIEW - MOUNTED');
  },
  //   computed: {
  //     classes() {
  //       return {
  //         ...VSnackbar.options.computed.classes.call(this),
  //         "v-snackbar--material": true,
  //       };
  //     },
  //   },
};
</script>

<style lang="sass">
.v-snackbar--material
    margin-top: -32px
    margin-bottom: 32px

.v-alert--material,
.v-snack__wrapper
    border-radius: 4px !important
    padding: 20px

.v-snack__content
    overflow: visible !important
    padding: 0 !important
    text-align: left !important

.v-alert__icon
    height: 40px
    width: 40px
</style>
