<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
    justify-center
    >
    <ValidationObserver ref="observer">
      <v-layout wrap slot-scope="{ invalid, validated }">
                 
                
                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Priority" rules="required|numeric">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.priority"
                      label="Priority"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="priority"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>
                
                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Gender" rules="required">
                      <v-select
                          slot-scope="{
                            errors,
                            valid
                          }"
                        v-model="item_.sex"
                        :items="sex"
                        label="Sex"
                        :error-messages="errors"
                        class="orange-input"
                        item-text="name"
                        item-value="id"
                        color="orange"
                        ref="sex"
                        @change="setSex"
                      ></v-select>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <v-spacer></v-spacer>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Age From" rules="required|numeric">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.age_from"
                      label="Age From"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="agefrom"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Age To" rules="required|numeric">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.age_to"
                      label="Age To"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="ageto"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>
                
                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Time Period" rules="required">
                      <v-select
                          slot-scope="{
                            errors,
                            valid
                          }"
                        v-model="item_.time_period"
                        :items="timeperiod"
                        label="Time Period"
                        :error-messages="errors"
                        class="orange-input"
                        item-text="name"
                        item-value="id"
                        color="orange"
                        ref="timeperiod"
                        @change="setTimePeriod"
                      ></v-select>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Type" rules="required">
                      <v-select
                          slot-scope="{
                            errors,
                            valid
                          }"
                        v-model="item_.type"
                        :items="type"
                        label="Type"
                        :error-messages="errors"
                        class="orange-input"
                        item-text="name"
                        item-value="id"
                        color="orange"
                        ref="type"
                        @change="setType"
                      ></v-select>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Lower Limit" rules="required|decimal">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.lower_limit"
                      label="Lower Limit"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="lowerlimit"
                      :disabled="range"
                      v-show="!range"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Upper Limit" rules="required|decimal">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.upper_limit"
                      label="Upper Limit"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="upperlimit"
                      :disabled="range"
                      v-show="!range"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Symbol" rules="required">
                      <v-select
                          slot-scope="{
                            errors,
                            valid
                          }"
                        v-model="item_.symbol"
                        :items="symbols"
                        label="Symbol"
                        :error-messages="errors"
                        class="orange-input"
                        item-text="name"
                        item-value="id"
                        color="orange"
                        ref="symbol"
                        :disabled="symbol"
                        v-show="!symbol"
                        @change="setSymbol"
                      ></v-select>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Value" rules="required|decimal">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.value"
                      label="Value"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="value"
                      :disabled="symbol"
                      v-show="!symbol"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md12
                  mx-2
                >
                  <ValidationProvider name="Range in Text" rules="required">
                    <v-textarea
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.text_range"
                      label="Range in Text"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="textrange"
                      :disabled="text"
                      v-show="!text"/>
                    </v-textarea>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md12
                  mx-2
                >
                  <ValidationProvider name="Force Secondary Reference Range">
                       <v-switch
                        v-model="item_.enable_2nd_range"
                        label="Force Secondary Reference Range"
                        color="orange"
                        @change="setSecondary"
                      ></v-switch>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md12
                  mx-2
                >
                  <ValidationProvider name="Secondary Reference Range">
                    <v-text-field
                      slot-scope="{
                        errors,
                        valid
                      }"
                      v-model="item_.secondary_range"
                      label="Range in Text"
                      class="orange-input"
                      :error-messages="errors"
                      required
                      ref="range2"
                      :disabled="range2"/>
                    </v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md3
                  mx-2
                >
                  <ValidationProvider name="Instrument" rules="required">
                      <v-select
                          slot-scope="{
                            errors,
                            valid
                          }"
                        v-model="item_.instrument_id"
                        :items="instruments"
                        label="Instrument"
                        :error-messages="errors"
                        class="orange-input"
                        item-text="instrument"
                        item-value="id"
                        color="orange"
                        ref="instrument"
                        @change="setInstrument"
                      ></v-select>
                  </ValidationProvider>
                </v-flex>

                <v-flex
                  xs12
                  md12
                  mx-2
                >
                  <ValidationProvider name="Active">
                       <v-switch
                        v-model="item_.active"
                        label="Active"
                        color="orange"
                      ></v-switch>
                  </ValidationProvider>
                </v-flex>

                <!-- <v-card-actions xs12> -->
                  <v-flex
                    xs12
                    md12
                    mx-2
                    text-xs-left
                  >
                  
                    <!-- <h6 v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="orange"
                    :size="25"
                    v-if="loading"></v-progress-circular> Saving ... </h6> -->
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="orange"
                    v-on:click="processItem"
                    :loading="loading"
                    :disabled="loading"
                  >
                    {{ getButtonLabel }}
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="orange"
                    v-on:click="clear(false)"
                  >
                    Clear
                  </v-btn>
                  </v-flex>
                <!-- </v-card-actions> -->
              
              

          </v-layout>
      </ValidationObserver>

                
      
    </v-layout>
  </v-container>
      
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation
} from "vee-validate";

import EventBus from '../../plugins/eventbus';

export default {
  props: {
      action: {
        type: String, 
        default() { return ''; },
      }
  },
  data() {
    return {
      refrangeheaders: [],
      refrangeitems: [],
      item_: {
        id: '',
        analyte_id: '',
        priority: '1',
        sex: '',
        age_from: '',
        age_to: '',
        time_period: '',
        type: '',
        lower_limit: '',
        upper_limit: '',
        symbol: '',
        value: '',
        text_range: '',
        enable_2nd_range: false,
        secondary_range: '',
        instrument_id: '',
        active: true
      },
      sex: [
        {
          id: 'A',
          name: 'ALL'
        },
        {
          id: 'M',
          name: 'Male'
        },
        {
          id: 'F',
          name: 'Male'
        }
      ],
      timeperiod: [
        {
          id: 'D',
          name: 'Days'
        },
        {
          id: 'M',
          name: 'Months'
        },
        {
          id: 'Y',
          name: 'Years'
        }
      ],
      type: [
        {
          id: 'range',
          name: 'Range'
        },
        {
          id: 'symbol',
          name: 'Symbol < / >'
        },
        {
          id: 'text',
          name: 'Text'
        }
      ],
      symbols: [
        {
          id: '>',
          name: '>'
        },
        {
          id: '>=',
          name: '>='
        },
        {
          id: '<',
          name: '<'
        },
        {
          id: '<=',
          name: '<='
        }
      ],
      instruments: [],
      range: false,
      symbol: false,
      text: false,
      range2: true,
      loading: false,
      dialog: false,
      options: { 
        actiontext: [''], 
        action: 'new' 
      },
      id: '',
      item: '',
      itemdata: {},
      message: '',
      module: '',
      snackbar: false,
      closedialog: false,
      switchlabel: 'Inactive',
      analyte_id: null,
      savebuttonlabel: 'SAVE REFERENCE RANGE',
      initialized: false
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    setSex(id) {
      console.log(id)
      this.item_.sex = id
    },
    setTimePeriod(id) {
      this.item_.time_period = id
    },
    setType(id) {
      console.log('test')
      this.item_.type = id
      if(id == 'range') {
        this.range = false
        this.symbol = this.text = true

        this.setFocus(0)
      } else if (id == 'symbol') {
        this.symbol = false
        this.range = this.text = true

        // this.$refs.lowerlimit.reset()
        // this.$refs.upperlimit.reset()

        this.setFocus(1)
      } else {
        this.text = false
        this.range = this.symbol = true
        this.setFocus(2)
      }
    },
    setSymbol(id) {
      this.item_.symbol = id
      this.setFocus(3)
    },
    setSecondary(id) {
      this.range2 = !this.range2
      this.item_.enable_2nd_range = id
      console.log(id)
      this.setFocus(4)
    },
    setInstrument(id) {
      this.item_.instrument_id = id
    },
    closeDialog() {
      // this.dialog = true
      EventBus.$emit('CLOSE_ANALYTE_DIALOG');
    },
    async setOption(opt) {
      this.options = opt
      return true
    },
    async fetchItems() {
        this.itemdata = {}
        await this.$store.dispatch('referenceranges/fetchItems')
          .then(res => {
              // this.items = res.data
              this.refrangeheaders = res.data
          })
          .catch(error => {
            var notif = { option: 'fail', msg: 'Error in getting ' + this.title + ' data.' }
            this.showNotification(notif)
            // console.log(error)
          });
    },
    setSwitchLabel() {
      if (this.item_.active === undefined || this.item_.active === 'NO' || this.item_.active == false) {
        this.item_.active = false
        this.switchlabel = 'Inactive'
        //return 'Inactive'
      }
      else {
        this.item_.active = true
        this.switchlabel = 'ACTIVE'
        // return 'Active'
      }
    },
    setActionText(index) {
      return this.options.actiontext[index]
    },
    async processItem() {
      const isValid = await this.$refs.observer.validate()
      // alert(isValid)
      var isValid2 = await this.validateItems()
      if(isValid2) {
        // this.item_.name = this.item
        // this.item_.name = ''
        // console.log(this.item_)
        this.loading = true
        var payload = { 
          action: this.options.action,
          item: this.item_,
          module: 'referenceranges'
        }
        // EventBus.$emit('PROCESS_CALL', payload);

        this.$store.dispatch('referenceranges/processItem', payload)
            .then(res => {

              var notif = { option: 'success', msg: '', show: true }
              EventBus.$emit('SHOW_NOTIFICATION', notif)
              this.clear()
             
          })
          .catch(error => {

            var notif = { option: 'fail', msg: '' }
            if(error.response.status === 422) {
              var err = this.$store.getters['settings/getErrors']
              var errors = ''
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(key => errors = err.data.errors[key][0]);
              notif = { option: 'fail', msg: errors }
              this.showNotification(notif)
            } else {
              notif = { option: 'fail', msg: 'Server can not process the Item.' }
              this.showNotification(notif)
            }

            // this.clear()
          }); 
        
      } else {
        // Throw Validation Error
      }
    },
    async validateItems() {

      if(this.item_.priority != "" && this.item_.sex != "" && this.item_.age_from != "" && this.item_.age_to && this.item_.time_period && this.item_.type != "" && this.item_.instrument_id != "") {

        if(this.item_.type == 'range') {
          if(this.item_.lower_limit != "" && this.item_.upper_limit != "")
            return true
        } else if (this.item_.type == 'symbol') {
          if(this.item_.symbol != "" && this.item_.value != "")
            return true
        } else if (this.item_.type == 'text') {
          if(this.item_.text_range != "")
            return true
        }

      }
      return false
    },
    async clear(toggle) {

      this.item_ = {}
      this.itemdata = {}
      this.setType(null)
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });

    //   this.$nextTick(() => {
    //     this.errors.clear();
    //     this.$nextTick(() => {
    //         this.$validator.reset();
    //     });
    // });
      this.range = false
      this.symbol = false
      this.text = false
      this.loading = false
      this.savebuttonlabel = 'SAVE REFERENCE RANGE'
      // this.$nextTick(() => this.$refs.priority.focus())

      if(!toggle)
        this.setFocus(5)
      // if(this.options.action != 'new' || toggle || this.closedialog) {
      //   this.closeDialog()
      // }
      console.log('CLEARED')
    },
    async setFocus(index) {
      if(index == 0)
        this.$nextTick(() => this.$refs.lowerlimit.focus())
      else if(index == 1)
        this.$nextTick(() => this.$refs.symbol.focus())
      else if(index == 2)
        this.$nextTick(() => this.$refs.textrange.focus())
      else if(index == 3)
        this.$nextTick(() => this.$refs.value.focus())
      else if(index == 4)
        this.$nextTick(() => this.$refs.range2.focus())
      else if(index == 5)
        this.$nextTick(() => this.$refs.priority.focus())
    },
    SetNewDefaults() {
      this.item_.operator = '*'
      this.item_.factor = 1
      this.item_.amount = 0
      this.item_.sort = 1
      this.item_.analyte_group_id = 0
      this.item_.calculated = false
      this.switchlabel = 'ACTIVE'
      this.item_.active = true
    },
    init() {
      this.clear()
      this.instruments = this.$store.getters['instruments/getItems']
      this.item_.analyte_id = this.$store.getters['cache/getAnalyteID']
      // this.refrangeheaders = this.$store.getters['referenceranges/getHeaders']
      if(!this.initialized) {
        this.item_ = this.$store.getters['cache/getReferenceRanges']
        this.initialized = true
      }
      console.log('init')
    }
  },
  computed: {
    
    showTextfield() {
      if(this.options.action != 'delete')
        return true
      else
        return false
    },
    getIcon() {
      return this.options.icon
    },
    getAction() {
      return this.options.action
    },
    getItem() {
      return this.item_.name
    },
    getButtonLabel() {
      return this.savebuttonlabel
    }
    
  },
  watch: {
     patientdata (val) {
        console.log(val)
    }
  },
  mounted() {
    this.init()
    EventBus.$on('CLEAR_REFRANGE', () => {
      this.clear(false)
      console.log('CLEAR_REFRANGE')
    }),
    EventBus.$on('CLEAR_DIALOG', (payload) => {
      this.clear(false)
    }),
    EventBus.$on('LOAD_REFERENCE_RANGE', (payload) => {
      this.clear(false)
      this.item_ = payload
      this.setType(this.item_.type)
      this.options.action = 'edit'
      this.savebuttonlabel = 'UPDATE REFERENCE RANGE'
    })
    

  },
  created() {
    // this.item_ = {}
    // this.clear(false)
    console.log('CREATED')
  }
}
</script>

<style>


.v-toolbar__content {
    margin-left: 0px !important;
  }

.v-datatable__actions {
  color: black;
}

.v-datatable__actions .v-btn {
  color: orange !important;
}
.v-messages__message {
  color: red !important;
}
</style>