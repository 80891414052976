export default {
    api: '/api/analytes',
    items: [],
    buttontip: 'New Analytes',
    title: 'Analytes',
    text: 'Laboratory Tests',
    options: [
        {
            action: 'new',
            label: 'New Analytes',
            actiontext: ['Enter New Analyte', 'Enter New Analyte Code'],
            icon: 'mdi-new-box'
        },
        {
            action: 'edit',
            label: 'Edit Unit',
            actiontext: ['Edit Analyte', 'Edit Analyte Code'],
            icon: 'mdi-pencil'
        },
        {
            action: 'delete',
            label: 'Delete Analyte',
            actiontext: 'Delete Analyte',
            icon: 'mdi-delete'
        }
    ],
    headers: [
        {
            sortable: true,
            text: 'ID',
            value: 'id'
        },
        {
            sortable: true,
            text: 'HL7 Code',
            value: 'hl7_code'
        },
        {
            sortable: true,
            text: 'Code',
            value: 'code'
        },
        {
            sortable: true,
            text: 'Analyte',
            value: 'analyte'
        },
        // {
        //   sortable: true,
        //   text: 'Order Name',
        //   value: 'ordername'
        // },
        {
            sortable: true,
            text: 'Section',
            value: 'section'
        },
        {
            sortable: true,
            text: 'Specimen',
            value: 'specimen'
        },
        {
            sortable: true,
            text: 'Unit',
            value: 'unit'
        },
        {
            sortable: true,
            text: 'Decimal',
            value: 'decimal'
        },
        // {
        //   sortable: true,
        //   text: 'Operator',
        //   value: 'operator'
        // },
        // {
        //   sortable: true,
        //   text: 'Factor',
        //   value: 'factor'
        // },
        // {
        //   sortable: true,
        //   text: '2nd Unit',
        //   value: 'unit2'
        // },
        // {
        //   sortable: true,
        //   text: '2nd Decimal',
        //   value: 'decimal2'
        // },
        // {
        //   sortable: true,
        //   text: 'Amount',
        //   value: 'amount'
        // },
        {
            sortable: true,
            text: 'Sort',
            value: 'sort'
        },
        // {
        //   sortable: true,
        //   text: 'Calculated',
        //   value: 'calculated'
        // },
        // {
        //   sortable: true,
        //   text: 'Report Template',
        //   value: 'reporttemplate'
        // },
        {
            sortable: true,
            text: 'Active',
            value: 'active',
            align: 'center'
        },
        {
            sortable: false,
            text: 'Actions',
            value: 'actions',
            align: 'left'
        }
    ]
};
