<template>
  <!-- <v-container fill-height fluid grid-list-xl> -->
  <v-layout justify-center row wrap>
    <!-- <v-responsive> -->
    <base-material-card
      color="info"
      icon="fa-user-circle-o"
      title="Search Analyte"
      class="py-3 px-5"
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-autocomplete
            class="patient"
            color="grey darken-1"
            v-model="model"
            :items="entries"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            item-text="description"
            item-value="id"
            :label="label"
            placeholder="Start typing to Search"
            prepend-icon="fa4 fa-check"
            return-object
            @change="setValue"
            clear-icon="search"
            :error-messages="getErrorMsg()"
          ></v-autocomplete>
          <!--  v-on:keyup.delete="clear()" -->
          <!-- <v-text-field
          dark
          color="cyan lighten-5"
          class="search-label-color"
          prepend-icon="search"
          v-model="search"
          label="Type to Search.."
          />
          </v-text-field>-->
        </v-col>
      </v-row>
      <!-- <v-card outlined flat style="background-color: red">
        <v-layout row wrap class="searched-items">
          <v-flex xs12 md12>
            test 1
          </v-flex>
        </v-layout>
      </v-card>-->
      <v-row>
        <v-col md="12">
          <v-card-actions class="pa-0">
            <v-btn color="cyan darken-2 --black-text" @click="clear(true)">
              {{ buttonLabel }}
              <v-icon right>{{ buttonIcon }}</v-icon>
            </v-btn>
            <v-btn :disabled="!model" color="grey darken-2" @click="clear()">
              Clear
              <v-icon right>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-actions>

          <!-- <v-btn
          :disabled="!model"
          color="grey darken-2"
          @click="clear()"
        >
          Clear
          <v-icon right>mdi-close-circle</v-icon>
          </v-btn>-->
        </v-col>
        <!-- </v-flex>   -->

        <!-- <div class="flex-grow-1"></div> -->
      </v-row>
    </base-material-card>
    <!-- </v-responsive> -->
  </v-layout>
  <!-- </v-container> -->
</template>

<script>
import _ from 'lodash';

import EventBus from '@/plugins/eventbus';

export default {
  props: {
    action: {
      type: String, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return '';
      },
    },
    module: {
      type: String, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return '';
      },
    },
  },
  //
  data() {
    return {
      items: [
        {
          id: 1,
          analyte_order_name: 'ANALYTE 1',
          specimen: 'Serum',
          section: 'CHEMISTRY',
          amount: '10000000.01',
        },
        {
          id: 2,
          analyte_order_name: 'ANALYTE 2',
          specimen: 'Serum',
          section: 'CHEMISTRY',
          amount: '1000.02',
        },
        {
          id: 3,
          analyte_order_name: 'ANALYTE 3',
          specimen: 'Serum',
          section: 'CHEMISTRY',
          amount: '1000.10',
        },
        {
          id: 4,
          analyte_order_name: 'ANALYTE 4',
          specimen: 'Serum',
          section: 'CHEMISTRY',
          amount: '1000',
        },
        {
          id: 5,
          analyte_order_name: 'ANALYTE 5',
          specimen: 'Serum',
          section: 'CHEMISTRY',
          amount: '1000',
        },
      ],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      keyword: null,
      selected: false,
      patient: {},
      patients: null,
      selected_ids: [],
      errorMessage: '',
      finalSelection: [],

      color: 'success',
      item_: {},
      options: {},
      menu: false,
      date: null,
      loading: false,
      validator: null,
    };
  },
  // components: {
  //   ValidationProvider,
  //   ValidationObserver
  // },
  methods: {
    getUnique(arr, comp) {
      //store the comparison  values in array
      const unique = arr
        .map((e) => e[comp])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & return unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e]);

      return unique;
    },
    getErrorMsg() {
      return this.errorMessage;
    },
    async setValue(value) {
      if (!value) return;

      let val = value;
      // console.log('selected value', val)
      // console.log('selected value id', val.id)
      this.selected = true;
      switch (this.module) {
        case 'orders':
          let selected = this.$store.getters['cache/getSelectedAnalytes'];
          let exists = false;

          selected.forEach(function (item, index) {
            if (item.id === val.id) {
              exists = true;
            }
          });

          if (!exists) EventBus.$emit('ADD_ITEM', val);
          else this.errorMessage = 'Analyte already selected.';

          this.clear();
          break;
        default:
          break;
      }
    },
    getSelection(arr1, arr2) {
      let final = [];
      // console.log('GET_SELECTION')
      let i = 0;
      let x = 0;
      let entry = arr1;

      let a1length = entry.length;
      const a1 = arr1;
      const a2 = arr2;

      let found = false;
      let index = 0;
      if (entry.length) {
        for (i = 0; i < a1.length; i++) {
          found = false;
          for (x = 0; x < a2.length; x++) {
            // console.log(a1[i].id + ' == ' + a2[x].id , a1[i].id)
            if (a1[i].id === a2[x].id) {
              found = true;
            }
          }
          // console.log('found: ', found)
          if (!found) final.push(a1[i]);
        }
      }

      // let entryid = 'entry_id: ';
      // entry.forEach(function(item,index){
      //   entryid += item.id + ', '
      // });
      // console.log(entryid)

      // let finalSelection = 'final: ';
      // final.forEach(function(item,index){
      //   finalSelection += item+ ', '
      // });
      // console.log(finalSelection)
      return final;
    },
    removeFromSelection(index) {
      this.finalSelection.splice(index, 1);
    },
    throttledMethod: _.debounce(() => {
      console.log('I only get fired once every one second, max!');
    }, 1000),
    // async searchItem(val) {
    searchItem: _.debounce(function (query) {
      this.$store
        .dispatch('analytes/searchItem', query)
        // .then(res => res.json())
        .then((res) => {
          const { count, entries } = res;
          this.count = count;

          // this.entries = entries
          // this.isLoading = false
          // return

          if (!entries.length) {
            this.entries = entries;
            this.isLoading = false;
            return;
          }

          const selected = this.$store.getters['cache/getSelectedAnalytes'];
          // const entry1 = entries
          const entry = entries;
          this.finalSelection = entry;
          let selections = [];

          // console.log('entry1 length', entry1.length)
          // var entry1id = 'entry1id: ';
          // entry1.forEach(function(item,index){
          //   entry1id += item.id + ', '

          // });
          // console.log(entry1id)

          let selection = null;
          selection = this.getSelection(entry, selected);
          // console.log('selection_obj', selection)

          if (!selection.length) {
            this.entries = [];
          } else {
            // var sel = 'selection: ';
            // selection.forEach(function(item,index){
            //   sel += item.id + ', '
            // });
            this.entries = selection;
          }
        })
        .catch((error) => {
          var notif = {
            option: 'fail',
            msg:
              'Internal Server Error. Can not complete search (' +
              error.response.status +
              ')',
          };
          EventBus.$emit('SHOW_NOTIFICATION', notif);
        })
        .finally(() => (this.isLoading = false));
    }, 100),
    clear(toggle) {
      switch (this.module) {
        case 'orders':
          if (this.model) {
            console.log('clear');
            this.selected = false;
            this.entries = [];
          }

          if (toggle) EventBus.$emit('CLEAR_ORDERS');
          break;
        default:
          break;
      }

      this.model = null;
      this.search = null;

      // EventBus.$emit('LOAD_PATIENT', this.patient)
      // if(this.patient.id != null) {
      //   this.selected = false
      //   this.patient = {}
      //   this.model = null
      //   EventBus.$emit('LOAD_PATIENT', this.patient)
      // }
    },
  },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      // console.log(this.entries)

      return this.entries.map((entry) => {
        // const Description = entry.Description.length > this.descriptionLimit
        //   ? entry.Description.slice(0, this.descriptionLimit) + '...'
        //   : entry.Description

        const description = entry.description;
        return Object.assign({}, entry, { description });

        // return Object.assign({}, entry, { entry.description })
      });
    },
  },

  watch: {
    search(val) {
      // // Items have already been loaded
      // if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading || !val || this.selected) return;
      if (val.length < 2) return;
      this.keyword = val;
      this.errorMessage = null;
      this.isLoading = true;

      this.searchItem(val);

      // // Lazily load input items
      // fetch('https://api.publicapis.org/entries')
      //   .then(res => res.json())
      //   .then(res => {
      //     const { count, entries } = res
      //     this.count = count
      //     this.entries = entries
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
      //   //end fetch
    }, //end search val
  },

  created() {},
  mounted() {
    EventBus.$on('CLOSE_DIALOG', (payload) => {
      console.log(Object.keys(payload).length);
      if (Object.keys(payload).length != 0) {
        this.model = payload.show;
        this.patient = payload.data[0];
        EventBus.$emit('LOAD_PATIENT', payload.data[0]);
      }
      this.patients = null;
    });
    EventBus.$on('CLEAR_SEARCH', (payload) => {
      // this.clear(true)
      this.search = null;
      this.model = null;
    });
  },
  computed: {
    label() {
      switch (this.module) {
        case 'orders':
          return 'Search Analytes';
          break;
        default:
          return 'Search';
      }
    },
    buttonLabel() {
      switch (this.module) {
        case 'orders':
          return 'New Order';
          break;
        default:
          return 'New';
      }
    },
    buttonIcon() {
      switch (this.module) {
        case 'orders':
          return 'mdi-plus-circle';
          break;
        default:
          return 'mdi-plus-circle-outline';
      }
    },
  },
};

//v-list__tile v-list__tile--link v-list__tile--active theme--light cyan--text text--lighten-5
// .v-list__tile--active {
//   color: black !important;
// }
</script>

<style scoped>
::v-deep .v-autocomplete__content .v-list__tile {
  height: 700px !important;
}

.v-list__tile__mask {
  background-color: #eeff41 !important;
  color: black !important;
}

.cyan--text text--lighten-5 {
  color: black important;
}

/*
.search-label-color .v-label {
  color: #E0F7FA !important;
  opacity: 1;
  font-weight: bold;
}
.searched-items {
  z-index: 9999;
  font-weight: bold;
  position: absolute;
  top: 45px;
  overflow-y: scroll;
  max-height: 200px;
}
*/
.patient {
  margin-top: 10px !important;
  font-weight: bold;
}
</style>
