<template>
  <!-- <v-container fluid tag="section"> -->
  <v-layout justify-center wrap>
    <!-- <v-container id="dashboard" fluid tag="section"> -->
    <v-responsive>
      <base-lisa-material-card color="info" icon="mdi-mail" title="Patient Info">
        <v-form>
          <v-autocomplete
            v-model="model"
            :items="entries"
            :loading="isLoading"
            :search-input.sync="search"
            color="grey darken-1"
            hide-no-data
            hide-selected
            item-text="description"
            item-value="id"
            label="Search Patient..."
            placeholder="Start typing to Search"
            prepend-icon="fa4 fa-check"
            return-object
            @change="setValue"
            v-on:keyup.delete="clear()"
            class="patient"
          ></v-autocomplete>
          <!-- <v-responsive> -->
          <v-card-actions class="pa-0">
            <!-- <v-checkbox label="Subscribe to newsletter" /> -->

            <!-- <v-spacer /> -->
            <!-- <v-responsive -->
            <v-btn
              color="light-green"
              @click="
                                        clear();
                                        patients = true;
                                    "
            >
              New Patient
              <v-icon right>mdi-account-plus</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn :disabled="!model" color="grey darken-2" @click="clear()">
              Clear
              <v-icon right>mdi-close-circle</v-icon>
            </v-btn>
            <!-- </v-responsive> -->
          </v-card-actions>
          <!-- </v-responsive> -->
        </v-form>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <div class="caption grey--text">PATIENT ID</div>
              <div class="bold">{{ patient.patient_id }}</div>
            </v-col>

            <v-col cols="12" md="3">
              <div class="caption grey--text">LAST NAME</div>
              <div class="bold">{{ patient.last_name }}</div>
            </v-col>

            <v-col cols="12" md="3">
              <div class="caption grey--text">FIRST NAME</div>
              <div class="bold">{{ patient.first_name }}</div>
            </v-col>

            <v-col cols="12" md="3">
              <div class="caption grey--text">MIDDLE NAME</div>
              <div class="bold">{{ patient.middle_name }}</div>
            </v-col>
          </v-row>

          <v-row class="mt-n2">
            <v-col cols="12" md="3">
              <div class="caption grey--text">AGE</div>
              <div class="bold">{{ patient.age }}</div>
            </v-col>

            <v-col cols="12" md="1">
              <div class="caption grey--text">GENDER</div>
              <div class="bold">{{ patient.gender }}</div>
            </v-col>

            <v-col cols="12" md="2">
              <div class="caption grey--text">DATE OF BIRTH</div>
              <div class="bold">{{ patient.birthdate }}</div>
            </v-col>

            <v-col cols="12" md="3">
              <div class="caption grey--text">LAST VISIT DATE</div>
              <div class="bold">date...</div>
            </v-col>

            <v-col cols="12" md="3">
              <div class="caption grey--text">LAST BRANCH VISIT</div>
              <div class="bold">BRANCH ...</div>
            </v-col>
          </v-row>
        </v-card-text>
      </base-lisa-material-card>
    </v-responsive>
    <!-- </v-container> -->
  </v-layout>
  <!-- </v-container> -->
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  Validator,
} from 'vee-validate';

import EventBus from '@/plugins/eventbus';

export default {
  props: {
    patientdata: {
      type: Object, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return {};
      },
    },
  },
  //
  data() {
    return {
      patient: {},
    };
  },
  methods: {},
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        // const Description = entry.Description.length > this.descriptionLimit
        //   ? entry.Description.slice(0, this.descriptionLimit) + '...'
        //   : entry.Description

        const description = entry.description;
        return Object.assign({}, entry, { description });

        // return Object.assign({}, entry, { entry.description })
      });
    },
  },

  watch: {
    search(val) {
      // if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading || !val || this.selected) return;
      if (val.length < 3) return;

      this.isLoading = true;

      this.searchItem(val);
    },
    patientdata(val) {
      this.patient = val;
    },
  },

  created() {},
  mounted() {
    EventBus.$on('LOAD_PATIENT', (payload) => {
      console.log('LOAD_PATIENT', payload);
      this.patient = payload;
    });
  },
};
</script>

<style lang="sass">
.patient
  font-weight: bold

// .patientcard
//   margin-top: 30px !important
//   height: 200px
</style>
