export default {
    api: '/api/analytegroups',
    items: [],
    buttontip: 'New Analyte Group',
    title: 'Analyte Groups',
    text: 'Test Groups',
    refresh: true,
    options: [
        {
            action: 'new',
            label: 'New Analyte Group',
            actiontext: [
                'Enter New Analyte Group',
                'Enter New Code',
                'Enter New Sort Number'
            ],
            icon: 'mdi-new-box'
        },
        {
            action: 'edit',
            label: 'Edit Analyte Group',
            actiontext: ['Edit Analyte Group', , 'Edit Sort Number'],
            icon: 'mdi-pencil'
        },
        {
            action: 'delete',
            label: 'Delete Analyte Group',
            actiontext: 'Delete Analyte Group',
            icon: 'mdi-delete'
        }
    ],
    headers: [
        {
            sortable: true,
            text: 'ID',
            value: 'id'
        },
        {
            sortable: true,
            text: 'Analyte Code',
            value: 'code'
        },
        {
            sortable: true,
            text: 'Analyte Group',
            value: 'name'
        },
        {
            sortable: true,
            text: 'Sort',
            value: 'sort'
        },
        {
            sortable: false,
            text: 'Actions',
            value: 'actions',
            align: 'left'
        }
    ]
};
