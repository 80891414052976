import { fetchItems, updateItem, addItem, deleteItem } from '../proc/axios';

export default {
    async setTerminalID(context, payload) {
        context.commit('UPDATE_TERMINAL_ID', payload);
    },
    async setAnalyteID(context, payload) {
        context.commit('UPDATE_ANALYTE_ID', payload);
    },
    async setAnalyteSettings(context, payload) {
        context.commit('UPDATE_ANALYTE_SETTINGS', payload);
    },
    async setReferenceRanges(context, payload) {
        context.commit('SET_REFERENCE_RANGES', payload);
    },
    async setSelectedAnalytes(context, payload) {
        context.commit('UPDATE_SELECTED_ANALYTES', payload);
    },
    async fetchItems(context) {
        const response = await fetchItems(context.state.api);
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async addItem(context, item) {
        const response = await addItem(context.state.api, item);
        // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async updateItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await updateItem(url, item);
        // console.log('updateItem')
        // console.log(response.data)

        return response;
    },
    async deleteItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await deleteItem(url, item);
        return response;
    },
    async processItem(context, payload) {
        var action = payload.action;
        const url = context.state.api + '/' + payload.item.id;
        var response;

        if (action === 'new')
            response = await addItem(context.state.api, payload.item);
        else if (payload.action === 'edit')
            response = await updateItem(url, payload.item);
        else if (payload.action === 'delete')
            response = await deleteItem(url, payload.item);

        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async setRefresh(context, payload) {
        context.commit('UPDATE_REFRESH', payload);
    },
    async fetchAnalytes(context) {
        const response = await fetchItems('/api/allanalytes');
        // console.log('async fetchAnalytes', response);
        context.commit('SAVE_ANALYTES', response);
        return response;
    },
    async fetchAppSettings(context) {
        const response = await fetchItems('/api/appsettings');
        context.commit('SAVE_APP_SETTINGS', response);
        return response;
    }
};
