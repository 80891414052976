var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-lisa-material-card',{staticClass:"px-5 py-3",attrs:{"color":"info","icon":"mdi-cog","inline":"","title":_vm.getTitle}},[_c('v-tooltip',{attrs:{"top":"","content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"slot":"activator","color":"success","small":""},on:{"click":function($event){return _vm.showDialog('new')}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("NEW\n            ")],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.ButtonTip))])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":"","content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"slot":"activator","color":"orange","small":""},on:{"click":function($event){return _vm.fetchItems()}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("REFRESH\n            ")],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Refresh Table")])]),_vm._v(" "),_c('v-divider',{staticClass:"mt-3"}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.itemheaders,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text-warning text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","height":3,"indeterminate":"","absolute":""}})]},proxy:true},{key:"items",fn:function(ref){
var item = ref.item;
return [_vm._l((item),function(value){return (_vm.checkModule())?_c('td',[_vm._v(_vm._s(value))]):_vm._e()}),_vm._v(" "),(_vm.module == 'pathologists')?_c('td',[_vm._v(_vm._s(item.id))]):_vm._e(),_vm._v(" "),(_vm.module == 'pathologists')?_c('td',[_vm._v(_vm._s(item.name))]):_vm._e(),_vm._v(" "),(_vm.module == 'pathologists')?_c('td',[_c('v-chip',{attrs:{"color":_vm.setColor(item.default),"dark":"","small":true}},[_vm._v(_vm._s(item.default))])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn--simple",attrs:{"slot":"activator","icon":""},on:{"click":function($event){return _vm.editItem(item)}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Edit "+_vm._s(item.analyte))])])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('admin-dialogv2',{attrs:{"propItems":_vm.itemdata,"propOptions":_vm.propOptions,"propModule":_vm.module}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }