export default {
    /**
     * GET STATISTICS DATA
     */
    getStatisticsData: state => {
        return state.items;
    },
    /**
     * END
     */
    getTitle: state => {
        return state.title;
    },
    getText: state => {
        return state.text;
    },
    getButtonTip: state => {
        return state.buttontip;
    },
    getItems: state => {
        return state.items;
    },
    getNewOption: state => {
        return state.optnew;
    },
    getEditOption: state => {
        return state.optedit;
    },
    getOptions: state => {
        return state.options;
    },
    getHeaders: state => {
        return state.headers;
    },
    refresh: state => {
        return state.refresh;
    }
};
