function addStyles(win, styles) {
    styles.forEach(style => {
        let link = win.document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', style);

        win.document.getElementsByTagName('head')[0].appendChild(link);
        console.log('style', link);
    });
}

const VueHtmlToPaperFull = {
    install(Vue, options = {}) {
        Vue.mixin({
            methods: {
                $htmlToPaperFull(el, thetitle, thestyle, cb = () => true) {
                    // alert(1);
                    let {
                        name = '_blank',
                        specs = [
                            'fullscreen=no',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        replace = true,
                        styles = thestyle,
                        title = thetitle
                    } = options;

                    // console.log('thestyle', thestyle);
                    specs = !!specs.length ? specs.join(',') : '';

                    const element = document.getElementById(el);

                    if (!element) {
                        alert(`Element to print #${el} not found!`);
                        return;
                    }

                    const url = '';
                    // const win = window.open(url, name, specs, replace);
                    const win = window.open(url, name);
                    win.document.write(
                        `
              <!DOCTYPE html>
                <head>
                  <title>` +
                            thetitle +
                            `</title>
                </head>
                <body>
                  ${element.innerHTML}
                </body>
                </html>

            `
                    );

                    // addStyles(win, styles);
                    addStyles(win, thestyle);

                    setTimeout(() => {
                        win.document.close();
                        win.focus();
                        win.print();
                        win.close();
                        cb();
                    }, 500);
                    return true;
                }
            }
        });
    }
};

export default VueHtmlToPaperFull;
