<template>
  <v-container id="validation-forms" fluid tag="section" fill-height>
    <v-layout justify-center wrap>
      <v-row>
        <v-col cols="12" md="4">
          <base-material-card
            color="info"
            icon="fa-user-circle-o"
            title="Search Patient"
            class="py-3 px-5"
          >
            <v-form>
              <v-autocomplete
                v-model="model"
                :items="entries"
                :loading="isLoading"
                :search-input.sync="search"
                color="grey darken-1"
                hide-no-data
                hide-selected
                item-text="description"
                item-value="id"
                label="Search Patient..."
                placeholder="Start typing to Search"
                prepend-icon="fa4 fa-check"
                return-object
                @change="setValue"
                v-on:keyup.delete="clear()"
                class="patient"
              ></v-autocomplete>
              <!-- <v-responsive> -->
              <v-card-actions class="pa-0">
                <!-- <v-checkbox label="Subscribe to newsletter" /> -->

                <!-- <v-spacer /> -->
                <!-- <v-responsive -->
                <v-btn color="light-green" @click="clear();patients = true;">
                  New Patient
                  <v-icon right>mdi-account-plus</v-icon>
                </v-btn>
                <v-spacer />
                <v-btn :disabled="!model" color="grey darken-2" @click="clear()">
                  Clear
                  <v-icon right>mdi-close-circle</v-icon>
                </v-btn>
                <!-- </v-responsive> -->
              </v-card-actions>
              <!-- </v-responsive> -->
            </v-form>
          </base-material-card>
        </v-col>

        <!-- <v-responsive> -->
        <v-col cols="12" md="8">
          <v-expand-transition>
            <!-- <base-material-card icon="mdi-mail" class="v-card--material-stats" color="info"> -->
            <user-selectedpatient v-show="model" />
            <!-- </base-material-card> -->
          </v-expand-transition>
        </v-col>
        <!-- </v-responsive> -->

        <v-col cols="12" md="4">
          <user-orderselection />
          <!-- <v-card color="white"> -->
          <!-- <user-searchcomponent :module="'orders'" class="analytes" /> -->
          <!-- </v-card> -->
        </v-col>
      </v-row>

      <!-- <v-row>
        
      </v-row>-->

      <v-dialog v-model="patients" persistent color="red" max-width="1000px">
        <user-patients :action="'new'" :module="'orders'" />
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  // Validator,
} from "vee-validate";

import EventBus from "@/plugins/eventbus";

export default {
  //
  data() {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      selected: false,
      patient: {},
      patients: null,

      color: "success",
      item_: {},
      options: {},
      menu: false,
      date: null,
      loading: false,
      validator: null,
      // buttonLabel: 'Save Patient',
      sex: [
        {
          id: "M",
          name: "Male",
        },
        {
          id: "F",
          name: "Female",
        },
      ],
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    setValue(value) {
      this.selected = true;
      this.patient = value;
      console.log("setValue", this.patient);
      EventBus.$emit("LOAD_PATIENT", this.patient);
    },
    async processItem() {
      const isValid = await this.$refs.observer.validate();
      var notif = { option: "success", msg: "", show: true };

      if (isValid) {
        this.loading = true;

        var payload = {
          action: this.options.action,
          item: this.item_,
          module: null,
        };

        this.$store
          .dispatch("patients/processItem", payload)
          .then((res) => {
            if (this.options.action == "delete")
              notif = {
                option: "success",
                msg: "Delete Successful!",
                show: true,
              };

            EventBus.$emit("SHOW_NOTIFICATION", notif);

            // this.clear()
            // this.fetchItems()
          })
          .catch((error) => {
            notif = { option: "fail", msg: "" };
            this.loading = false;
            if (error.response.status === 422) {
              var err = this.$store.getters["settings/getErrors"];
              var errors = "";
              // Object.keys(err.data.errors).forEach(key => console.log(key, err.data.errors[key][0]));
              Object.keys(err.data.errors).forEach(
                (key) => (errors = err.data.errors[key][0])
              );
              notif = { option: "fail", msg: errors };
              EventBus.$emit("SHOW_NOTIFICATION", notif);
            } else {
              notif = {
                option: "fail",
                msg:
                  "Server can not process the Item. (" +
                  error.response.status +
                  ")",
              };
              EventBus.$emit("SHOW_NOTIFICATION", notif);
            }
          });
      } else {
        const ValidDob = await this.validator
          .validateAll({
            dob: this.item_.dob,
          })
          .then((result) => {
            if (result) return true;
            else return false;
          });
        if (!ValidDob)
          notif = {
            option: "fail",
            msg: "Date of Birth is Required",
            show: true,
          };
        else
          notif = {
            option: "fail",
            msg: "Please fill in all required fields",
            show: true,
          };

        EventBus.$emit("SHOW_NOTIFICATION", notif);
      }
    },
    async searchItem(val) {
      this.$store
        .dispatch("patients/searchItem", val)
        // .then(res => res.json())
        .then((res) => {
          // console.log(res)
          const { count, entries } = res;
          this.count = count;
          this.entries = entries;
        })
        .catch((error) => {
          var notif = {
            option: "fail",
            msg:
              "Internal Server Error. Can not complete search (" +
              error.response.status +
              ")",
          };
          EventBus.$emit("SHOW_NOTIFICATION", notif);
        })
        .finally(() => (this.isLoading = false));
    },
    async clear(toggle) {
      console.log("this.patient.patient_id", this.patient.patient_id);
      if (this.patient.patient_id != null) {
        this.selected = false;
        this.patient = {};
        this.model = null;
        EventBus.$emit("LOAD_PATIENT", this.patient);
      }
    },
  },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      // console.log(this.entries)

      return this.entries.map((entry) => {
        // const Description = entry.Description.length > this.descriptionLimit
        //   ? entry.Description.slice(0, this.descriptionLimit) + '...'
        //   : entry.Description

        const description = entry.description;
        return Object.assign({}, entry, { description });

        // return Object.assign({}, entry, { entry.description })
      });
    },
  },

  watch: {
    search(val) {
      // // Items have already been loaded
      // if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading || !val || this.selected) return;
      if (val.length < 3) return;

      this.isLoading = true;

      this.searchItem(val);

      // // Lazily load input items
      // fetch('https://api.publicapis.org/entries')
      //   .then(res => res.json())
      //   .then(res => {
      //     const { count, entries } = res
      //     this.count = count
      //     this.entries = entries
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
      //   //end fetch
    }, //end search val
  },

  created() {
    // this.validator = new Validator({
    //     dob: "required"
    // });
  },
  mounted() {
    EventBus.$on("CLOSE_PATIENT_DIALOG", (payload) => {
      // console.log(Object.keys(payload).length)
      console.log("CALL - CLOSE_PATIENT_DIALOG");
      if (Object.keys(payload).length != 0) {
        this.model = payload.show;
        this.patient = payload.data[0];
        EventBus.$emit("LOAD_PATIENT", payload.data[0]);
      }
      this.patients = null;
    });
    console.log("CLOSE_PATIENT_DIALOG - MOUNTED");
  },
  beforeDestroy() {
    EventBus.$off("CLOSE_PATIENT_DIALOG");
    console.log("CLOSE_PATIENT_DIALOG - DESTROYED");
  },
};
</script>

<style lang="sass">
// .v-card--material-stats
//     display: flex
//     flex-wrap: wrap
//     position: relative

// > div:first-child
//     justify-content: space-between

// .v-card
//     border-radius: 4px
//     flex: 0 1 auto

// .v-card__text
//     display: inline-block
//     flex: 1 0 calc(100% - 120px)
//     position: absolute
//     top: 0
//     right: 0
//     width: 100%

// .v-card__actions
//     flex: 1 0 100%

.patient
    font-weight: bold

// .selectedpatient
//     margin-top: -350px

// .patientdetails
//     height: 200px !important
</style>
