var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"hl7code","name":"HL7 Code","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"hl7code",staticClass:"orange-input",attrs:{"label":"HL7 Code","error-messages":errors,"required":""},model:{value:(_vm.item_.hl7_code),callback:function ($$v) {_vm.$set(_vm.item_, "hl7_code", $$v)},expression:"item_.hl7_code"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"code","name":"Code","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"code",staticClass:"orange-input",attrs:{"label":"Code","error-messages":errors,"required":""},model:{value:(_vm.item_.code),callback:function ($$v) {_vm.$set(_vm.item_, "code", $$v)},expression:"item_.code"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"6","mr-auto":""}},[_c('validation-provider',{attrs:{"vid":"analyte","name":"Analyte","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"analyte",staticClass:"orange-input",attrs:{"label":"Analyte","error-messages":errors,"required":""},model:{value:(_vm.item_.analyte),callback:function ($$v) {_vm.$set(_vm.item_, "analyte", $$v)},expression:"item_.analyte"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"6","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"order_name","name":"Analyte Order Name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"order_name",staticClass:"orange-input",attrs:{"label":"Analyte Order Name","error-messages":errors,"required":""},model:{value:(_vm.item_.analyte_order_name),callback:function ($$v) {_vm.$set(_vm.item_, "analyte_order_name", $$v)},expression:"item_.analyte_order_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"section","name":"Section","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"section",staticClass:"orange-input",attrs:{"items":_vm.sections,"label":"Section","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setSection},model:{value:(_vm.item_.section_id),callback:function ($$v) {_vm.$set(_vm.item_, "section_id", $$v)},expression:"item_.section_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"specimen","name":"Specimen","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"specimen",staticClass:"orange-input",attrs:{"items":_vm.specimens,"label":"Specimen","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setSpecimen},model:{value:(_vm.item_.specimen_id),callback:function ($$v) {_vm.$set(_vm.item_, "specimen_id", $$v)},expression:"item_.specimen_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"unit","name":"Unit","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"unit",staticClass:"orange-input",attrs:{"items":_vm.units,"label":"Unit","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setUnit},model:{value:(_vm.item_.unit_id),callback:function ($$v) {_vm.$set(_vm.item_, "unit_id", $$v)},expression:"item_.unit_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"analytegroup","name":"Analyte Group","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"analytegroup",staticClass:"orange-input",attrs:{"items":_vm.analytegroups,"label":"Analyte Group","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setAnalyteGroup},model:{value:(_vm.item_.analyte_group_id),callback:function ($$v) {_vm.$set(_vm.item_, "analyte_group_id", $$v)},expression:"item_.analyte_group_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"decimal","name":"Decimal","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"decimal",staticClass:"orange-input",attrs:{"items":_vm.decimals,"label":"Decimal","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setDecimal},model:{value:(_vm.item_.decimal),callback:function ($$v) {_vm.$set(_vm.item_, "decimal", $$v)},expression:"item_.decimal"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"operator","name":"Operator","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"operator",staticClass:"orange-input",attrs:{"items":_vm.operators,"label":"Operator","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setOperator},model:{value:(_vm.item_.operator),callback:function ($$v) {_vm.$set(_vm.item_, "operator", $$v)},expression:"item_.operator"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"factor","name":"Factor","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"factor",staticClass:"orange-input",attrs:{"label":"Factor","error-messages":errors,"required":""},model:{value:(_vm.item_.factor),callback:function ($$v) {_vm.$set(_vm.item_, "factor", $$v)},expression:"item_.factor"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"unit2","name":"Secondary Unit","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"unit2",staticClass:"orange-input",attrs:{"items":_vm.units,"label":"Secondary Unit","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setUnit2},model:{value:(_vm.item_.unit_id2),callback:function ($$v) {_vm.$set(_vm.item_, "unit_id2", $$v)},expression:"item_.unit_id2"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"decimal2","name":"Secondary Decimal","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"decimal2",staticClass:"orange-input",attrs:{"items":_vm.decimals,"label":"Secondary Decimal","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setDecimal2},model:{value:(_vm.item_.decimal2),callback:function ($$v) {_vm.$set(_vm.item_, "decimal2", $$v)},expression:"item_.decimal2"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"amount","name":"Amount","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"amount",staticClass:"orange-input",attrs:{"label":"Amount","error-messages":errors,"required":""},model:{value:(_vm.item_.amount),callback:function ($$v) {_vm.$set(_vm.item_, "amount", $$v)},expression:"item_.amount"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"3","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"sort","name":"Sort","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"sort",staticClass:"orange-input",attrs:{"label":"Sort","error-messages":errors,"required":""},model:{value:(_vm.item_.sort),callback:function ($$v) {_vm.$set(_vm.item_, "sort", $$v)},expression:"item_.sort"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"4","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"reporttemplate","name":"Report Template","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"reporttemplate",staticClass:"orange-input",attrs:{"items":_vm.reporttemplates,"label":"Report Template","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setReportTemplate},model:{value:(_vm.item_.report_template_id),callback:function ($$v) {_vm.$set(_vm.item_, "report_template_id", $$v)},expression:"item_.report_template_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"12","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"calculated","name":"Calculated"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Calculated Test","color":"orange"},model:{value:(_vm.item_.calculated),callback:function ($$v) {_vm.$set(_vm.item_, "calculated", $$v)},expression:"item_.calculated"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"12","mx-2":""}},[_c('validation-provider',{attrs:{"vid":"active","name":"Active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.switchlabel,"color":"orange"},on:{"change":function($event){return _vm.setSwitchLabel()}},model:{value:(_vm.item_.active),callback:function ($$v) {_vm.$set(_vm.item_, "active", $$v)},expression:"item_.active"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","text-xs-right":""}},[_c('div',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"mx-1 font-weight-light",attrs:{"color":"dark--grey","outlined":""},on:{"click":function($event){return _vm.clear(true)}}},[_vm._v("Close")]),_vm._v(" "),_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"orange","type":"submit","loading":_vm.loading}},[_vm._v("Save Analyte")])],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }