import {
    fetchItems,
    updateItem,
    addItem,
    deleteItem,
    fetchItemsWithPayload,
    processItemWithPayload
} from '../proc/axios';

export default {
    async fetchItems(context, query) {
        const response = await fetchItems(context.state.api + '?' + query);
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async fetchIncomeReportByResults(context, query) {
        const response = await fetchItems(context.state.baseApi + '/reports/income-report?' + query);
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async searchItem(context, id) {
        const response = await fetchItems(context.state.api + '/search/' + id);
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async fetchDeletedItems(context, query) {
        const response = await fetchItems(
            context.state.api + '/deleted/cs?' + query
        );
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async restoreItem(context, payload) {
        var response = await fetchItemsWithPayload(
            context.state.api + '/restore/' + payload.id,
            payload
        );

        return response;
    },
    async validate(context, id) {
        const response = await fetchItems(
            context.state.api + '/validate/' + id
        );
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async loadAnalytes(context, id) {
        const response = await fetchItems(
            context.state.api + '/loadanalytes/' + id
        );
        return response;
    },
    async updateStatus(context, id) {
        const response = await fetchItems(context.state.api + '/status/' + id);
        // console.log(response)
        context.commit('SAVE_ITEMS', response.data);
        return response;
    },
    async addItem(context, item) {
        const response = await addItem(context.state.api, item);
        // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async updateItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await updateItem(url, item);
        return response;
    },
    async deleteItem(context, item) {
        const url = context.state.api + '/' + item.id;

        const response = await deleteItem(url, item);
        return response;
    },
    async processItem(context, payload) {
        var action = payload.action;
        const url = context.state.api + '/' + payload.item.id;
        var response;

        if (action === 'save_order')
            response = await addItem(context.state.api + '/', payload.item);
        else if (action === 'new')
            response = await addItem(context.state.api, payload.item);
        else if (payload.action === 'edit')
            response = await updateItem(url, payload.item);
        else if (payload.action === 'delete')
            response = await deleteItem(url, payload.item);

        // context.commit('SAVE_ITEMS', response.data)
        return response;
    },
    async getSampleData(context, payload) {
        var response = await fetchItemsWithPayload(
            context.state.api + '/sampledata',
            payload
        );

        return response;
    },
    async updateOrder(context, payload) {
        var response = await processItemWithPayload(
            context.state.api + '/updateorder',
            payload
        );

        return response;
    },
    async updateSampleData(context, payload) {
        // const url = context.state.api + '/' + payload.id;
        // var response = await updateItem(url, payload.item);
        var response = await processItemWithPayload(
            context.state.api + '/updatesampledata',
            payload.item
        );

        return response;
    },

    async getLastVisit(context, payload) {
        var response = await processItemWithPayload(
            context.state.api + '/lastvisit',
            payload
        );

        return response;
    },
    async printChargeslip(context, payload) {
        var response = await processItemWithPayload(
            context.state.api + '/printchargeslip',
            payload
        );

        return response;
    },
    async printRequests(context, payload) {
        var response = await processItemWithPayload(
            context.state.api + '/printrequests',
            payload
        );

        return response;
    },
    async setRefresh(context, payload) {
        context.commit('UPDATE_REFRESH', payload);
    }
};
