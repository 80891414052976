var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Priority","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{ref:"priority",staticClass:"orange-input",attrs:{"label":"Priority","error-messages":errors,"required":""},model:{value:(_vm.item_.priority),callback:function ($$v) {_vm.$set(_vm.item_, "priority", $$v)},expression:"item_.priority"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return _c('v-select',{ref:"sex",staticClass:"orange-input",attrs:{"items":_vm.sex,"label":"Sex","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setSex},model:{value:(_vm.item_.sex),callback:function ($$v) {_vm.$set(_vm.item_, "sex", $$v)},expression:"item_.sex"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('v-spacer')],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Age From","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{ref:"agefrom",staticClass:"orange-input",attrs:{"label":"Age From","error-messages":errors,"required":""},model:{value:(_vm.item_.age_from),callback:function ($$v) {_vm.$set(_vm.item_, "age_from", $$v)},expression:"item_.age_from"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Age To","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{ref:"ageto",staticClass:"orange-input",attrs:{"label":"Age To","error-messages":errors,"required":""},model:{value:(_vm.item_.age_to),callback:function ($$v) {_vm.$set(_vm.item_, "age_to", $$v)},expression:"item_.age_to"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Time Period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return _c('v-select',{ref:"timeperiod",staticClass:"orange-input",attrs:{"items":_vm.timeperiod,"label":"Time Period","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setTimePeriod},model:{value:(_vm.item_.time_period),callback:function ($$v) {_vm.$set(_vm.item_, "time_period", $$v)},expression:"item_.time_period"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return _c('v-select',{ref:"type",staticClass:"orange-input",attrs:{"items":_vm.type,"label":"Type","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setType},model:{value:(_vm.item_.type),callback:function ($$v) {_vm.$set(_vm.item_, "type", $$v)},expression:"item_.type"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Lower Limit","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.range),expression:"!range"}],ref:"lowerlimit",staticClass:"orange-input",attrs:{"label":"Lower Limit","error-messages":errors,"required":"","disabled":_vm.range},model:{value:(_vm.item_.lower_limit),callback:function ($$v) {_vm.$set(_vm.item_, "lower_limit", $$v)},expression:"item_.lower_limit"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Upper Limit","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.range),expression:"!range"}],ref:"upperlimit",staticClass:"orange-input",attrs:{"label":"Upper Limit","error-messages":errors,"required":"","disabled":_vm.range},model:{value:(_vm.item_.upper_limit),callback:function ($$v) {_vm.$set(_vm.item_, "upper_limit", $$v)},expression:"item_.upper_limit"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Symbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return _c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.symbol),expression:"!symbol"}],ref:"symbol",staticClass:"orange-input",attrs:{"items":_vm.symbols,"label":"Symbol","error-messages":errors,"item-text":"name","item-value":"id","color":"orange","disabled":_vm.symbol},on:{"change":_vm.setSymbol},model:{value:(_vm.item_.symbol),callback:function ($$v) {_vm.$set(_vm.item_, "symbol", $$v)},expression:"item_.symbol"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Value","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.symbol),expression:"!symbol"}],ref:"value",staticClass:"orange-input",attrs:{"label":"Value","error-messages":errors,"required":"","disabled":_vm.symbol},model:{value:(_vm.item_.value),callback:function ($$v) {_vm.$set(_vm.item_, "value", $$v)},expression:"item_.value"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md12":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Range in Text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.text),expression:"!text"}],ref:"textrange",staticClass:"orange-input",attrs:{"label":"Range in Text","error-messages":errors,"required":"","disabled":_vm.text},model:{value:(_vm.item_.text_range),callback:function ($$v) {_vm.$set(_vm.item_, "text_range", $$v)},expression:"item_.text_range"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md12":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Force Secondary Reference Range"}},[_c('v-switch',{attrs:{"label":"Force Secondary Reference Range","color":"orange"},on:{"change":_vm.setSecondary},model:{value:(_vm.item_.enable_2nd_range),callback:function ($$v) {_vm.$set(_vm.item_, "enable_2nd_range", $$v)},expression:"item_.enable_2nd_range"}})],1)],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md12":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Secondary Reference Range"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return _c('v-text-field',{ref:"range2",staticClass:"orange-input",attrs:{"label":"Range in Text","error-messages":errors,"required":"","disabled":_vm.range2},model:{value:(_vm.item_.secondary_range),callback:function ($$v) {_vm.$set(_vm.item_, "secondary_range", $$v)},expression:"item_.secondary_range"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md3":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Instrument","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return _c('v-select',{ref:"instrument",staticClass:"orange-input",attrs:{"items":_vm.instruments,"label":"Instrument","error-messages":errors,"item-text":"instrument","item-value":"id","color":"orange"},on:{"change":_vm.setInstrument},model:{value:(_vm.item_.instrument_id),callback:function ($$v) {_vm.$set(_vm.item_, "instrument_id", $$v)},expression:"item_.instrument_id"}})}}],null,true)})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md12":"","mx-2":""}},[_c('ValidationProvider',{attrs:{"name":"Active"}},[_c('v-switch',{attrs:{"label":"Active","color":"orange"},model:{value:(_vm.item_.active),callback:function ($$v) {_vm.$set(_vm.item_, "active", $$v)},expression:"item_.active"}})],1)],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md12":"","mx-2":"","text-xs-left":""}},[_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"orange","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.processItem}},[_vm._v("\n                  "+_vm._s(_vm.getButtonLabel)+"\n                ")]),_vm._v(" "),_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"orange"},on:{"click":function($event){return _vm.clear(false)}}},[_vm._v("\n                  Clear\n                ")])],1)],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }