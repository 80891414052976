import EventBus from '@/plugins/eventbus';
import {
    processItemWithPayload
} from '../proc/axios';

export async function updateDefaultBranch(payload) {
    console.log('CHANGE BRANCH');
    const response = await processItemWithPayload('/api/usersettings/setbranch', payload)
        .then(response => {
            localStorage.setItem('default_branch', JSON.stringify(response.data.default_branch));
            return response;
        })
        .catch(err => {
            return (err);
        });
    // context.commit('SAVE_ITEMS', response.data);
    console.log('updateDefaultBranch', response);
    return response;
}

export default updateDefaultBranch;
