
export default {
	api: '/api/units',
	items: [],
	buttontip: 'New Unit',
	title: 'Units',
	text: 'Laboratory Units',
	options: [
		{
			action: 'new',
			label: 'New Unit',
			actiontext: ['Enter New Unit'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Unit',
			actiontext: ['Edit Unit'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Unit',
			actiontext: 'Delete Unit',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: true,
          text: 'Unit',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
}