
export default {
	api: '/api/referenceranges',
	items: [],
	buttontip: 'New Reference Range',
	title: 'Reference Range',
	text: 'Analyte Reference Range',
	options: [
		{
			action: 'new',
			label: 'New Reference Range',
			actiontext: ['Enter New Reference Range', 'Enter New Reference Range'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Unit',
			actiontext: ['Edit Reference Range', 'Edit Reference Range'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Reference Range',
			actiontext: 'Delete Reference Range',
			icon: 'mdi-delete'
		}
	],
	headers: [
        // {
        //   sortable: true,
        //   text: 'ID',
        //   value: 'id',
        //   align: 'right'
        // },
        {
          sortable: true,
          text: 'Priority',
          value: 'priority',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Sex',
          value: 'sex',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Age From',
          value: 'age_from',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Age To',
          value: 'age_to',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Time Period',
          value: 'time_period',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Type',
          value: 'type',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Lower Limit',
          value: 'lower_limit',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Upper Limit',
          value: 'upper_limit',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Symbol',
          value: 'symbol',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Value',
          value: 'value',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Text Range',
          value: 'text_range',
          align: 'right'
        },
        // {
        //   sortable: true,
        //   text: '2nd Range',
        //   value: 'enable_2nd_range',
        //   align: 'right'
        // },
        // {
        //   sortable: true,
        //   text: 'Secondary Range',
        //   value: 'secondary_range',
        //   align: 'right'
        // },
        {
          sortable: true,
          text: 'Instrument',
          value: 'instrument_id',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Active',
          value: 'active',
          align: 'right'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'right'
        }
      ],
}