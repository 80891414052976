var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-lisa-material-card',{staticClass:"px-5 py-3",attrs:{"color":"info","icon":"mdi-file-document-multiple","inline":"","title":_vm.getTitle}},[_c('div',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"top":"","content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","color":"success","small":""},on:{"click":function($event){return _vm.showDialog('new')}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-spacer'),_vm._v(" "),_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("NEW\n          ")],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("New Analyte")])])],1),_vm._v(" "),_c('v-text-field',{staticClass:"ml-1",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Search Content","hide-details":"","single-line":"","color":"grey darken-1"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-divider',{staticClass:"mt-3"}),_vm._v(" "),_c('v-data-table',{key:_vm.dataTableKey,attrs:{"headers":_vm.itemheaders,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text-warning text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"lisa-font-weight-bold"},[_c('v-chip',{attrs:{"color":_vm.setColor(item.active),"dark":"","small":""}},[_vm._v(_vm._s(_vm.setActiveLabel(item.active)))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn--simple",attrs:{"slot":"activator","color":"success","icon":""},on:{"click":function($event){return _vm.editItem(item)}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Edit "+_vm._s(item.analyte))])])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.analytedialog),callback:function ($$v) {_vm.analytedialog=$$v},expression:"analytedialog"}},[_c('admin-analytedialog',{key:_vm.analyteDialogKey,attrs:{"analyteData":_vm.itemdata}})],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.optSnackbar.color,"top":"","right":"","dark":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"white"}},[_vm._v("\n        "+_vm._s(_vm.optSnackbar.icon)+"\n      ")]),_vm._v(" "),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.optSnackbar.title))]),_vm._v("\n        - "+_vm._s(_vm.optSnackbar.msg)+"\n      ")]),_vm._v(" "),_c('v-icon',{attrs:{"size":"14"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("mdi-close-circle")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }