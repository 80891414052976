export default {
    api: "/api/usersettings",
    usersettings: [],
    primary: "orange",
    snackbarsettings: [
        {
            option: "success",
            color: "success",
            title: "SUCCESS",
            msg: "Saving Successful!",
            icon: "mdi-check-circle"
        },
        {
            option: "info",
            color: "info",
            title: "INFO",
            msg: "Saving Successful!",
            icon: "mdi-information"
        },
        {
            option: "error",
            color: "error",
            title: "ERROR",
            msg: "Process Failed. Please contact administrator.",
            icon: "mdi-alert"
        },
        {
            option: "warning",
            color: "warning",
            title: "WARNING",
            msg: "Please check.",
            icon: "mdi-exclamation"
        }
    ],
    optSuccessSnackbar: {
        color: "success",
        title: "SUCCESS",
        msg: "Saving Successful!",
        icon: "mdi-comment-check"
    },
    optFailSnackbar: {
        color: "red",
        title: "ERROR",
        msg: "Process Failed. Please contact administrator.",
        icon: "mdi-alert-decagram"
    },
    errors: {}
};
