export default {
    // UPDATE_TERMINAL_ID(state, payload) {
    //     // console.log('UPDATE_TERMINAL_ID', payload);
    //     state.terminal_id = payload;
    // },
    UPDATE_ANALYTE_ID(state, payload) {
        state.analyte_id = payload;
    },
    UPDATE_ANALYTE_SETTINGS(state, payload) {
        state.analyteSettings = payload;
    },
    UPDATE_SELECTED_ANALYTES(state, payload) {
        state.selected_analytes = payload;
    },
    UPDATE_RESULTS_DATA(state, payload) {
        state.results_data = payload;
    },
    UPDATE_USER_SETTINGS(state, payload) {
        state.results_data.user_settings = payload;
    },
    SET_REFERENCE_RANGES(state, payload) {
        state.reference_ranges = payload;
    },
    SAVE_ITEMS(state, payload) {
        state.items = payload;
    },
    SAVE_ANALYTES(state, payload) {
        state.analytes = payload;
        // state.currentUser = Object.assign({}, payload.user, {
        //     token: payload.access_token
        // });
        localStorage.setItem('analytes', JSON.stringify(payload));
    },
    SAVE_APP_SETTINGS(state, payload) {
        state.app_settings = payload;
        localStorage.setItem('app_settings', JSON.stringify(payload));
        localStorage.setItem('default_branch', JSON.stringify(payload.default_branch));
        // localStorage.setItem('analytes', JSON.stringify(payload));
    },
    UPDATE_REFRESH(state, payload) {
        state.refresh = payload;
    }
};
