
export default {
	api: '/api/patients',
	items: [],
	buttontip: 'New Patient',
	title: 'Patients',
	text: 'Laboratory Patients',
	options: [
		{
			action: 'new',
			label: 'New Patient',
			actiontext: ['Enter New Patient'],
			icon: 'mdi-new-box'
		},
		{
			action: 'edit',
			label: 'Edit Patient',
			actiontext: ['Edit Patient'],
			icon: 'mdi-pencil'
		},
		{
			action: 'delete',
			label: 'Delete Patient',
			actiontext: 'Delete Patient',
			icon: 'mdi-delete'
		}
	],
	headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: true,
          text: 'Specimen Code',
          value: 'code'
        },
        {
          sortable: true,
          text: 'Specimen',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'actions',
          align: 'left'
        }
      ],
}