export default {
    api: '/api/pathologists',
    items: [],
    buttontip: 'New Pathologist',
    title: 'Pathologist',
    text: 'Laboratory Pathologist',
    options: [
        {
            action: 'new',
            label: 'New Pathologist',
            actiontext: ['Enter New Pathologist', 'Default'],
            icon: 'mdi-new-box'
        },
        {
            action: 'edit',
            label: 'Edit Pathologist',
            actiontext: ['Edit Pathologist', 'Edit Default'],
            icon: 'mdi-pencil'
        },
        {
            action: 'delete',
            label: 'Delete Pathologist',
            actiontext: 'Delete Pathologist',
            icon: 'mdi-delete'
        }
    ],
    headers: [
        {
            sortable: true,
            text: 'ID',
            value: 'id'
        },
        {
            sortable: true,
            text: 'Pathologist',
            value: 'name'
        },
        {
            sortable: true,
            text: 'Default',
            value: 'default'
        },
        {
            sortable: false,
            text: 'Actions',
            value: 'actions',
            align: 'left'
        }
    ]
};
