// import Vue from 'vue';
// import Vuetify from 'vuetify/lib';

// Vue.use(Vuetify);

// const opts = {};

// export default new Vuetify(opts);

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@/i18n';
import '@/sass/_variables.scss';
//import '@/sass/overrides.sass';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css';
import 'font-awesome/css/font-awesome.min.css';

Vue.use(Vuetify);

const theme = {
    primary: '#E91E63',
    secondary: '#9C27b0',
    accent: '#9C27b0',
    info: '#00CAE3'
};

export default new Vuetify({
    icons: {
        iconfont: 'fa4'
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },
    theme: {
        themes: {
            dark: theme,
            light: theme
        }
    }
});
