import axios from 'axios';

// axios.defaults.headers.common["Content-Type"] =
//     axios.defaults.headers.common = {
//         // "Authorization": `Bearer ${token}`,
//         'X-Requested-With': 'XMLHttpRequest',
//         'Content-Type': 'application/json'
//     };

export function fetchItems(url) {
    return new Promise((res, rej) => {
        axios
            .get(url)
            .then(response => {
                // console.log(response.data)
                res(response.data);
            })
            .catch(err => {
                rej(err);
            });
    });
}

export function fetchItemsWithPayload(url, payload) {
    return new Promise((res, rej) => {
        axios
            .post(url, payload)
            .then(response => {
                res(response);
            })
            .catch(err => {
                rej(err);
            });
    });
}

export function processItemWithPayload(url, payload) {
    return new Promise((res, rej) => {
        axios
            .post(url, payload)
            .then(response => {
                res(response);
            })
            .catch(err => {
                rej(err);
            });
    });
}

export function updateItem(url, item) {
    return new Promise((res, rej) => {
        axios
            .put(url, item)
            .then(response => {
                res(response);
            })
            .catch(err => {
                // console.log('error', Object.assign({}, err));
                // console.log(err.response.data)
                // rej(err.reponse.data)
                // if(err.reponse.status === 422)
                //     console.log('YES 422')

                rej(err);
            });
    });
}

export function updateItemPost(url, item) {
    return new Promise((res, rej) => {
        axios
            .post(url, item)
            .then(response => {
                res(response);
            })
            .catch(err => {
                rej(err);
            });
    });
}
// { headers: {
//             'Content-Type': 'application/json',
//         }}
export function addItem(url, item) {
    return new Promise((res, rej) => {
        axios
            .post(url, item)
            .then(response => {
                // console.log('addItemRES', response);
                res(response);
            })
            .catch(err => {
                // console.log('err_addItemREJ', err.response);
                rej(err);
            });
    });
}

export function deleteItem(url) {
    return new Promise((res, rej) => {
        axios
            .delete(url)
            .then(response => {
                res(response);
            })
            .catch(err => {
                rej(err);
            });
    });
}
