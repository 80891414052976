// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

require('./bootstrap');

window.Vue = require('vue');

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/base';
import './plugins/chartist';
import './plugins/vee-validate';
// import VeeValidate from "vee-validate";
import './plugins/vue-world-map';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

import { initialize } from './store/modules/proc/general';
//import { sync } from 'vuex-router-sync';

import VueHtmlToPaperFull from './plugins/vuehtmltopaperfull';
// import VueHtmlToPaperFull_Result from './plugins/vuehtmltopaperfull';
// Components
import './components';

const options = {
    name: '_blank',
    specs: ['fullscreen=no', 'titlebar=yes', 'scrollbars=yes'],
    styles: ['/styles/chargeslip.css'],
    title: 'CHARGESLIP'
};

// const options2 = {
//     name: '_blank',
//     specs: ['fullscreen=no', 'titlebar=yes', 'scrollbars=yes'],
//     styles: ['/styles/result.css'],
//     title: 'RESULT'
// };

Vue.use(VueHtmlToPaperFull, options);
// Vue.use(VueHtmlToPaperFull_Result, options2);

// Vue.use(VeeValidate);
Vue.config.productionTip = false;
initialize(store, router);
//sync(store, router);
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
