<template>
  <v-container>
    <base-lisa-material-card
      color="info"
      icon="mdi-file-document-multiple"
      inline
      class="px-5 py-3"
      :title="getTitle"
    >
      <!-- :text="getText" -->
      <div class="text-xs-right">
        <v-tooltip top content-class="top">
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn
                            slot="activator"
                            color="success"
                            small
                            v-on:click="$emit('set-option', getOption)"
            >-->
            <v-btn
              slot="activator"
              color="success"
              small
              v-on:click="showDialog('new')"
              v-bind="attrs"
              v-on="on"
            >
              <v-spacer></v-spacer>
              <v-icon>mdi-plus</v-icon>NEW
            </v-btn>
          </template>
          <span>New Analyte</span>
        </v-tooltip>
      </div>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-1"
        label="Search Content"
        hide-details
        single-line
        style="max-width: 250px"
        color="grey darken-1"
      />

      <v-divider class="mt-3" />

      <!-- <v-data-table
        :headers="headers"
        :items="items"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="sample_details.barcode"
        show-expand
        class="elevation-3"
        :search="search"
        :loading="loading"
        loader-height="3"
        loading-text="Loading data..."
        color="info"
        :footer-props="footerProps"
      >-->

      <v-data-table
        :headers="itemheaders"
        :items="items"
        :search="search"
        :key="dataTableKey"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span
            class="font-weight-light text-warning text--darken-3"
            v-text="header.text"
          />
        </template>

        <template v-slot:item.active="{ item }">
          <span class="lisa-font-weight-bold">
            <v-chip :color="setColor(item.active)" dark small>{{
              setActiveLabel(item.active)
            }}</v-chip>
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top content-class="top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                slot="activator"
                class="v-btn--simple"
                color="success"
                icon
                @click="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit {{ item.analyte }}</span>
          </v-tooltip>

          <!-- <v-tooltip top content-class="top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                slot="activator"
                class="v-btn--simple"
                color="danger"
                icon
                @click="deleteItem(item)"
                v-if="module != 'analytes'"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>-->
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="deep-purple accent-4"
                v-bind="attrs"
                v-on="on"
                @click="validate(item)"
              >
                Edit
                <v-icon right>mdi-file-document-edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Analyte</span>
          </v-tooltip>-->
        </template>
      </v-data-table>
      <!-- <v-dialog v-model="dialog" width="50%" persistent>
        <admin-dialog />
      </v-dialog> -->

      <v-dialog v-model="analytedialog" persistent>
        <admin-analytedialog :analyteData="itemdata" :key="analyteDialogKey" />
      </v-dialog>

      <v-snackbar :color="optSnackbar.color" top right v-model="snackbar" dark>
        <v-icon color="white" class="mr-3">
          <!-- mdi-bell-plus -->
          {{ optSnackbar.icon }}
        </v-icon>
        <div>
          <strong>{{ optSnackbar.title }}</strong>
          - {{ optSnackbar.msg }}
        </div>
        <v-icon size="14" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>
    </base-lisa-material-card>
  </v-container>
</template>

<script>
import EventBus from '@/plugins/eventbus';
export default {
  props: {
    module: {
      type: String, // [1*] This will validate the 'max' prop to be a Number.
      default() {
        return '';
      },
    },
    // analyteData: {
    //   type: Object, // [1*] This will validate the 'max' prop to be a Number.
    //   default() {
    //     return {};
    //   },
    // },
  },

  data: () => ({
    analyteDialogKey: 0,
    dataTableKey: 0,
    search: null,
    title: null,
    text: null,
    ButtonTip: '',
    items: [],
    dialog: false,
    analytedialog: false,
    optData: {},
    itemdata: {},
    color: null,
    colors: ['purple', 'info', 'success', 'warning', 'error'],
    snackbar: false,
    itemheaders: [
      {
        sortable: true,
        text: 'ID',
        value: 'id',
      },
      {
        sortable: true,
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'left',
      },
    ],
    optSnackbar: {},
    options: [],
    snackbarsettings: [],
    // itemdata: {},
    action: '',
    refresh: true,
    refreshparameters: true,
    onprocess: false,
    sections: [],
    specimens: [],
    units: [],
    analytegroups: [],
    reporttemplates: [],
    instruments: [],
    loading: true,
    analyte_id: null,
  }),
  methods: {
    async fetchItems() {
      if (this.refresh == true) {
        // if(this.module != 'analytes')
        this.refresh = false;
        // console.log('fetchItems: ' + this.module);
        this.itemdata = {};
        await this.$store
          .dispatch(this.module + '/fetchItems')
          .then((res) => {
            console.log('res_analytes', res.data);
            ++this.dataTableKey;
            this.items = res.data;
            this.refresh = false;
            this.loading = false;
          })
          .catch((error) => {
            var notif = {
              option: 'fail',
              msg: 'Error in getting ' + this.title + ' data.',
            };
            this.showNotification(notif);
            // console.log(error)
          });

        if (this.module === 'analytes' && this.refreshparameters) {
          await this.$store
            .dispatch('sections/fetchItems')
            .then((res) => {
              this.sections = res.data;
              this.refresh = false;
            })
            .catch((error) => {
              var notif = {
                option: 'fail',
                msg: 'Error in getting Sections data.',
              };
              this.showNotification(notif);
              // console.log(error)
            });

          await this.$store
            .dispatch('specimens/fetchItems')
            .then((res) => {
              this.specimens = res.data;
            })
            .catch((error) => {
              var notif = {
                option: 'fail',
                msg: 'Error in getting Specimens data.',
              };
              this.showNotification(notif);
              // console.log(error)
            });

          await this.$store
            .dispatch('units/fetchItems')
            .then((res) => {
              this.units = res.data;
            })
            .catch((error) => {
              var notif = {
                option: 'fail',
                msg: 'Error in getting Units data.',
              };
              this.showNotification(notif);
              // console.log(error)
            });

          await this.$store
            .dispatch('analytegroups/fetchItems')
            .then((res) => {
              this.analytegroups = res.data;
            })
            .catch((error) => {
              var notif = {
                option: 'fail',
                msg: 'Error in getting Analyte Groups data.',
              };
              this.showNotification(notif);
              // console.log(error)
            });

          await this.$store
            .dispatch('reporttemplates/fetchItems')
            .then((res) => {
              this.reporttemplates = res.data;
            })
            .catch((error) => {
              var notif = {
                option: 'fail',
                msg: 'Error in getting Report Templates data.',
              };
              this.showNotification(notif);
              // console.log(error)
            });

          await this.$store
            .dispatch('instruments/fetchItems')
            .then((res) => {
              // console.log('instruments', res.data);
              this.instruments = res.data;
            })
            .catch((error) => {
              var notif = {
                option: 'fail',
                msg: 'Error in getting Instruments data.',
              };
              // this.showNotification(notif);
              // console.log(error)
              // notif = {
              //   option: 'error',
              //   msg:
              //     error.response.data.message +
              //     ' (' +
              //     error.response.status +
              //     ')',
              //   error: error,
              // };
              EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
            });

          this.refreshparameters = false;
          this.refresh = false;
        }
      }
    },
    async processItem(payload) {
      // console.log('processItem ' + this.onprocess)
      // console.log('this.action: ' + this.action)
      let notif = {};
      if (this.onprocess == true) {
        this.onprocess = false;
        // console.log('payload.action: ' + payload.action);
        var module = payload.module;

        if (module === '' || module === undefined) {
          module = this.module;
        }
        console.log('payload', payload);
        this.$store
          .dispatch(module + '/processItem', payload)
          .then((res) => {
            // console.log('res.data', res.data);
            this.items = res.data.data;
            notif = { option: 'success', msg: '', show: true };
            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

            if (this.action === 'new' && !payload.othercomponent) {
              this.onprocess = true;
              this.refresh = true;

              EventBus.$emit('CLEAR_DIALOG', false);
              // } else if (this.action !='new' && module != 'referenceranges') {
            } else if (payload.action === 'delete' && payload.othercomponent) {
              // EventBus.$emit('CLEAR_DIALOG', false);
              if (payload.module === 'referenceranges')
                EventBus.$emit('LOAD_REFERENCE_RANGE');
              notif = {
                option: 'success',
                msg: 'Delete Successful!',
              };
              EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);
              this.dialog = false;
            } else if (this.action != 'new') {
              EventBus.$emit('CLEAR_DIALOG', true);
            }
          })
          .catch((error) => {
            notif = {
              option: 'error',
              msg:
                error.response.data.message +
                ' (' +
                error.response.status +
                ')',
              error: error,
            };
            EventBus.$emit('SHOW_NOTIFICATION_SNACKBAR', notif);

            // EventBus.$emit('CLEAR_DIALOG');
          });
      }
    },
    init() {
      // this.ButtonTip = this.$store.getters[this.module + '/getButtonTip']
      this.options = this.$store.getters[this.module + '/getOptions'];
      // Object.keys(this.options).forEach(key => console.log(key, this.options[key]));
      this.ButtonTip = this.options[0].label;
      this.snackbarsettings = this.$store.getters[
        'settings/getSnackbarSettings'
      ];
      this.title = this.$store.getters[this.module + '/getTitle'];
      this.text = this.$store.getters[this.module + '/getText'];
      this.itemheaders = this.$store.getters[this.module + '/getHeaders'];
      // this.refresh = this.$store.getters[this.module + '/refresh']
    },
    showDialog(opt) {
      ++this.analyteDialogKey;
      this.onprocess = true;
      this.refresh = true;
      this.action = opt;
      var [option] = this.options.filter((el) => el.action == opt);
      // console.log(this.module)

      // if(opt === 'new') {
      //   EventBus.$emit('SET_DIALOG_OPTION', this.options[0], this.itemdata);
      // } else if(opt === 'edit') {
      //   EventBus.$emit('SET_DIALOG_OPTION', this.options[1], this.itemdata);
      // } else {
      //   EventBus.$emit('SET_DIALOG_OPTION', this.options[2], this.itemdata);
      // }
      this.$store.dispatch('cache/setAnalyteID', this.itemdata.id);
      if (opt === 'new') {
        this.itemdata = {};
      }
      if (this.module == 'analytes') {
        EventBus.$emit(
          'SET_ANALYTE_DIALOG_OPTION',
          option,
          this.itemdata,
          this.module,
          this.sections,
          this.specimens,
          this.units,
          this.analytegroups,
          this.reporttemplates,
          this.instruments
        );

        let analyteSettings = {
          option: option,
          itemdata: this.itemdata,
          module: this.module,
          sections: this.sections,
          specimens: this.specimens,
          units: this.units,
          analytegroups: this.analytegroups,
          reporttemplates: this.reporttemplates,
          instruments: this.instruments,
        };

        this.$store.dispatch('cache/setAnalyteSettings', analyteSettings);
        // EventBus.$emit('SET_REFRANGE_OPTION', option, this.instruments);
        // this.setAnalyteID(this.itemdata.id)

        EventBus.$emit('LOAD_REFERENCE_RANGE');
        this.analytedialog = true;
      } else {
        EventBus.$emit('SET_DIALOG_OPTION', option, this.itemdata, this.module);
        this.dialog = true;
      }
    },
    showDeleteDialog(opt, itemdata, module) {
      this.onprocess = true;
      this.refresh = true;

      var options = this.$store.getters[module + '/getOptions'];
      var [option] = options.filter((el) => el.action == opt);

      EventBus.$emit('SET_DIALOG_OPTION', option, itemdata, module, true);
      this.dialog = true;
    },
    async setAnalyteID(id) {
      this.$store
        .dispatch('cache/setAnalyteID', id)
        .then((res) => {
          this.analyte_id = id;
        })
        .catch((error) => {});
    },
    async showNotification(notification) {
      // console.log('showNotification')

      if (notification.show == false) {
        this.dialog = false;
        return;
      }

      var opt = notification.option;
      var [option] = this.snackbarsettings.filter((el) => el.option == opt);

      this.optSnackbar = Object.assign({}, option);

      if (this.action === 'delete') this.optSnackbar.msg = 'Delete Success!';

      if (notification.msg != (null || ''))
        this.optSnackbar.msg = notification.msg;

      // if(opt === 'success') {
      //   this.optSnackbar = () => { return this.snackbarsettings[0] }
      //   if(this.action === 'delete') {
      //     this.optSnackbar.msg = 'Success!'
      //   }
      //   console.log(this.snackbarsettings[0])
      // } else if(opt === 'fail') {
      //   this.optSnackbar = this.snackbarsettings[1]
      //   if(msg != '') {
      //     this.optSnackbar.msg = msg
      //   }
      // }
      // this.snackbar = true;
    },
    editItem(item) {
      // this.itemdata.id = id
      // this.itemdata.name = name
      //   ++this.analyteDialogKey;
      console.log('ANALYTECRUD - this.item', this.item);
      this.itemdata = item;

      this.showDialog('edit');
    },
    deleteItem(item) {
      // this.itemdata.id = id
      // this.itemdata.name = name
      //   ++this.analyteDialogKey;
      this.itemdata = item;
      this.showDialog('delete');
    },
    setValue(value) {
      if (value === 'NO') {
        return '<v-chip>Example Chip</v-chip>';
      } else {
        return '<v-chip>Example Chip</v-chip>';
      }
    },
    setColor(value) {
      if (value === 'YES' || value === 1) {
        this.items.active = 'YES';
        return 'orange';
      } else {
        return '';
      }
    },
    setActiveLabel(value) {
      if (value === 'YES' || value === 1) {
        return 'YES';
      } else {
        return 'NO';
      }
    },

    checkModule() {
      if (this.module != 'pathologists' && this.module != 'analytes') {
        return true;
      } else {
        return false;
      }
    },
    analyteModule() {
      if (this.module == 'analytes') return true;
      else return false;
    },
  },
  computed: {
    getTitle() {
      return this.title;
    },
    getText() {
      return this.text;
    },
    getOption() {
      return this.module;
    },
  },
  mounted() {
    this.init();
    this.fetchItems();
    // EventBus.$on('EVENT_NAME', (payload) => {
    //   this.testAlert()
    // }),
    // EventBus.$on('CLOSE_DIALOG', (payload) => {
    //   if (this.module == payload) this.fetchItems();
    //   this.dialog = false;
    // }),
    EventBus.$on('CLOSE_ANALYTE_DIALOG', (payload) => {
      this.fetchItems();
      this.analytedialog = false;

      EventBus.$emit('ANALYTE_INITIALIZE_TAB');
    });
    // EventBus.$on('SAVE_ITEM', (payload) => {
    //   // alert(payload.item)
    //   this.dialog = false;
    // }),
    // EventBus.$on('SHOW_NOTIFICATION', (payload) => {
    //   this.showNotification(payload);
    // }),
    // EventBus.$on('SHOW_DELETE_DIALOG', (payload, module) => {
    //   // console.log(payload)
    //   // console.log(module)
    //   this.showDeleteDialog('delete', payload, module);
    // }),
    // // EventBus.$on('SHOW_ONCE', (payload) => {
    // //   this.showonce = payload
    // // }),
    EventBus.$on('PROCESS_ANALYTE', (payload) => {
      this.processItem(payload);
    });
    // EventBus.$on('CALL_EVENT_BUS', () => {
    //   console.log('CALL_EVENT_BUS');
    // });
  },
  beforeDestroy() {
    EventBus.$off('PROCESS_ANALYTE');
    EventBus.$off('CLOSE_ANALYTE_DIALOG');
  },
  created() {},
  ready() {
    events.$on('eventGreet', () => {
      alert('from Parent');
    });
  },
};
</script>

<style>
/*
.v-datatable__actions {
  display: none;
} */
#core-view {
  padding-bottom: 100px;
}
.v-btn {
  color: orange;
}
.v-btn--icon {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  padding: 0px !important;
}

/* .v-btn--round {
} */
</style>
