var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"xs":"12","md":"12"}},[_c('v-btn',{ref:"new",attrs:{"color":"primary"},on:{"click":function($event){return _vm.newRefRange()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("NEW REFERENCE RANGE\n      ")],1)],1)],1),_vm._v(" "),_c('v-expand-transition',[(_vm.expand)?_c('div',[_c('v-row',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('v-row',[_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"priority","name":"Priority","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"priority",staticClass:"orange-input",attrs:{"label":"Priority","error-messages":errors,"required":""},model:{value:(_vm.item_.priority),callback:function ($$v) {_vm.$set(_vm.item_, "priority", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.priority"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"sex","name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"sex",staticClass:"orange-input",attrs:{"items":_vm.sex,"label":"Sex","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setSex},model:{value:(_vm.item_.sex),callback:function ($$v) {_vm.$set(_vm.item_, "sex", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.sex"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"agefrom","name":"Age From","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"agefrom",staticClass:"orange-input",attrs:{"label":"Age From","error-messages":errors,"required":""},model:{value:(_vm.item_.age_from),callback:function ($$v) {_vm.$set(_vm.item_, "age_from", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.age_from"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"ageto","name":"Age To","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"ageto",staticClass:"orange-input",attrs:{"label":"Age To","error-messages":errors,"required":""},model:{value:(_vm.item_.age_to),callback:function ($$v) {_vm.$set(_vm.item_, "age_to", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.age_to"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"timeperiod","name":"Time Period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"timeperiod",staticClass:"orange-input",attrs:{"items":_vm.timeperiod,"label":"Time Period","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setTimePeriod},model:{value:(_vm.item_.time_period),callback:function ($$v) {_vm.$set(_vm.item_, "time_period", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.time_period"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"type","name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"type",staticClass:"orange-input",attrs:{"items":_vm.type,"label":"Type","error-messages":errors,"item-text":"name","item-value":"id","color":"orange"},on:{"change":_vm.setType},model:{value:(_vm.item_.type),callback:function ($$v) {_vm.$set(_vm.item_, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.type"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"lower_limit","name":"Lower LImit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.range)?_c('v-text-field',{ref:"lowerlimit",staticClass:"orange-input",attrs:{"label":"Lower Limit","error-messages":errors,"required":"","disabled":_vm.range},model:{value:(_vm.item_.lower_limit),callback:function ($$v) {_vm.$set(_vm.item_, "lower_limit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.lower_limit"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"upper_limit","name":"Upper Limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.range)?_c('v-text-field',{ref:"upperlimit",staticClass:"orange-input",attrs:{"label":"Upper Limit","error-messages":errors,"required":"","disabled":_vm.range},model:{value:(_vm.item_.upper_limit),callback:function ($$v) {_vm.$set(_vm.item_, "upper_limit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.upper_limit"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"symbol","name":"Symbol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.symbol)?_c('v-select',{ref:"symbol",staticClass:"orange-input",attrs:{"items":_vm.symbols,"label":"Symbol","error-messages":errors,"item-text":"name","item-value":"id","color":"orange","disabled":_vm.symbol},on:{"change":_vm.setSymbol},model:{value:(_vm.item_.symbol),callback:function ($$v) {_vm.$set(_vm.item_, "symbol", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.symbol"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"value","name":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.symbol)?_c('v-text-field',{ref:"value",staticClass:"orange-input",attrs:{"label":"Value","error-messages":errors,"required":"","disabled":_vm.symbol},model:{value:(_vm.item_.value),callback:function ($$v) {_vm.$set(_vm.item_, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.value"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"textrange","name":"Range in Text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.text)?_c('v-textarea',{ref:"textrange",staticClass:"orange-input",attrs:{"label":"Range in Text","error-messages":errors,"required":"","disabled":_vm.text},model:{value:(_vm.item_.text_range),callback:function ($$v) {_vm.$set(_vm.item_, "text_range", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.text_range"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Force Secondary Reference Range"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":"Force Secondary Reference Range","color":"orange"},on:{"change":_vm.setSecondary},model:{value:(_vm.secondary),callback:function ($$v) {_vm.secondary=$$v},expression:"secondary"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"secondrange","name":"Secondary Reference Range","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.range2)?_c('v-text-field',{ref:"secondrange",staticClass:"orange-input",attrs:{"label":"Secondary Reference Range","error-messages":errors,"required":""},model:{value:(_vm.item_.secondary_range),callback:function ($$v) {_vm.$set(_vm.item_, "secondary_range", $$v)},expression:"item_.secondary_range"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"instrument","name":"Instrument","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"instrument",staticClass:"orange-input",attrs:{"items":_vm.instruments,"label":"Instrument","error-messages":errors,"item-text":"instrument","item-value":"id","color":"orange"},on:{"change":_vm.setInstrument},model:{value:(_vm.item_.instrument_id),callback:function ($$v) {_vm.$set(_vm.item_, "instrument_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item_.instrument_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{staticClass:"mx-2",attrs:{"xs":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"active","name":"active"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":"Active","color":"orange"},on:{"change":_vm.setActive},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","md":"12","ma-3":"","text-xs-left":""}},[_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"indigo","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.processItem()}}},[_vm._v("\n                  "+_vm._s(_vm.getButtonLabel())+"\n                ")]),_vm._v(" "),_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"dark--grey","outlined":""},on:{"click":function($event){return _vm.clearAndRefresh()}}},[_vm._v("Clear")])],1)],1)],1)]}}],null,false,334076937)})],1)],1):_vm._e()]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.refrangeheaders,"items":_vm.items},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text-warning text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item.time_period",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"lisa-font-weight-bold"},[_vm._v(_vm._s(_vm.setTimePeriodLabel(item.time_period)))])]}},{key:"item.instrument",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"lisa-font-weight-bold"},[_vm._v(_vm._s(_vm.setInstrument(item.instrument_id)))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"lisa-font-weight-bold"},[_c('v-chip',{attrs:{"color":_vm.setColor(item.active),"dark":"","small":true}},[_vm._v(_vm._s(_vm.setActiveLabel(item.active)))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn--simple",attrs:{"slot":"activator","color":"success","icon":""},on:{"click":function($event){return _vm.editItem(item)}},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Edit")])])]}}])})],1),_vm._v(" "),_c('v-col',{attrs:{"xs":"12","text-xs-right":"","ma-5":""}},[_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"orange"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }