import axios from 'axios';

import { getLocalUser } from './auth';
// import { mapState, mapMutations, mapActions } from 'vuex';
// import store from '../../../store';
// import router from '../../../router';

export function initialize(store, router) {
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(
            record => record.meta.requiresAuth
        );

        // const currentUser = this.$store.getters.currentUser;
        // console.log('currentUser: ' + currentUser)
        const user = getLocalUser();
        // console.log(user);
        const isLoggedIn = store.getters['usermod/isLoggedIn'];
        // console.log(isLoggedIn);
        // console.log(requiresAuth);

        // if (requiresAuth && !store.getters.currentUser) {
        if (requiresAuth && !user && !isLoggedIn) {
            // console.log('next /login');
            next({ name: 'Login' });
            // } else if (to.path == '/login' && store.getters.currentUser) {
        } else if (to.path == '/pages/login' && user && isLoggedIn) {
            // console.log('next /');
            //original
            // next('/');
            next('/lisa/orders');
        } else {
            // console.log('last');
            next();
        }
    });

    axios.interceptors.request.use(
        function(config) {
            // console.log("interceptor");
            // var token = store.getters["usermod/currentUser"];
            token = getLocalUser();
            // console.log(token)
            if (token) {
                config.headers.Authorization = `Bearer ${token.token}`;
                // config.headers.headers.post["Content-Type"] =
                //     "application/json";
                // config.headers.headers.get["Content-Type"] = "application/json";
                // config.headers.Accept = "application/json";
                // config.headers.common = {
                //     "Content-Type": "application/json"
                // };
                // axios.defaults.headers.common = {
                //     "Content-Type": "application/json"
                // };
            }

            return config;
        },
        function(err) {
            // console.log('interceptor error', err.response);
            return Promise.reject(err);
        }
    );

    axios.interceptors.response.use(null, error => {
        // console.log("INTERCEPTOR");
        // console.log(error.response.status);
        // console.log("error", error.response);
        store.dispatch('settings/saveErrors', error.response);
        if (error.response.status === 401) {
            // context.commit('usermod/logout');
            // console.log("ERROR INTERCEPTOR");
            store.dispatch('usermod/logout');
            // console.log(router);
            if (router.history.current.name != 'Login') {
                router.push({ name: 'Login' });
            }

            // this.$router.push({ name: 'Lock' });
            // router.push('/pages/login');
            // router.push('/login');
            // this.$router.push({ path: "login" });
        }
        // console.log('interceptor error', err.response);
        return Promise.reject(error);
    });

    if (store.getters['usermod/currentUser']) {
        var token = store.getters['usermod/currentUser'];
        setAuthorization(token.token);
    }
}

export function setAuthorization(token) {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    // axios.defaults.headers.common["Content-Type"] =
    // axios.defaults.headers.common = {
    //     "Authorization": `Bearer ${token}`,
    //     'X-Requested-With': 'XMLHttpRequest',
    //     'Content-Type': 'application/json'
    // };
}
