export default {
    getPrimaryColor: state => {
        return state.primary
    },
    getOptSuccessSnackbar: state => {
        return state.optSuccessSnackbar
    },
    getOptFailSnackbar: state => {
        return state.optFailSnackbar
    },
    getSnackbarSettings: state => {
        return state.snackbarsettings
    },
    getErrors: state => {
        return state.errors
    },
    getUserSettings: state => {

        if (!state.usersettings) {
            return state.usersettings
        } else {
            const user_settings = JSON.parse(localStorage.getItem("lisa"));
            return user_settings
        }
    }
}
