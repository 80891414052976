import { fetchItems, updateItem, addItem, deleteItem } from '../proc/axios';

export default {
    saveErrors(context, error) {
        context.commit('SAVE_ERRORS', error);
    },
    async fetchUserSettings(context) {
        const response = await fetchItems(context.state.api);
        // console.log('fetchUserSettings-response', response);
        context.commit('SAVE_SETTINGS', response);
        // context.commit('SAVE_SETTINGS', response.user_settings);
        return response;
    }
};
