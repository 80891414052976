export default {
    api: '/api/specimens',
    items: [],
    buttontip: 'New Specimen',
    title: 'Specimens',
    text: 'Laboratory Specimens',
    options: [
        {
            action: 'new',
            label: 'New Specimen',
            actiontext: ['Enter New Specimen', 'Enter New Specimen Code'],
            icon: 'mdi-new-box'
        },
        {
            action: 'edit',
            label: 'Edit Specimen',
            actiontext: ['Edit Specimen', 'Edit Specimen Code'],
            icon: 'mdi-pencil'
        },
        {
            action: 'delete',
            label: 'Delete Specimen',
            actiontext: 'Delete Specimen',
            icon: 'mdi-delete'
        }
    ],
    headers: [
        {
            sortable: true,
            text: 'ID',
            value: 'id'
        },
        {
            sortable: true,
            text: 'Specimen Code',
            value: 'code'
        },
        {
            sortable: true,
            text: 'Specimen',
            value: 'name'
        },
        {
            sortable: false,
            text: 'Actions',
            value: 'actions',
            align: 'left'
        }
    ]
};
